import React from "react";
import imageRight from '../../../images/imageRight.png'
function Button({
  onPress,
  text,
  imageLeft,
  color,
  disabled,
  fullWidth,
  secondary,
}: {
  onPress: any;
  text: any;
  color?:any;
  imageLeft?: any;
  imageRight?: any;
  disabled?: any;
  fullWidth?: any;
  secondary?: boolean;
}) {
  return (
    <button
      style={{
        marginBottom:'5px',
        display: "flex",
        backgroundColor: disabled
          ? "#c1c1c1"
          :color==="success"?"lightGreen":color==="NMD"?"lightBlue":color==="failure"?"pink": secondary
          ? "#363636"
          : "#D32028",
        padding: "8px",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        borderRadius: 32,
        border: "none",
        cursor: disabled ? "not-allowed" : "pointer",
        width: fullWidth ? "100%" : "fit-content",
      }}
      onClick={onPress}
      disabled={disabled}
    >
      <img src={imageLeft} alt=""></img>
      <p
        style={{
          color:color==="success"?"green":color==="NMD"?"blue":color==="failure"?"#d32028":"#ffffff",
          fontSize: secondary ? "0.8rem" : "1.2rem",
          flex: 1,
          textAlign: "center",
          padding: "0px 5px",
          margin: 0,
          
        }}
      >
        {text}
      </p>
      <img style={{width:'1.5em',height:'1.5em'}}src={imageRight} alt=""></img>
    </button>
  );
}

export default Button;
