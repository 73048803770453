import React from "react";
import styles from "./index.module.css";
import CheckCircleIcon from "../../images/icons/check-circle.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";

function Sanctions() {
  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.buttonList}>
            <Button
              text="Digilocker KYC"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="Selfie"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="Agreement"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="e-Mandate"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
          </div>

          <p className={styles.footerText}>
            Powered By
            <img
              className={styles.footerImage}
              src="https://www.digio.in/images/digio_blue.png"
              alt=""
            />
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Sanctions;
