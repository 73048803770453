import Navbar from './Navbar'
import Hero from './Hero'
import React, { useState } from "react";
import {useDispatch,useSelector} from 'react-redux'
import {userActions} from '../../actions/user.actions'
import Educationcard from '../Home/Educationcard';
import { Container, Button, Grid, Paper, Box, colors ,Typography,Divider} from "@mui/material";
import { Homestyle } from "../Home/Style";
import calender from "../../Assets/images/calender.svg"
import file from "../../Assets/images/file.png"
import download from "../../Assets/images/download.png"
import calculator from "../../Assets/images/calculator.png";
import interest from "../../Assets/images/calculator.png";
import back from "../../Assets/images/arrow-left-circle.png"
import program from '../../Assets/images/program.png';
import school from '../../Assets/images/school.png';
import eye from "../../Assets/images/eye.png"
import wallet from '../../Assets/images/wallet.png'
import i from "../../Assets/images/i-icon.png"
import applicant from '../../Assets/images/applicant.png'
import {useNavigate,useLocation} from 'react-router-dom'

export default function ApplicationDetails(){
  const location=useLocation();
  const [applicationsDetails,setApplicationDetails]=React.useState(null)

  const documentState=useSelector((state)=>state?.GETAPPLICATIONS?.getApplicationsData?.data?.uploads?.data)
React.useEffect(()=>{


 if(location?.state?.applicationDetails)
 {
    setApplicationDetails(location?.state?.applicationDetails)
 }
}

,[])
    const navigate=useNavigate();

    const handleDocDownload=(url)=>{
      const a = document.createElement('a')
      a.href = url
      a.download = url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    return(
       <div className='home-bg '>
        <Navbar/>
        <Homestyle>
            <Container>
                <Paper className='paper-setting'>
                    <Grid container spacing={2}>
                            <div onClick={() => navigate(-1)} style={{marginLeft:'20px',cursor:'pointer',margin:'1em'}}>
                            <p  style={{display:'flex',alignItems:'center'}}><img  style={{height:'25px',width:'25px',cursor:'pointer'}} src={back}/><span style={{fontFamily:'Outfit-Medium',color:'#D32028',fontSize:'20px',marginLeft:'1em',fontWeight:'500'}}>{applicationsDetails?.applicationId}</span></p>
                            </div>
                      
                        <Grid item xs={12}>
                            <Grid container spacing={3}  justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12}>
                              <div style={{display:'flex'}}>
                                <div style={{display:'block'}}>
                                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',color:'#737373',}}><span style={{display:'flex',alignItems:'center',fontSize:'18px',fontFamily:'Outfit-Medium'}}><img src={calender}/>Loan Start Date</span></p>
                                  <p style={{fontFamily:'Outfit-Medium',fontSize:'18px',fontWeight:'500'}}>{applicationsDetails?.appliedDate}</p>
                                </div>
                                <div style={{display:'block',marginLeft:'10%'}}>
                                  <p style={{fontFamily:'Outfit-Medium',fontSize:'18px',color:'#737373',}}><span style={{display:'flex',alignItems:'center',fontSize:'18px',fontFamily:'Outfit-Medium'}}>Status</span></p>
                                   <p style={{fontFamily:'Outfit-Medium',fontSize:'18px',border:'none',borderRadius:'16px',textAlign:'center',fontWeight:'500'}}>{applicationsDetails?.status}</p>
                                </div>
                              

                              </div>
                            </Grid>
                            
                            </Grid> 
                        </Grid>
                      
                    </Grid>
                    <Grid style={{marginTop:'2em'}} item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment">
                <span style={{borderLeft:'5px solid #d32028',margin:'1em',fontFamily:'Outfit',fontSize:'18px',paddingLeft: '0.5em',}}>Uploaded Documents</span>
                <svg
                  width="5"
                  height="23"
                  viewBox="0 0 5 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                 
                </svg>
              </Typography>
            </Grid>
             <Grid style={{}} item xs={6} sm={8} md={9}>
              <Box mt={2}>
                <Divider />
              </Box>
            </Grid>
            {documentState?.map((item)=>{
              return(
                <Grid container>
                <Grid item xs={12} style={{marginTop:'10px',marginBottom:'10px'}}>
                     <div style={{backgroundColor:'#FFF8F4',boxShadow:'0px 0px 1px 1px #F9D8D6',borderRadius:'10px',padding:'1em',display:'flex',justifyContent:'space-between'}}>
                       <div style={{display:'flex',width:'100%',alignItems:'center'}}>
                        <p> <img style={{width:"22px",height:'22px'}} src={file}/></p>
                         <p style={{fontSize:'18px',marginLeft:'10px'}}>{item.type}</p>
                       </div>
                       <img  src={download} style={{height:'30px',width:'30px'}}/>
                     </div>
                   </Grid>
                  
                </Grid>
              )
            })}
            
                    <div style={{marginTop:'30px'}}>

            {/* <Educationcard /> */}
            </div>
                </Paper>
            </Container>
        </Homestyle>
       </div>
       
    )
}