import React from 'react'
import { Container, List, ListItem,Button ,Grid,Paper,Box,Typography} from '@mui/material';
import {Homestyle} from './Style'

export default function Footer() {
  return (
    <Homestyle>
        <Container>
   <Typography variant="h3" gutterBottom className='helpcenter' >
   Help centre 
      </Typography>
      <Box textAlign="center" >
      <List className='listfooter' >
        <ListItem  >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5459 6.34473C22.5459 5.24473 21.6459 4.34473 20.5459 4.34473H4.5459C3.4459 4.34473 2.5459 5.24473 2.5459 6.34473M22.5459 6.34473V18.3447C22.5459 19.4447 21.6459 20.3447 20.5459 20.3447H4.5459C3.4459 20.3447 2.5459 19.4447 2.5459 18.3447V6.34473M22.5459 6.34473L12.5459 13.3447L2.5459 6.34473" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
hello@feepay.education
        </ListItem>
        <ListItem>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.546 17.2647V20.2647C22.5471 20.5432 22.49 20.8189 22.3785 21.0741C22.2669 21.3292 22.1033 21.5583 21.898 21.7466C21.6928 21.9349 21.4505 22.0782 21.1867 22.1674C20.9229 22.2567 20.6433 22.2898 20.366 22.2647C17.2888 21.9304 14.333 20.8789 11.736 19.1947C9.31978 17.6594 7.27129 15.6109 5.73595 13.1947C4.04593 10.5859 2.9942 7.61572 2.66595 4.52472C2.64096 4.24819 2.67383 3.96948 2.76245 3.70635C2.85108 3.44321 2.99352 3.20141 3.18072 2.99635C3.36791 2.79128 3.59576 2.62743 3.84974 2.51525C4.10373 2.40306 4.37829 2.34499 4.65595 2.34472H7.65595C8.14126 2.33995 8.61175 2.5118 8.97972 2.82826C9.34769 3.14471 9.58803 3.58417 9.65595 4.06472C9.78258 5.02479 10.0174 5.96745 10.356 6.87472C10.4905 7.23265 10.5196 7.62164 10.4399 7.9956C10.3601 8.36957 10.1748 8.71284 9.90595 8.98472L8.63595 10.2547C10.0595 12.7583 12.1324 14.8312 14.636 16.2547L15.906 14.9847C16.1778 14.7159 16.5211 14.5306 16.8951 14.4508C17.269 14.3711 17.658 14.4002 18.016 14.5347C18.9232 14.8733 19.8659 15.1081 20.826 15.2347C21.3117 15.3033 21.7554 15.5479 22.0725 15.9222C22.3896 16.2965 22.5581 16.7743 22.546 17.2647Z" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

+91  63096 31666
        </ListItem>
      </List>
      </Box>
      <Typography variant="h3" gutterBottom className='Reserved' >
      Copyright @ 2024 Feepay All Rights Reserved.
      </Typography>
      </Container>
    </Homestyle>
  )
}
