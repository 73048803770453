import React from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate, useLocation } from "react-router-dom";

function SanctionLetter() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <p
            style={{
              overflowY: "scroll",
              width: "75%",
              fontSize: "1.2rem",
            }}
            dangerouslySetInnerHTML={{
              __html: location.state.data,
            }}
          ></p>
          <Button
              /*sanctions/digilocker*/
            onPress={() => {
              navigate("/approval");
            }}
            text={"Accept for loan"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SanctionLetter;
