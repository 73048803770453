import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { Container, List, ListItem ,Grid} from '@mui/material';
import Logo from '../../Assets/images/feepay.png'
import iacgLogo from '../../Assets/images/iacglogo.png'
import avgciLogo from '../../Assets/images/AVGCI_Logo.png'
import {Homestyle} from '../Home/Style'
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import joyLogo from "../../Assets/images/joy.png";
import ushodayaLogo from "../../Assets/images/Ushodaya.png"

export default function ButtonAppBar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const logo=window?.location?.href?.split('?')[1]?.split('&')[1]?.split('=')[1] || ""
  console.log(logo,"asdsad")
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget,"event")
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log("clicked")
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    sessionStorage.clear();
    if (logo==="iacg") {
      window.location.href = 'https://iacg.feepay.education/login';
    } else if (logo==="avgci"){
      window.location.href = 'https://avgci.feepay.education/login';
    }
    else if (logo==="joyinternational"){
      window.location.href = 'https://joyinternational.feepay.education/login';
    }
    else if (logo==="ushodaya"){
      window.location.href = 'https://ushodaya.feepay.education/login';
    }
    else {
      navigate('/login');
      window.location.reload();
    }
  };
  return (
    <>
    <Homestyle>
        <Container>
      <AppBar position="static" className='Appbar-main' >
            <Grid container >
            <Grid item xs={12} sm={4} >
              <div className='logo-cntr' >
                <Link to="/" >
            <Box component="img" alt='' src={Logo} className='logo' style={{width:logo==='iacg'?'9rem':"",height:logo==='iacg'?'3.5rem':""}}  />
            {logo==="iacg"?<Box component="img" alt='' src={iacgLogo} className='logo' style={{marginLeft:'1rem',width:logo==='iacg'?'10rem':"",height:logo==='iacg'?'3.5rem':""}}  />:<></>}
            {logo==="avgci"?<Box component="img" alt='' src={avgciLogo} className='logo' style={{width:logo==='iacg'?'7rem':"",height:logo==='iacg'?'3.5rem':""}}  />:<></>}
            {logo==="joyinternational"?<Box component="img" alt='' src={joyLogo} className='logo' style={{width:logo==='joyinternational'?'10rem':"",height:logo==='joyinternational'?'3.5rem':""}}  />:<></>}
            {logo==="ushodaya"?<Box component="img" alt='' src={ushodayaLogo} className='logo' style={{width:logo==='ushodaya'?'10rem':"",height:logo==='ushodaya'?'3.5rem':""}}  />:<></>}

            </Link>
            </div>
            </Grid>
            <Grid item xs={12} sm={8} >
            <List>
            {/* <ListItem>
                <Button variant="contained" color='primary'  >
                Download App

                </Button>
            </ListItem> */}
            <ListItem>
            <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30.6396" cy="30.2529" r="29.9707" fill="#F8E1E1"/>
            <path d="M43.2484 41.3992V38.3274C43.2484 36.6979 42.6011 35.1352 41.4489 33.9831C40.2967 32.8309 38.734 32.1836 37.1046 32.1836H24.8171C23.1877 32.1836 21.625 32.8309 20.4728 33.9831C19.3206 35.1352 18.6733 36.6979 18.6733 38.3274V41.3992M37.1046 19.8961C37.1046 23.2892 34.354 26.0398 30.9609 26.0398C27.5678 26.0398 24.8171 23.2892 24.8171 19.8961C24.8171 16.503 27.5678 13.7523 30.9609 13.7523C34.354 13.7523 37.1046 16.503 37.1046 19.8961Z" stroke="#D32028" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
               
                    <MenuItem onClick={()=>handleLogout()}>
            <List 
              sx={{
                display: "inline-flex",
                "& .MuiListItem-root": {
                  padding: "unset",
                },
              }}
            >
              <ListItem >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5"
                    stroke="#D32028"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ListItem>
              <ListItem  sx={{ paddingLeft: "10px !important " }}>
                <Typography
                  variant="body1"
                  component="div"
                  fontFamily="Inter-Medium"
                  color="#D32028"
                >
                  Logout
                </Typography>
              </ListItem>
            </List>
          </MenuItem>
                  </Menu>
              

            </ListItem>
            </List>
            </Grid>
            </Grid>
 
     
      </AppBar>
      </Container>
      </Homestyle>

      </>
  );
}
