import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import Label from "../../components/atoms/Label";
import InputText from "../../components/atoms/InputText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createNach } from "../../services/sanctions";

function Mandate() {
  const { userId, applicationId } = useContext(DataContext) as DataContextType;

  const [radioSelectedAccount, setRadioSelectedAccount] = useState("SAVINGS");
  const [radioSelectedMandate, setRadioSelectedMandate] =
    useState("NET_BANKING");

  const [state, setState] = useState({
    accountNumber: "",
    ifscCode: "",
  });

  const { accountNumber, ifscCode } = state;

  const handleChange = (key: any, val: any) => {
    setState({ ...state, [key]: val });
  };

  async function getRazorpayUrl() {
    const response = await createNach({
      accountNumber: accountNumber,
      accountType: radioSelectedAccount,
      ifscCode: ifscCode,
      mandateType: radioSelectedMandate,
      userId,
      applicationId,
    });

    window.open(response?.data?.url, "_blank", "noopener,noreferrer");
  }

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.childContainer}>
            <div className={styles.inputField}>
              <Label text="Account Number" />
              <InputText
                placeholder="Account Number"
                type="number"
                value={accountNumber}
                changeHandler={(e) =>
                  handleChange("accountNumber", e.target.value)
                }
              />
            </div>

            <div className={styles.inputField}>
              <Label text="IFSC Code" />
              <InputText
                placeholder="IFSC Code"
                type="text"
                value={ifscCode}
                changeHandler={(e) => handleChange("ifscCode", e.target.value)}
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Account Type" />
              <div
                onChange={(event) =>
                  setRadioSelectedAccount(
                    (event.target as HTMLInputElement).value
                  )
                }
                defaultValue="SAVINGS"
              >
                <input
                  type="radio"
                  value="SAVINGS"
                  name="student"
                  checked={radioSelectedAccount === "SAVINGS"}
                />{" "}
                Savings
                {" "}<br/>
                <input
                  type="radio"
                  value="CUURENT"
                  name="student"
                  checked={radioSelectedAccount === "CUURENT"}
                />{" "}
                Current
              </div>
            </div>
            <div className={styles.inputField}>
              <Label text="How do you want to authenticate your account?" />
              <div
                onChange={(event) =>
                  setRadioSelectedMandate(
                    (event.target as HTMLInputElement).value
                  )
                }
                defaultValue="NET_BANKING"
              >
                <input
                  type="radio"
                  value="NET_BANKING"
                  name="Net Banking"
                  checked={radioSelectedMandate === "NET_BANKING"}
                />{" "}
                Net Banking
                {" "}<br/>
                <input
                  type="radio"
                  value="DEBIT_CARD"
                  name="Debit Card"
                  checked={radioSelectedMandate === "DEBIT_CARD"}
                />{" "}
                Debit Card
              </div>
            </div>
            <Button
              onPress={() => {
                // initiateDigio();
                getRazorpayUrl();
              }}
              text="SUBMIT"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Mandate;
