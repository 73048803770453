import Navbar from "./Navbar";
import Hero from "./Hero";
import React, { useState } from "react";
import Educationcard from "../Home/Educationcard";
import { Container, Button, Grid, Paper, Box, colors } from "@mui/material";
import { Homestyle } from "../Home/Style";
import calender from "../../Assets/images/calender.svg";
import sanction from "../../Assets/images/sanction.png";
import calenderbig from "../../Assets/images/calenderbig.png";
import calculator from "../../Assets/images/calculator.png";
import interest from "../../Assets/images/calculator.png";
import back from "../../Assets/images/arrow-left-circle.png";
import program from "../../Assets/images/program.png";
import school from "../../Assets/images/school.png";
import eye from "../../Assets/images/eye.png";
import wallet from "../../Assets/images/wallet.png";
import i from "../../Assets/images/i-icon.png";
import {useDispatch,useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import * as moment from 'moment'
import {ModalHeader, ModalBody, Modal ,ModalFooter} from 'reactstrap';
import download from "../../Assets/images/download.png"
import EMI from "../../Assets/images/EMI.png"
import feemonk from "../../Assets/images/FeeMonk-MonkIllustration.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import App1 from "./Reciept/App1";
import App from "./Statement/App";
import { createRoot } from 'react-dom/client';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function Loandetails() {
  const user = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const getEmiHistory=useSelector((state)=>state?.emiHistory?.getEmiHistoryData?.data?.emiHistory?.data)
  const [loanDetails,setLoanDetails] = useState("")
    const token = sessionStorage.getItem("token") || window.location.href.split('?')[1].split('=')[1]

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [tableData, setTableData] = useState(getEmiHistory?.emis);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalEmiAmount, setTotalEmiAmount] = useState(0);
  const [overdueCharges, setOverdueCharges] = useState(0);
  const [chequeBounceCharges, setChequeBounceCharges] = useState(0);
  const [checkedCount, setCheckedCount] = useState(0);
  const [count, setCount] = useState(0); 
  const [totalCount, setTotalCount] = useState(0);
  const [openForeClose, setOpenForeClose] = React.useState(false);



  const handleForeClose = () => {
    setOpenForeClose(false); 
    for (let i = 0; i < tableData.length; i++) {
      const checkbox = document.getElementById(`${i}`);
      if (checkbox && checkbox.checked) {
        checkbox.checked = false;
        setCheckedCount((prevCount) => prevCount - 1);
      }
    }
  };
  
  const handleCheckOne=(item,index)=>{
    console.log("index",index,item)
    setTimeout(() => {
    document.getElementById(`check${index}`).checked=true;
      
    }, 1000);
  
    setSelectedItems(old=>[...old,item.emiId]);
    setTotalEmiAmount(old=>old+item.emiAmount)
    setOverdueCharges(0)
    setChequeBounceCharges(0)
    setCheckedCount(1)


  }
  const handleCheckboxClick = (array,index) => {
    console.log("index",array,index)
    setSelectedItems([]);
    setTotalEmiAmount(0)
    setOverdueCharges(0)
    setChequeBounceCharges(0)
    setCheckedCount(0)

    if(document.getElementById(`check${index}`).checked==false)
    {
      // setCheckedCount((prevCount) => prevCount - 1);
    console.log("deselect",array,index)

        for(let i=0;i<tableData?.length;i++)
        {
            document.getElementById(`check${i}`).checked=false
            setSelectedItems([]);
            setTotalEmiAmount(0)
            setOverdueCharges(0)
            setChequeBounceCharges(0)
            setCheckedCount(0)
        }
    }
    else
    {
    console.log("select",array,index)
     
        for(let i=index;i>=0;i--)
        {   
    
            if(array[i].status!=2)
            {
                
                document.getElementById(`check${i}`).checked=true
                setCheckedCount((prevCount) => prevCount + 1);
                setSelectedItems((old)=>[...old,array[i].emiId]);
                setTotalEmiAmount((old)=>old+array[i].emiAmount)
                setOverdueCharges((old)=>old+array[i].overdueCharges)
                setChequeBounceCharges((old)=>old+array[i].bounceCharges)
            }
        }
    }
    

     
    }   

    const handleForecloseButtonClick = () => {
      setOpenForeClose(true);
      for (let i = 0; i < tableData.length; i++) {
        const checkbox = document.getElementById(`${i}`);
        if (checkbox && !checkbox.disabled && !checkbox.checked) {
          checkbox.checked = true;
          setCheckedCount((prevCount) => prevCount + 1);
        }
      }
    };

  React.useEffect(() => {
    const url = `${process.env.REACT_APP_DASHBOARD_URL}/loan-repayment/profile?loanId=${sessionStorage.getItem("LoanId")}`
         
        axios.get(url,{
            headers:{
              Authorization:`Bearer ${token}`
          }
          }).then((res)=>{console.log("res",res?.data?.data)
          setLoanDetails(res?.data?.data)

        }).catch((error)=>console.log(error),"error")
        const emiUrl = `${process.env.REACT_APP_DASHBOARD_URL}/emis/for-loan?loanId=${sessionStorage.getItem("LoanId")}`
         
        axios.get(emiUrl,{
            headers:{
              Authorization:`Bearer ${user}`
          }
          }).then((res)=>{setTableData(res?.data?.data)
            const unpaidItems = res?.data?.data.filter(item => item.status === 2);
            setCount(unpaidItems.length);
            setTotalCount(res?.data?.data.length)
            const filteredItems = res?.data?.data.filter(item => {
              const [day, month, year] = item.dueDate.split('/'); 
              const dueDate = new Date(`${year}-${month}-${day}`); 
 
              return dueDate <= new Date() && item.status !== 2; 
            });
            
            filteredItems.sort((a, b) => {
              const [dayA, monthA, yearA] = a.dueDate.split('/');
              const [dayB, monthB, yearB] = b.dueDate.split('/');
              
              const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
              const dateB = new Date(`${yearB}-${monthB}-${dayB}`);
            
              return dateA - dateB;
            });
            const smallestDueDateItem = filteredItems[0];
            const index = res?.data?.data.findIndex(item => item === smallestDueDateItem); 
            console.log("index",index,smallestDueDateItem)
            handleCheckOne(smallestDueDateItem,index)
        }).catch((error)=>{console.log("error",error);})

        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };


  }, []);
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }

  const [sanctionModal, setModalSanction] = useState(false);
  const toggleSanction = () => setModalSanction(!sanctionModal);

  const fetchJsFromCDN = (src, externals) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };
const [clicked,setClicked] = useState(false)
  function razorpayCheckoutHandler(emis, reqAmount) {
    setClicked(true)
    fetchJsFromCDN("https://checkout.razorpay.com/v1/checkout.js", []).then(
      async (razorp) => {
        // var myHeaders = new Headers();
        // myHeaders.append(
        //   "Authorization",
        //   Bearer ${authToken && authToken.value}
        // );

        // var requestOptions: RequestInit = {
        //   method: "POST",
        //   headers: myHeaders,
        //   redirect: "follow",
        //   body: JSON.stringify({
        //     amount: 100
        //   })
        // };

        // console.log(requestOptions, "---> request options")

        const response = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/razorpay-checkout/create-order`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: reqAmount,
            loanId: sessionStorage.getItem("LoanId"),
            emis
          }),
        })
        const result = await response.json()
        console.log(result);
        const { id, status, amount,name,mobile } = result.data;

        console.log(id, status, amount, "---> id, status, amount")

        if (status == "created") {
          const options = {
            "key": "rzp_test_6eg0KnKc41tyIo", // Enter the Key ID generated from the Dashboard
            amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Feemonk (Monk Capital Pvt Ltd)",
            "description": "Test Transaction",
            "image": feemonk,
            "order_id": id, //This is a sample Order ID. Pass the id obtained in the response of Step 1
            "prefill": {
              "name": name,
              // "email": "gaurav.kumar@example.com",
              "contact":mobile
            },
            "callback_url": "http://staging-backend.feemonk.com/razorpay-checkout/resolve",
            // "notes": {
            //   "address": "2nd Floor, (SOUTHSIDE, Prasanna Hitex, 1-82/2/SUITE B, Manchirevula, Hyderabad, 500089"
            // },
            "theme": {
              "color": "#f77723"
            }
          }

          const paymentObject = new (window).Razorpay(options);
          paymentObject.open();
        }
      }
    ).catch((err) => console.log(err))
      
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (component, componentName) => {
    const newWindow = window.open('', '_blank');
    const container = newWindow.document.createElement('div'); // Creating a container element
    newWindow.document.body.appendChild(container); // Appending container to body
    createRoot(container).render(component); // Rendering into the container using createRoot
  setTimeout(() => {
    newWindow.close()
  }, 1000);
  };

  return (
    <div className="home-bg ">
      <Navbar />
      <Homestyle>
        <Container>
          <Paper className="paper-setting">
          <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"  style={{ position: 'absolute', top: '-60%', left: '0%' }}
      >
        <DialogTitle id="alert-dialog-title" color='red'>
          {"Alert !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color='red'>
            Please Select the Amount by clicking checkbox from EmiHistory to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus style={{color:'red',justifyContent:'flex-end',backgroundColor:'red',width:'1rem',color:'white'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      <Modal isOpen={openForeClose} >
        <ModalHeader toggle={handleForeClose}>ForeClose</ModalHeader>
        <ModalBody>
         <div>
          <p>Amount : </p>
          <p>Charges :</p>
         </div>
         <div style={{display:'flex',justifyContent:'space-around'}}>
          <button style={{width:'5rem',backgroundColor:'#D32028',color:'white',border:'none',fontFamily:'Inter-Medium',padding:'5px',borderRadius:'5px'}} onClick={()=>razorpayCheckoutHandler(selectedItems,totalEmiAmount)}>
              PAY
          </button>
          <button style={{width:'5rem',backgroundColor:'#D32028',color:'white',border:'none',fontFamily:'Inter-Medium',padding:'5px',borderRadius:'5px'}} onClick={handleForeClose}>
              CANCEL
          </button>
         </div>
        </ModalBody>
      </Modal>
              <Modal isOpen={sanctionModal}  size="xl" style={{height:'100%'}}>
                <ModalHeader toggle={toggleSanction}>Sanction Letter</ModalHeader>
                <ModalBody >
                <iframe
                    src={loanDetails?.sanctionLetterUrl}
                    width="100%"
                    height="1000vh"
                    style={{ border: 'none' }}
                  ></iframe>
                </ModalBody>
              </Modal>
              <Grid container >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <div
              onClick={clicked === true ?() => navigate(-2):() => navigate(-1)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",width:'5rem'
              }}
            >
              <img style={{ height: "22px", width: "22px" }} src={back} />
              <p
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: 0,
                  margin: 0,
                  paddingLeft: "5px",
                }}
              >
                Back
              </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <Grid container justifyContent="flex-end" >
                
                    <button
                   style={{
                     height: "40px",
                     display: "flex",
                     alignItems: "center",
                     borderStyle: "solid",
                     borderColor: "#D0D5DD",
                     borderWidth: "1px",
                     borderRadius: "5px",
                     padding: "5px",
                     background: "none",width:'8.7rem',marginRight:'1rem'
                   }} 
                 >
                   <img src={download} style={{height:'16px'}}/>
                   <span
                     style={{
                       fontFamily: "Inter-Medium",
                       fontSize: "14px",
                       marginLeft: "7px",whiteSpace:'nowrap'
                     }}
                     onClick={() => handleClick(<App repaymentDet={{...loanDetails,status:sessionStorage.getItem('LoanStatus')}} emiDet={tableData} />, 'Statement')}
                   >
                        <App repaymentDet={{...loanDetails,status:sessionStorage.getItem('LoanStatus')}} emiDet={tableData} />                   </span>
                 </button>
                  
             
                 <button
                   style={{
                     height: "40px",
                     display: "flex",
                     alignItems: "center",
                     borderStyle: "solid",
                     borderColor: "#D0D5DD",
                     borderWidth: "1px",
                     borderRadius: "5px",
                     padding: "5px",
                     background: "none",width:'8.5rem'
                   }} onClick={toggleSanction}
                 >
                   <img src={sanction} />
                   <span
                     style={{
                       fontFamily: "Inter-Medium",
                       fontSize: "14px",
                       marginLeft: "7px",whiteSpace:'nowrap'
                     }}
                     
                   >
                     Sanction Letter
                   </span>
                 </button>
               
                  
                  </Grid>
                </Grid>
              
              </Grid>
              <Grid container spacing={1} marginTop="0.5rem" >
                <Grid item xs={6} md={3}>
                <p
                        style={{
                          color: "#667085",
                          fontSize: "14px",
                          fontFamily: "Inter-Medium",
                          fontWeight: "500",marginBottom: "5px"
                        }}
                      >
                       Loan Id

                      </p>
                      <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "Inter-Medium",
                        fontWeight: "700",
                        marginBottom: "5px"
                        
                        // paddingTop: "10px",
                      }}
                    >
                   {sessionStorage.getItem("LoanId")} 

                    </p> 
                </Grid>
                <Grid item xs={6} md={3}>
                <p
                        style={{
                          color: "#667085",
                          fontSize: "14px",
                          fontFamily: "Inter-Medium",
                          fontWeight: "500",marginBottom: "5px"
                        }}
                      >
                        Outstanding amount
                      </p>
                      <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter-Medium",
                        fontWeight: "700",
                       marginBottom: "5px"
                        
                        // paddingTop: "10px",
                      }}
                    >
                      ₹{formatNumberWithCommas(loanDetails?.outstandingAmount)}
                    </p>
                </Grid>
                <Grid item xs={6} md={3}>
                <p
                        style={{
                          color: "#667085",
                          fontSize: "14px",
                          fontFamily: "Inter-Medium",
                          fontWeight: "500",marginBottom: "5px"
                        }}
                      >
                        Upcoming EMI date
                      </p>
                      <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter-Medium",
                        fontWeight: "700",
                       marginBottom: "5px"
                        
                        // paddingTop: "10px",
                      }}
                    >
                      {loanDetails.nextEmiDate ? moment(loanDetails.nextEmiDate).format('DD MMM YY') : null }
                    </p>
                </Grid>
                <Grid item xs={6} md={3} style={{display:'flex',alignItems:'center'}}>
                <button
                      style={{
                        display: "flex",
                       justifyContent:'center',
                        alignItems: "center",
                        backgroundColor: "#D32028",
                        border: "none",
                        borderRadius: "10px",
                        padding: "5px",
                       color:'white',fontFamily:'Inter-Medium'
                      }} onClick={handleForecloseButtonClick}
                    >
                     
                     Foreclose Loan Now
                    </button>
                </Grid>
              </Grid>
             
              <Grid container style={{
                    padding: "20px",
                    marginTop: "20px",
                    boxShadow: "0px 3px 3px 0px #D320281A",
                    backgroundColor: "#FFF8F4",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#F9D8D6"
                  }}>
                <Grid item xs={12} sm={6} md={3} lg={3} >
                <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "14px",
                        color: "#737373",
                        fontWeight: "700",paddingTop:'10%'
                      }}
                    >
                      Principal amount
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "18px",
                        fontWeight: "700",
                        
                      }}
                    >
                      ₹ {formatNumberWithCommas(loanDetails?.loanAmount)}
                    </p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "14px",
                        color: "#737373",
                        fontWeight: "700",paddingTop:'10%'
                      }}
                    >
                      <span style={{ display: "flex", alignItems: "center", fontFamily: "Inter-Medium",
                        fontSize: "14px",
                        color: "#737373",
                        fontWeight: "700", }}>
                        <img src={calender} />
                        Loan Start Date
                      </span>
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter-Medium",
                        fontSize: "14px",
                        fontWeight: "700"
                      }}
                    >
                      {loanDetails.startDate ? moment(loanDetails.startDate).format('DD MMM YY') : null }
                    </p>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}  style={{
                        padding: "25px",
                        backgroundColor: "#FFFCFA",
                        borderRadius: "12px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#F9D8D6",
                        display: "flex",
                        justifyContent: "space-between",}}>
                          <Grid container spacing={6}>
                          <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: "flex",flexDirection:'row',alignItems:'center' }}>
                          <img
                            style={{ height: "40px", width: "40px" }}
                            src={calculator}
                          />
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:'5px'}}>
                          <p
                            style={{
                          
                              fontFamily: "Inter-Medium",
                              fontSize: "11px",
                              color: "#737373",
                              fontWeight: "700"
                            }}
                          >
                            EMI
                           
                          </p>
                          <p style={{
                                fontFamily: "Inter-Medium",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "black",lineHeight:'1px'
                              }}> 
                              ₹{formatNumberWithCommas(loanDetails?.emi)}
                            </p>
                          </div>
                          
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: "flex", alignItems: "center" }}>
                          <img
                            style={{ height: "40px", width: "40px" }}
                            src={calenderbig}
                          />
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:'5px'}}>
                          <p
                            style={{
                             
                              fontFamily: "Inter-Medium",
                              fontSize: "11px",
                              color: "#737373",
                              fontWeight: "700",marginTop:'5px'
                            }}
                          >
                            TENURE
                           
                          </p>
                          <p  style={{
                                fontFamily: "Inter-Medium",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "black",whiteSpace:'nowrap',lineHeight:'0.5px'
                              }}>
                          
                          {loanDetails?.tenure ? `${loanDetails.tenure} months` : ''}
                         
                          </p>
                          </div>
                         
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: "flex", alignItems: "center" }}>
                          <img
                            style={{ height: "40px", width: "40px" }}
                            src={interest}
                          />
                          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',marginLeft:'5px'}}>
                          <p
                            style={{
                          
                              fontFamily: "Inter-Medium",
                              fontSize: "11px",
                              color: "#737373",
                              fontWeight: "700",marginTop:'5px'
                            }}
                          >
                            INTEREST
                           
                          </p>
                          <p style={{
                                fontFamily: "Inter-Medium",
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "black",lineHeight:'1px'
                              }}>
                          
                          {loanDetails?.interest !== undefined ? `${loanDetails.interest}%` : '0%'}                          
                          </p>
                          </div>
                         
                        </Grid>
                          </Grid>
                          
                </Grid>
              </Grid>
            
                     <Accordion style={{backgroundColor:'#FFF8F4',width:'100%',overflow:'auto',padding:'0.2rem',boxShadow:"0px 3px 3px 0px #D320281A",borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#F9D8D6",marginTop:'20px'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"  
                    >
                      <span style={{fontFamily:'Inter-Medium'}}>Mandate Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                     <Grid container>
                     <Grid item xs={12} sm={6} md={6} lg={6} style={{fontFamily:'Inter-Medium'}}>
                      <span style={{fontFamily:'Inter-Medium',color: "#737373",fontSize:'1rem'}}>Account Number : <span style={{fontFamily:'Inter-Medium',color:'black',fontSize:'1rem'}}>{loanDetails?.mandateDetails?.accountNumber}</span></span> 
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} style={{fontFamily:'Inter-Medium'}}>
                      <span style={{fontFamily:'Inter-Medium',color: "#737373",marginRight:'0.5rem',fontSize:'1rem'}}>Ifsc : <span style={{fontFamily:'Inter-Medium',color:'black',fontSize:'1rem'}}>{loanDetails?.mandateDetails?.ifsc}</span></span> 
                      </Grid>
                     </Grid>
                    </AccordionDetails>
                     </Accordion>
     
              <Grid container style={{
                    padding: "20px",
                    marginTop: "20px",
                    boxShadow: "0px 3px 3px 0px #D320281A",
                    backgroundColor: "#FFF8F4",
                    borderRadius: "12px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#F9D8D6"
                  }}>
              <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={8} style={{display:'flex',paddingLeft:'0%',paddingTop:'%',alignItems:'center'}}>
                <img style={{height:'30px',width:'28px',marginBottom:'1rem'}} src={EMI}/>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%'}}>EMI History</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%',marginLeft:'3%',fontWeight:'600'}}>{count}/{totalCount} EMIs completed</p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} style={{padding:'0%'}}>
                  <div style={{borderStyle:'solid',borderColor:'#A5A5A5',borderRadius:'14px',borderWidth:'1px',display:'flex',justifyContent:'space-between',padding:'10px',alignItems:'center'}}>
                    <div style={{fontFamily:'Inter-Medium'}}>
                      {checkedCount}EMIs
                    </div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{fontFamily:'Inter-Medium'}}> ₹{totalEmiAmount?totalEmiAmount:"0"}</div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{backgroundColor:'#CB1F26',borderRadius:'10px',paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer',whiteSpace:'nowrap',fontSize:'14px'}} onClick={selectedItems.length >=1 ? ()=>razorpayCheckoutHandler(selectedItems,totalEmiAmount):handleClickOpen}><span ><img style={{height:'12px',width:'20px',marginRight:'5px'}} src={wallet}/><span style={{fontFamily:'Inter-Medium',color:'white'}}>Pay now</span></span></div>

                  </div>
               
                </Grid>
               </Grid>
              </Grid>
              <Grid item xs={12}>
                {tableData?.map((item,index)=>(
                  <div style={{marginTop:'1rem'}}>
                {(item.status === 3 || item.status === 5 || item?.status === 1) ? (
                  <div style={{display:'flex'}}>
                   <div style={{display:'flex',lineHeight:'1rem',justifyContent:'center',alignItems:'center',marginTop:'-1rem',marginRight:'1rem',flexDirection:'column'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>{item?.emiNo}.</p>
                    <input id={`check${index}`} style={{height:'20px',width:'20px'}}   disabled={item?.status==2} type="checkbox"  onClick={() => handleCheckboxClick(tableData,index)}></input>
                  </div>
                    <Accordion style={{width:'100%',overflow:'auto',padding:'0.2rem',border:'1px solid #F9D8D6',boxShadow: "0px 3px 3px 0px #D320281A",borderRadius:'0.5rem'}}>
                   <AccordionSummary
                  expandIcon={(item.status === 3 || item.status === 5) ? <ExpandMoreIcon />:null}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container spacing={1}>
                  
                  <Grid item xs={7.5} sm={4} md={3} lg={3} style={{lineHeight:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Due Date</p>
                    <p style={{
                                fontFamily: "Inter-Medium",
                                fontSize: "0.8rem",
                                
                                lineHeight:'1px'
                              }}>{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</p>
                  </Grid>
                  <Grid item xs={4.5} sm={4} md={3} lg={3} style={{lineHeight:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Emi</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',lineHeight:'1px',fontWeight:'300'}}>₹{formatNumberWithCommas(item?.emiAmount)}</p>
                  </Grid> 
                  <Grid item xs={7.5} sm={4} md={3} lg={3} style={{lineHeight:'1rem'}}>
                    <p style={{fontWeight:'500',whiteSpace:'nowrap',fontSize:'0.8rem'}}>Amt to be paid</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',lineHeight:'1px',fontWeight:'300'}}>₹{formatNumberWithCommas(item?.emiAmount)}</p>
                  </Grid> 
                  <Grid item xs={4.5} sm={4} md={3} lg={3} style={{lineHeight:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Status</p>
                    {item.status?
                                  item.status == 1?<p style={{color:'#6E24E7', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>UnPaid</p>
                                  :item.status==3?<p style={{color:'#F8A31F', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>PartlyPaid</p>
                                  :item.status==2?<p style={{color:'#12B76A',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>Paid</p>
                                  :item.status==4?<p style={{color:'#D22129',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>OvdPayment</p>
                                  :item.status==5?<p style={{color:'#D22129',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>Ovd</p>
                                  :item.status==6?<p style={{color:'#F8A31F', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem',fontWeight:'300'}}>Pause</p>
      
                                  :item.status
                                  :'-'}
                  </Grid>
                 
                 
                </Grid>
                </AccordionSummary>
                {(item.status === 3 || item.status === 5) ? (
                  <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Bounce Charges : <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'0.8rem',lineHeight:'1px'}}>₹{formatNumberWithCommas(item?.bounceCharges)}</span></p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p style={{fontWeight:'500',fontSize:'0.8rem'}}>overdueCharges : <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'0.8rem',lineHeight:'1px'}} >₹{formatNumberWithCommas(item?.overdueCharges)}</span></p>
                    </Grid>

                  </Grid>
                  </AccordionDetails>
                ):null}
               
                </Accordion>
                  </div>
                ):(<div style={{display:'flex'}}>
                  <div style={{display:'flex',lineHeight:'1rem',justifyContent:'center',alignItems:'center',marginTop:'-1rem',marginRight:'1rem',flexDirection:'column'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>{item?.emiNo}.</p>
                    <input  id={`check${index}`} style={{height:'20px',width:'20px'}}   disabled={item?.status==2} type="checkbox" onClick={() => handleCheckboxClick(tableData,index)}></input>
                  </div>
                  <Accordion  style={{width:'100%',overflow:'auto',border:'1px solid #F9D8D6',boxShadow: "0px 3px 3px 0px #D320281A",borderRadius:'0.5rem'}}>

                  <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Grid container spacing={1}>
                
                  <Grid item xs={7.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Due Date</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',fontWeight:'300',color:'#000000',lineHeight:'1px'}}>{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</p>
                  </Grid>
                  <Grid item xs={4.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Emi</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',fontWeight:'300',color:'black',lineHeight:'1px'}}>₹{formatNumberWithCommas(item?.emiAmount)}</p>
                  </Grid>
                  <Grid item xs={7.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Paid Date</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',fontWeight:'300',color:'black',lineHeight:'1px'}}>{moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</p>
                  </Grid>
                  <Grid item xs={4.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Paid</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'0.8rem',fontWeight:'300',color:'black',lineHeight:'1px'}}>₹{formatNumberWithCommas(item?.repaidEmiAmount)}</p>
                  </Grid>
                  
                 
                  
                  <Grid item xs={7.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Status</p>
                    {item.status?
                                  item.status == 1?<p style={{color:'#6E24E7', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>UnPaid</p>
                                  :item.status==3?<p style={{color:'#F8A31F', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>PartlyPaid</p>
                                  :item.status==2?<p style={{color:'#12B76A',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>Paid</p>
                                  :item.status==4?<p style={{color:'#D22129',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>OvdPayment</p>
                                  :item.status==5?<p style={{color:'#D22129',fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>Ovd</p>
                                  :item.status==6?<p style={{color:'#F8A31F', fontFamily:'Inter-Medium',lineHeight:'1px',fontSize:'0.8rem'}}>Pause</p>
      
                                  :item.status
                                  :'-'}
                  </Grid>
                 
                    <Grid item xs={4.5} sm={4} md={2} lg={2} style={{lineHeight:'1rem',paddingTop:'1rem'}}>
                    <p style={{fontWeight:'500',fontSize:'0.8rem'}}>Receipt</p>
                    {item?.status === 2 ?
                    <p style={{marginTop:'-0.5rem'}} onClick={() => handleClick(<App1 receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}} type="repayment" />, 'Receipt')}>
                     <App1 type="repayment" receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}}/> </p> : '-'}
                  </Grid>
                  
                 
                </Grid>
                </AccordionSummary>
                </Accordion>
                      </div>
                )}
              
              </div>
                ))}
              
              </Grid>
             </Grid>
          
              {/* <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:'#DBDBDB',borderRadius:'10px',boxShadow:' 0px 0px 3px 0px #00000026',marginTop:'30px',overflowX:'auto'}}>
               <Grid container>
                <Grid item xs={12} sm={12} md={7} lg={8} style={{display:'flex',paddingLeft:'3%',paddingTop:'2%'}}>
                <img style={{height:'30px',width:'28px'}} src={EMI}/>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%'}}>EMI History</p>
                <p style={{fontFamily:'Inter-Medium',fontSize:'14px',padding:'1%',marginLeft:'3%',fontWeight:'600'}}>{count}/{totalCount} EMIs completed</p>
                </Grid>
                <Grid item xs={12} sm={8} md={5} lg={4} style={{padding:'2%'}}>
                  <div style={{borderStyle:'solid',borderColor:'#A5A5A5',borderRadius:'14px',borderWidth:'1px',display:'flex',justifyContent:'space-between',padding:'10px',alignItems:'center'}}>
                    <div style={{fontFamily:'Inter-Medium'}}>
                      {checkedCount}EMIs
                    </div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{fontFamily:'Inter-Medium'}}> ₹{totalEmiAmount?totalEmiAmount:"0"}</div>
                    <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                    <div style={{backgroundColor:'#CB1F26',borderRadius:'14px',paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer'}} onClick={selectedItems.length >=1 ? ()=>razorpayCheckoutHandler(selectedItems,totalEmiAmount):handleClickOpen}><span ><img style={{height:'12px',width:'20px',marginRight:'5px'}} src={wallet}/><span style={{fontFamily:'Inter-Medium',color:'white'}}>Pay now</span></span></div>

                  </div>
               
                </Grid>
               </Grid>
               {isMobile ? (
                <>
                 <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead style={{backgroundColor:'#F9FAFB'}}>
        <tr>
        <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Select</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Due Date</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>EMI</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Paid Amt.</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Paid Date</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Status</th>
          <th style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>Receipt</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((item, index) => (
          <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'10px' }}><input id={index}   disabled={item?.status==2} type="checkbox" onClick={() => handleCheckboxClick(tableData,index)}  /></td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item.grossAmount}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{item.repaidEmiAmount}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>{moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>
            {item.status?
                                  item.status == 1?<span style={{color:'#6E24E7', fontFamily:'Inter-Medium',fontSize:'12px'}}>UnPaid</span>
                                  :item.status==3?<span style={{color:'#F8A31F', fontFamily:'Inter-Medium',fontSize:'12px'}}>PartlyPaid</span>
                                  :item.status==2?<span style={{color:'#12B76A',fontFamily:'Inter-Medium',fontSize:'12px'}}>Paid</span>
                                  :item.status==4?<span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'12px'}}>OvdPayment</span>
                                  :item.status==5?<span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'12px'}}>Ovd</span>
                                  :item.status==6?<span style={{color:'#F8A31F', fontFamily:'Inter-Medium',fontSize:'12px'}}>Pause</span>
      
                                  :item.status
                                  :'-'}
              </td>
            <td style={{ textAlign: 'left',fontSize:'12px',fontFamily:'Inter-Medium',padding:'4px' }}>
            {item?.status === 2 ?<div onClick={() => handleClick(<App1 receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}} type="repayment" />, 'Receipt')}> <App1 type="repayment" receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}}/> </div> : '-'}
              </td>
          </tr>
        ))}
      </tbody>
    </table>
            
                </>
           
          ) : (
            <div className="tables1">
            <table className={`table-of-contents1 ${isMobile ? 'mobile' : 'desktop'}`} >
                          <thead style={{backgroundColor:'#F9FAFB'}}>
                          <tr>
                          <th >Select</th>
                              <th >DueDate</th>
                              <th >EMI</th>
                              <th >Paid Amt.</th>
                              <th  >Paid Date</th>
                              <th >Status</th>
                              <th >Receipt</th>
      
      
                          </tr>
                          </thead>
                          <tbody>
                          {tableData?.map((item,index) => (
                              <tr key={index}>
                              <td ><input id={index}   disabled={item?.status==2} type="checkbox" onClick={() => handleCheckboxClick(tableData,index)}  /></td>
                              <td >{moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.dueDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
                              <td>{item.grossAmount}</td>
                              <td>{item.repaidEmiAmount}</td>
                              <td>{moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY")!=="Invalid date"?moment(item.emiRepaidDate,'DD/MM/YYYY').format("DD MMM YYYY"):"--"}</td>
                              <td>  {item.status?
                                  item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>UnPaid</span>
                                  :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>PartlyPaid</span>
                                  :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Paid</span>
                                  :item.status==4?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>OvdPayment</span>
                                  :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Ovd</span>
                                  :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'15px',padding:'5px',fontFamily:'Outfit-Medium',fontSize:'14px'}}>Pause</span>
      
                                  :item.status
                                  :'-'}</td>
                             
                                <td>
                                {item?.status === 2 ?<div onClick={() => handleClick(<App1 receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}} type="repayment" />, 'Receipt')}> <App1 type="repayment" receipt={{...item,firstname:loanDetails.firstName,loanType:loanDetails.courseName}}/> </div> : '-'}
                                </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
            </div>
          )}
           
              
      
            </div> */}
              <div style={{ marginTop: "30px" }}>
              {/* <Educationcard /> */}
            </div>
          
          </Paper>
        </Container>
      </Homestyle>
    </div>
  );
}
