import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import EmiIcon from "../../images/icons/emi_icon.svg";
import TenureIcon from "../../images/icons/tenure_icon.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { approve } from "../../services/application";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";

function SelectDate() {
  const location = useLocation();
  const [state, setState] = useState<{
    emi?: number;
    productId?: string;
    tenure?: number;
  }>({});
  const { applicationId, loanAmount } = useContext(
    DataContext
  ) as DataContextType;

  const navigate = useNavigate();

  useEffect(() => {
    setState(location.state.data);
  }, [location.state]);

  if (location.state.data) {
    return (
      <div className={styles.body}>
        <div className={styles.backdrop}>
          <Navbar />
          <div className={styles.container}>
            <div className={styles.content}>
              <div
                style={{
                  flex: 1,
                  marginLeft:'1em'
                }}
              >
                <p
                  style={{
                    color: "#606060",
                    fontSize: "1em",
                  }}
                >
                  Principal amount
                </p>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "1em",
                  }}
                >
                  ₹ {loanAmount}
                </p>
              </div>
              <div
               className={styles.dateBox}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src={EmiIcon}
                      alt=""
                      style={{
                        height: "3em",
                      }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#737373",
                        fontSize: "1em",
                      }}
                    >
                      EMI
                    </p>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "1em",
                      }}
                    >
                      {state.emi}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src={TenureIcon}
                      alt=""
                      style={{
                        height: "3em",
                      }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#737373",
                        fontSize: "1.2em",
                      }}
                    >
                      TENURE
                    </p>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "1.2em",
                      }}
                    >
                      {state.tenure} months
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "1em",
                }}
              >
                EMI date (Select your EMI Date)
              </p>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input style={{borderRadius:'10px',width:'10em',height:'4em',marginLeft:'1.5em'}} type="date" />
              </div>
            </div>
            <Button
              onPress={() => {
                approve({
                  applicationId,
                  fundCode: "GBP",
                  productId: state.productId,
                  loanAmount,
                  emi: state.emi,
                  emiFirstDate: "2023-08-15",
                  dayOfEmi: "15",
                }).then((result) => {
                  console.log(result.data);
                  if (result.data.message === "Successful") {
                  }
                  navigate("/sanction-letter", {
                    state: {
                      data: result.data.data,
                    },
                  });
                });
              }}
              text={"Accept for loan"}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
  return <div></div>;
}

export default SelectDate;
