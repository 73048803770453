import React from "react";
import FeemonkLogo from "../../../images/hero_icon.svg";
import ProfileIcon from "../../../images/icons/profile.svg";
import DownloadIcon from "../../../images/icons/download.svg";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className={styles.navbar}>
      <Link to="/home">
        <img style={{width:'8rem'}} src={FeemonkLogo} alt="" className={styles.logo} />
      </Link>
      <div className={styles.footerRight}>
        <div className={styles.downloadButton}>
          <p style={{fontSize:'1rem',textAlign:'center'}}>Download App</p>
        </div>
        <img style={{width:'3rem',height:'3rem'}} src={ProfileIcon} alt="" />
      </div>
    </div>
  );
}

export default Navbar;
