import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import FeemonkText from "../../components/atoms/FeemonkText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createApplication } from "../../services/application";
import BankIcon from "../../images/static_assests/bank.svg";
import PdfIcon from "../../images/static_assests/pdf.svg";
import { APPLICATIONS_BACKEND } from "../../utils/keys";

const banks = {
  count: 370,
  banks: [
    {
      name: "SBI",
      full_name: "State Bank of India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/sbi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HDFC",
      full_name: "HDFC Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/hdfc_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "ICICI",
      full_name: "ICICI Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/icici_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "AXIS",
      full_name: "Axis Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/axis_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "KOTAK",
      full_name: "Kotak Mahindra Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/kotak_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "INDUSIND",
      full_name: "IndusInd Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/indusind_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "CITI",
      full_name: "Citibank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/citi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ANDHRA",
      full_name: "Andhra Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/andhra_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UCO",
      full_name: "UCO Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/uco_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CENTRAL",
      full_name: "Central Bank of India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/central_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARODA",
      full_name: "Bank of Baroda",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/baroda_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CANARA",
      full_name: "Canara Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/canara_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ALLAHABAD",
      full_name: "Allahabad Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/allahabad_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "VIJAYA",
      full_name: "Vijaya Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/vijaya_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BOI",
      full_name: "Bank of India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/boi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "SYNDICATE",
      full_name: "Syndicate Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/syndicate_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CORPORATION",
      full_name: "Corporation Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/corporation_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DENA",
      full_name: "Dena Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/dena_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "FEDERAL",
      full_name: "Federal Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/federal_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "IDFC",
      full_name: "IDFC First Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/idfc_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DEUTSCHE",
      full_name: "Deutsche Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/deutsche_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "IDBI",
      full_name: "IDBI Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/idbi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "INDBNK",
      full_name: "Indian Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/indbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "IOB",
      full_name: "Indian Overseas Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/iob_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARNATAKA",
      full_name: "Karnataka Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/karnataka_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "KARUR",
      full_name: "Karur Vysya Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/karur_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MAHABK",
      full_name: "Bank of Maharashtra",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/mahabk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "OBC",
      full_name: "Oriental Bank of Commerce",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/obc_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNJAB_SIND",
      full_name: "Punjab & Sind Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/punjab_sind_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RBL",
      full_name: "RBL Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/rbl_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "SIB",
      full_name: "South Indian Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/sib_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "TAMIL_MERCANTILE",
      full_name: "Tamilnad Mercantile Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/tamil_mercantile_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UBI",
      full_name: "Union Bank of India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/ubi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: true,
      is_aa_available: false,
    },
    {
      name: "YESBNK",
      full_name: "Yes Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/yesbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CITY_UNION",
      full_name: "City Union Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/city_union_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PNBBNK",
      full_name: "Punjab National Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/pnbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KHATTRI",
      full_name: "Khattri Coop Urban Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/khattri.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JANTASAHAKARI",
      full_name: "Janata Sahakari Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jantasahakari.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HSBC",
      full_name: "HSBC Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/hsbc_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NKGSBBNK",
      full_name: "NKGSB Co-operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/nkgsbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "FINGROWTH",
      full_name: "Fingrowth Co-operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/fingrowth_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NATIONAL_MERCANTILE",
      full_name: "National Mercantile Co-operative Bank Limited",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/national_mercantile_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ABHYUDAYA",
      full_name: "Abhyudaya Co-operative Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/abhyudaya_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JANA_SAHAKARI",
      full_name: "Janakalyan Sahakari Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jana_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "EQUITAS",
      full_name: "Equitas Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/equitas_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NESFINBNK",
      full_name: "North East Small Finance Bank Limited",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/nesfinbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PRIMEBNK",
      full_name: "Prime Co-operative Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/primebnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "POCHAMPALLY",
      full_name: "Pochampally Co-Operative Urban Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/pochampally_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JIVAN",
      full_name: "Jivan Commercial Co-operative Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jivan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KNSB",
      full_name: "Shree Kadi Nagrik Sahakari Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/knsb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JANA",
      full_name: "Jana Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jana_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VARACHHA",
      full_name: "The Varachha Co-Op Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/varachha_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JSKB",
      full_name: "Jila Sahakari Kendriya Bank, Khargone",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jskb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "COSMOS",
      full_name: "The Cosmos Co-Op Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/cosmos_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "APNA_SAHAKARI",
      full_name: "Apna Sahakari Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/apna_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJKOT",
      full_name: "Rajkot Nagrik Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rajkot_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARAMATI",
      full_name: "The Baramti Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/baramati_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DEOGIRI",
      full_name: "Deogiri Nagari Sahakari Bank Ltd. Aurangabad",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/deogiri_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAWANAGAR",
      full_name: "The Nawanagar Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/nawanagar_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "FINANCIAL",
      full_name: "The Financial Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/financial_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BASSEIN",
      full_name: "Bassein Catholic Co-Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/bassein_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RMGBBNK",
      full_name: "Rajasthan Marudhara Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rmgbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ANDHRA_PRAGATHI",
      full_name: "Andhra Pragathi Grameena Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/andhra_pragathi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "THANE",
      full_name: "Thane District Central Cooperative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/thane_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "URBANCOOP",
      full_name: "The New Urban Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/urbancoop_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ABHINAV_SAHAKARI",
      full_name: "The Abhinav Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/abhinav_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GANDHIDHAM",
      full_name: "The Gandhidham Co-Op Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/gandhidam_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CITIZENS",
      full_name: "Citizens Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/citizens_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SARVODAYA",
      full_name: "Sarvodaya Commercial Co-Operative Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sarvodaya_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SADHANA",
      full_name: "Sadhana Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sadhana_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAINITAL",
      full_name: "Nainital Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/nainital_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "THANE_BHARAT",
      full_name: "Thane Bharat Sahakari Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/thane_bharat_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UTKARSHBNK",
      full_name: "Utkarsh Small Finance Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/utkarshbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PURVANCHALBNK",
      full_name: "Purvanchal Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/purvanchalbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CSBBNK",
      full_name: "Catholic Syrian Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/csbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAURASHTRA",
      full_name: "Saurashtra Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/saurashtra_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AKOLA",
      full_name: "The Akola Urban Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/akola_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ASSOCIATEBNK",
      full_name: "Associate Co Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/associatebnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KALYAN",
      full_name: "Kalyan Janata Sahakari Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kalyan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PRATAPBNK",
      full_name: "The Pratap Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pratapbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARODA_GUJRATBNK",
      full_name: "Baroda Gujrat Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/baroda_gujratbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "FINOBNK",
      full_name: "Fino Payments Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/finobnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MUNICIPALBNK",
      full_name: "The Municipal Co-Op Bank Ltd, Mumbai",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/municipalbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAHYADRI_SAHAKARI",
      full_name: "The Sahyadri Sahakari Bank Ltd. Mumbai",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sahyadri_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SUVARNAYUG",
      full_name: "Suvarnayug Sahakari Bank Ltd. Pune",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/suvarnayug_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DHANLAXMI",
      full_name: "Dhanlakshmi Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/dhanlakshmi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CHIKHLI",
      full_name: "The Chikhli Urban Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/chikhli_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SANGLI_URBAN",
      full_name: "Sangli Urban Co-Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sangli_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SURATBNK",
      full_name: "The Surat District Co-Op. Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/suratbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNE_PEOPLE",
      full_name: "Pune Peoples Co-op Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pune_people_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MUMBAI_DISTRICT",
      full_name: "The Mumbai District Central Co-operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mumbai_district_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SABARKANTHA",
      full_name: "The Sabarkantha District Central Co-Op. Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sabarkantha_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KALUPURBNK",
      full_name: "The Kalupur Commercial Co.op. Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kalupurbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SUTEXBNK",
      full_name: "The Sutex co-op bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sutexbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HINDUSTHAN",
      full_name: "The Hindusthan Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/hindusthan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAVI_MUMBAI",
      full_name: "Navi Mumbai Co op Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/navi_mumbai_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TELANGANA",
      full_name: "Telangana Grameena Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/telangana_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MEHSANA",
      full_name: "The co-op bank of Mehsana",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mehsana_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JUGALKISHOR",
      full_name: "Jugalkishor Tapdiya Shree Mahesh Urban co-op bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/jugalkishor_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UTTRAKHAND_GRAMIN",
      full_name: "Uttrakhand gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/uttrakhand_gramin_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAMARTH_SAHAKARI",
      full_name: "Samarth Sahakari Bank Ltd.Solapur",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/samarth_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "POORNAWADI_NAGRIK",
      full_name: "Poornawadi Nagrik Sahakari Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/poornawadi_nagrik_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NANDED",
      full_name: "Nanded Merchants Co-Op. Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/nanded_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARNATAKA_VIKAS",
      full_name: "Karnataka Vikas Grameena Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/karnataka_vikas_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JALGAON_SAHKARI",
      full_name: "The Jalgaon Janata Sahakari Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/jalgaon_sahkari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "INDIA_POST",
      full_name: "India post payments bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/india_post_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HASTI",
      full_name: "The Hasti Co-Op Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/hasti_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GP_PARSIK",
      full_name: "GP Parsik sahakari bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/gp_parsik_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DAPOLI",
      full_name: "DAPOLI URBAN CO-OP BANK LTD.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/dapoli_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "APCOB",
      full_name: "The Andhra Pradesh State Co-Operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/apcob_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AMBAJOGAI_PEOPLES",
      full_name: "Ambajogai Peoples co-op bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/ambajogai_peoples_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AHMADABAD_DISTRICT",
      full_name: "Ahmadabad district co-op bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/ahmadabad_district_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "WBSCB",
      full_name: "The West Bengal State Co-Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/wbscb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PRIYADARSHANI",
      full_name: "Priyadarshani Nagari Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/priyadarshani_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DEENDAYAL",
      full_name: "Deendayal Nagari Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/deendayal_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VASAI",
      full_name: "Vasai Vikas Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vasai_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "WAI_URBAN",
      full_name: "The Wai Urban Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/wai_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SIDDHESHWAR",
      full_name: "Siddheshwar Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/siddheshwar_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CHHATTISGARH",
      full_name: "Chhattisgarh Rajya Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/chhattisgarh_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CITIZEN_CREDIT",
      full_name: "Citizen Credit Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/citizen_credit_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HUTATMA",
      full_name: "Hutatma Sahakari Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/hutatma_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VAISHYA",
      full_name: "Vaishya Sahakari BankLtd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vaishya_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BANAS",
      full_name: "Banaskantha District Central Cooperative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/banas_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RDCC",
      full_name: "Ratnagiri District Central Co-Operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rdcc_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DMK_JAOLI",
      full_name: "DMK Jaoli Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/dmk_jaoli_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SPCB",
      full_name: "The Surat People's Co-operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/spcb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHINGAR",
      full_name: "Bhingar Urban Co Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/bhingar_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AMBARNATH",
      full_name: "Ambarnath Jai-Hind Co-Op Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/ambarnath_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHIVALIK",
      full_name: "Shivalik Small Finance Bank ",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/shivalik_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GREATERBNK",
      full_name: "Greater Bombay Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/greaterbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MOTIRAM",
      full_name: "Motiram Agrawal Jalna Merchants co-op bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/motiram_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MAHESH_SAHAKARI",
      full_name: "Mahesh Sahakari Bank LTD  Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mahesh_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SNCB",
      full_name: "Surat National Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sncb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AKOLA_JANATA",
      full_name: "Akola Janta Co-operative bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/akola_janata_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PANDHARPUR",
      full_name: "Pandharpur Urban Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pandharpur_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SARVODAYA_SAHAKARI",
      full_name: "The Sarvodaya Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sarvodaya_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ARIHANTBNK",
      full_name: "Shri Arihant Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/arihantbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KANGRA",
      full_name: "The Kangra Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kangra_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ODPCB",
      full_name: "Omprakash Deora Peoples Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/odpcb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ARVIND_SAHAKARI",
      full_name: "Arvind Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/arvind_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MTHE_BHAYALAKSHMI",
      full_name: "Bhagyalakshmi Mahila Sah Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mthe_bhagyalakshmi_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AHMEDABAD_MERCHENT",
      full_name: "Ahmednagar Merchant's Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/ahmedabad_merchent_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHAGYODAYA",
      full_name: "Bhagyodaya co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/bhagyodaya_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARNATAKA_GRAMIN",
      full_name: "Karnataka Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/karnataka_gramin_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MADHYANCHAL",
      full_name: "MADHYANCHAL GRAMIN BANK",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/madhyanchal_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SATARA_DISTRICT",
      full_name: "The Satara District Central Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/satara_district_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAMPURZILA",
      full_name: "Rampur Zila Sahakari Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rampurzila_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RSMCSBNK",
      full_name: "Rajarshi Shahu Multistate Co-Op Credit Society Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rsmcsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RSBBNK",
      full_name: "Rajgurunagar Sahakari Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rsbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MALAD",
      full_name: "The Malad Sahakari Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/malad_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHARAD",
      full_name: "Sharad Sahakari Bank Ltd ",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sharad_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MOGAVEERA",
      full_name: "The Mogaveera Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mogaveera_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SARASPUR",
      full_name: "Saraspur Nagrik Co-Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/saraspur_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CRSUCBNK",
      full_name: "Shri Chhatrapati Rajarshi Shahu Urban Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/crsucbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GSCB",
      full_name: "The Gujarat State Co-operative Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/gscb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ADARSH",
      full_name: "The Adarsh Co-Operative Urban Bank Limited",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/adarsh_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SNSBNK",
      full_name: "Shankar Nagari Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/snsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DELHIBNK",
      full_name: "The Delhi State Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/delhibnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TIRUPATI",
      full_name: "Tirupati Urban Co-Op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/tirupati_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "YASHBNK",
      full_name: "Yash Urban Co-Operative Credit Society ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/yashbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NEELKANTH",
      full_name: "Neelkanth co-oprerative bank ltd ",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/neelkanth_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JALGAON",
      full_name: "The Jalgaon Peoples Co-Operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/jalgaon_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MEHSANA_URBAN",
      full_name: "The Mehsana Urban Co-operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mehsana_urban.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KAIJSB",
      full_name: "Kallapanna Awade Ichalkaranji Janata Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kaijsb_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNE_URBAN",
      full_name: "Pune Urban Co Operative Bank Ltd ",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pune_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JANASEVA",
      full_name: "Janaseva Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/janaseva_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SANGLI",
      full_name: "Sangli District Central Co.-Op. Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sangli_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VAISHYA_NAGARI",
      full_name: "Vaishya Nagari sahakari bank ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vaishya_nagari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VIZAG",
      full_name: "The Visakhapatnam Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vizag_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CNSBNK",
      full_name: "The Chembur Nagarik Sahakari Bank,",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/cnsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KOKAN",
      full_name: "Kokan Mercantile Co-operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kokan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PANIPAT_URBAN",
      full_name: "The Panipat Urban Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/panipat_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SBMBANK",
      full_name: "SBM Bank India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sbmbank_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHARATI_SAHAKARI",
      full_name: "Bharati Sahakari Bank Ltd, Pune",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/bharati_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KHOLESHWAR",
      full_name: "Kholeshwar Multi-State Co-Operative Credit Society Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kholeshwar_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MEHSANA_NAGRIK",
      full_name: "The Mehsana Nagrik sahakari bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/mehsana_nagrik_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNE_DISTRICT",
      full_name: "Pune District Central Co-Operative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pune_district_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VSCBNK",
      full_name: "Vikas Souharda Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vscbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SURAT_MERCANTILE",
      full_name: "The Surat Mercantile Co-op. Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/surat_mercantile_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AKHAND_ANAND",
      full_name: "Akhand Anand co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/akhand_anand_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AKOLA_WASHIM",
      full_name: "The Akola-Washim District Central Cooperative Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/akola_washim_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUSAD",
      full_name: "Pusad Urban Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/pusad_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BNSBNK",
      full_name: "Bhagini Nivedita Sahakari Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/bnsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJKOT_DISTRICT",
      full_name: "Shri Rajkot District Co-operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rajkot_district_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TSCABBNK",
      full_name: "Telangana State Co-Operative Apex Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/tscabbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ANDHRA_MAHESH",
      full_name: "Andhra Pradesh Mahesh Co-operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/andhra_mahesh_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LATUR_URBAN",
      full_name: "Latur Urban Co-operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/latur_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "INDRAYANIBNK",
      full_name: "Indrayani Cooperative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/indrayanibnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AJARA_URBAN",
      full_name: "The Ajara Urban Co-Op. Bank Ltd. Ajara",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/ajara_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHIVESHWAR_SAHAKARI",
      full_name: "Sri Shiveshwar Nagri Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/shiveshwar_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "APRSBNK",
      full_name: "Abasaheb Patil Rendal Sahkari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/aprsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KUKARWADA_NAGRIK",
      full_name: "The Kukarwada Nagrik Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/kukarwada_nagrik_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJKOT_COBNK",
      full_name: "The Co-Operative Bank of Rajkot Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/rajkot_cobnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VIDYA_SAHAKARI",
      full_name: "Vidya Sahakari Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vidya_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHGBNK",
      full_name: "Sarva Haryana Gramin Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/shgbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LAXMI_URBAN",
      full_name: "Laxmi urban Co-operative Bank Ltd ",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/laxmi_urban_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SANT_SOPANKAKA",
      full_name: "Sant Sopankaka Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/sant_sopankaka_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VARDHAMAN_URBAN",
      full_name: "Vardhaman Mahila Co-Operative Urban Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GANDHINAGAR_NAGARIK",
      full_name: "Gandhinagar Nagrik Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ADILABAD_DCCBNK",
      full_name: "Adilabad District Co-Op Central Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GAYATRI_URBAN",
      full_name: "The Gayatri Co-Operative Urban Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MUSLIMBNK",
      full_name: "The Muslim Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MSCBNK",
      full_name: "The Maharashtra State Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAVJEEVANBNK",
      full_name: "Navjeevan Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "WARANA_SAHAKARI",
      full_name: "Shree Warana Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AGRASEN_URBAN",
      full_name: "Agrasen Co-op Urban Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAIGANJ_CENTRAL",
      full_name: "Raiganj Central Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KANKARIABNK",
      full_name: "Kankaria Maninagar Nagrik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CAPITAL_SMALL_FINANCE",
      full_name: "Capital Small Finance Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VIJAY_CO_OP_BNK",
      full_name: "Vijay Commercial Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VERAVAL_PEOPLES",
      full_name: "The Veraval Peoples Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VSBBNK",
      full_name: "The Vishweshawar Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/vsbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UNION_CO_OP_BNK",
      full_name: "The Union Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHRI_MAHAVIR_URBAN",
      full_name: "Shri Mahavir Urban Co-Op Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LONAVALA_SAHAKARI",
      full_name: "Lonavala Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NATIONAL_CO_OP_BNK",
      full_name: "The National Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ICHALKARANJI_MERCHANTS",
      full_name: "The Ichalkaranji Merchants Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "OSMANABAD_JANATA",
      full_name: "Osmanabad Janata Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNE_CANT_SAHAKARI",
      full_name: "Pune Cantonment Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JUNAGADH_BNK",
      full_name: "Junagadh Commercial Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KOLHAPUR_URBAN",
      full_name: "Kolhapur Urban Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DECCAN_MERCHANTS",
      full_name: "Deccan Merchants Co-op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "HIMATNAGAR_NAGARIK",
      full_name: "Himatnagar Nagarik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TEXTILE_TRADERS",
      full_name: "Textile Traders Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MAHANAGAR_NAGRIK",
      full_name: "Mahanagar Nagrik Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PRERANA_BNK",
      full_name: "Prerana Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAGPUR_NAGARIK",
      full_name: "Nagpur Nagarik Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UTKAL_GRAMEEN",
      full_name: "Utkal Grameen Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NOIDA_CCBNK",
      full_name: "Noida Commercial Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JAIHIND_URBAN",
      full_name: "Jaihind Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAURASHTRA_CO_BNK",
      full_name: "The Saurashtra Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJKOT_PEOPLES",
      full_name: "Rajkot Peoples Co-Op Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "COASTAL_AREA_BNK",
      full_name: "Coastal Local Area Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHRI_GANESH_SAHAKARI",
      full_name: "Shri Ganesh Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NIDHI_CO_BNK",
      full_name: "Nidhi Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARAD_URBAN",
      full_name: "The Karad Urban Co-op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SINDHUDURG_BNK",
      full_name: "Sindhudurg District Central Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHREE_MAHESH_BNK",
      full_name: "Shree Mahesh Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAMPADA_SAHAKARI",
      full_name: "Sampada Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BANASKANTHA_MERCANTILE",
      full_name: "The Banaskantha Mercantile Co. Operative Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJARSHI_SHAHU",
      full_name: "Rajarshi Shahu Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MAHESH_URBAN",
      full_name: "Mahesh Urban Co-Operative Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VCOBNK",
      full_name: "Vijay Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ZILA_SAHKARI",
      full_name: "Zila Sahkari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BOMBAY_MERCANTILE",
      full_name: "Bombay Mercantile Co-Operative Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "STERLING_URBAN",
      full_name: "Sterling Urban Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KURLA_NAGRIK",
      full_name: "The Kurla Nagrik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UNJHA_NAGARIK",
      full_name: "Unjha Nagarik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ADINATH_BNK",
      full_name: "The Adinath Co-Operative Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SOLAPUR_DISTRICT",
      full_name: "Solapur District Central Co.-Op. Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARODA_RAJASTHAN",
      full_name: "Baroda Rajasthan Kshetriya Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "COMMERCIAL_CO_BNK",
      full_name: "The Commercial Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHADRADRI_URBAN",
      full_name: "Bhadradri Co-Op Urban Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHAVNAGAR_DISTRICT",
      full_name: "The Bhavnagar District Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJARAMBAPU_SAHAKARI",
      full_name: "Rajarambapu Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SATARA_SAHAKARI",
      full_name: "The Satara Sahakari Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNE_MERCHANTS",
      full_name: "Pune Merchants Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PRATHAMA_GRAMIN",
      full_name: "Prathama U.P. Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ALMORA_URBAN",
      full_name: "Almora Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ANNASAHEB_MAGAR",
      full_name: "Annasaheb Magar Sahakari Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ASHOK_SAHAKARI",
      full_name: "Ashok Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BANAS_BNK",
      full_name: "Banas Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BANGIYA_GRAMIN",
      full_name: "Bangiya Gramin Vikash Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DAKSHIN_BIHAR",
      full_name: "Dakshin Bihar Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DHANERA_MERCANTILE",
      full_name: "Dhanera Mercantile Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DISTRICT_CO_OP",
      full_name: "District Co-Op Central Bank Ltd Bidar, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DURGAPUR_STEEL",
      full_name: "Durgapur Steel Peoples Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GADHINGLAJ_URBAN",
      full_name: "Gadhinglaj Urban Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GODAVARI_URBAN",
      full_name: "Godavari Urban Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JALNA_MERCHANTS",
      full_name: "Jalna Merchants Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JAMIA_CO_OP",
      full_name: "Jamia Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JANSEWA_URBAN",
      full_name: "Jansewa Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JHARKHAND_RAJYA",
      full_name: "Jharkhand Rajya Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JIJAMATA_MAHILA",
      full_name: "Jijamata Mahila Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARIMNAGAR_DISTRICT",
      full_name: "Karimnagar District Co-Op Central Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KERALA_GRAMIN",
      full_name: "Kerala Gramin Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KHAMGAON_URBANK",
      full_name: "Khamgaon Urbank Co-op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KOLHAPUR_DISTRICT",
      full_name: "Kolhapur District Central Co-Operative Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KRISHNA_BHIMA",
      full_name: "Krishna Bhima Samruddhi Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LOKMANGAL_CO_OP_BNK",
      full_name: "Lokmangal Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MALVIYA_URBAN",
      full_name: "Malviya Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MANINAGAR_CO_OP_BNK",
      full_name: "Maninagar Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MANVI_PATTANA",
      full_name: "Manvi Pattana Souharda Sahakari Bank Niyamita, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MEDAK_DISTRICT",
      full_name: "Medak District Co-Op Central Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NAVNIRMAN_CO_OP_BNK",
      full_name: "Navnirman Co-op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NOBLE_CO_OP_BNK",
      full_name: "Noble Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PARSHWANATH_CO_OP_BNK",
      full_name: "Parshwanath Co-Op. Bank Ltd., India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PATAN_NAGARIK",
      full_name: "Patan Nagarik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PAVANA_SAHAKARI",
      full_name: "Pavana Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PIMPRI_CHINCHWAD",
      full_name: "Pimpri Chinchwad Sahakari Bank Maryadit Pimpri, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PUNJAB_GRAMIN",
      full_name: "Punjab Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAMESHWAR_CO_OP_BNK",
      full_name: "Rameshwar Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAMRAJYA_SAHAKARI",
      full_name: "Ramrajya Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SAMATA_SAHKARI",
      full_name: "Samata Sahkari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SANMATI_SAHAKARI",
      full_name: "Sanmati Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SARANGPUR_CO_OP_BNK",
      full_name: "Sarangpur Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHIRPUR_PEOPLE",
      full_name: "Shirpur People Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UDAIPUR_URBAN",
      full_name: "The Udaipur Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MANSA_NAGRIK",
      full_name: "The Mansa Nagrik Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SANTRAMPUR_URBAN",
      full_name: "The Santrampur Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "STANCHAR",
      full_name: "Standard Chartered Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VASAI_JANATA",
      full_name: "Vasai Janata Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARODA_CITY_BNK",
      full_name: "The Baroda City Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LASALGAON_MERCHANTS",
      full_name: "The Lasalgaon Merchants Co-op Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SOLAPUR_JANATA",
      full_name: "Solapur Janata Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TAMLUK_GHATAL_BNK",
      full_name: "Tamlukghatal Central Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VERAVAL_MERCANTILE",
      full_name: "Veraval Mercantile Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJASTHAN_STATE",
      full_name: "THE RAJASTHAN STATE CO-OPERATIVE BANK LTD.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "IDAR_NAGARIK",
      full_name: "The Idar Nagarik Sahakari Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BULDANA_URBAN",
      full_name: "Buldana Urban Co-op. Credit Society",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VIDYANAND_CO_BNK",
      full_name: "Vidyanand Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ANNASAHEB_SAVANT",
      full_name: "The Annasaheb Savant Co-Op Urban Bank Mahad Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ADARSH_CO_BNK",
      full_name: "The Adarsh Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MODEL",
      full_name: "Model Co-op. Bank Ltd.",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/model_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "RAJKOT_COMMERCIAL",
      full_name: "Rajkot Commercial Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KUNBI",
      full_name: "The Kunbi Sahakari Bank LTD, Mumbai",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DAIVADNYA",
      full_name: "Daivadnya Sahakara Bank Niyamit",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SUNDARLAL_SAWJI",
      full_name: "Sundarlal Sawji Urban Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BAPUNAGAR_MAHILA",
      full_name: "The Bapunagar Mahila Co-Op Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHRI_ADINATH",
      full_name: "Shri Adinath Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "APPASAHEB_BIRNALE",
      full_name: "Appasaheb Birnale Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PARIJAT_CO_OP_BNK",
      full_name: "Parijat Co-Operative Credit Society ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AMRELI_JILLA_SAHAKARI",
      full_name: "Amreli Jilla Madhyastha Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SOLAPUR_SIDDHESHWAR",
      full_name: "Solapur Siddheshwar Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MPSCB_APEX_BNK",
      full_name: "MP Rajya Sahakari Bank (Apex Bank)",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SBPP_CO_OP_BNK",
      full_name: "Sardar Bhiladwala Pardi People's Co-Operative Bank Ltd",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KURUNDWAD_URBAN_BNK",
      full_name:
        "Swatantra Senani Late Shripal Alase Kurundwad Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SMRITI_NAGRIK",
      full_name: "Smriti Nagrik Sahakari Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MEHSANA_DISTRICT",
      full_name: "Mehsana District Central Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SOCIAL_CO_OP_BNK",
      full_name: "The Social Cooperative Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NANDURA_URBAN",
      full_name: "The Nandura Urban Co Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VISHWAS_CO_OP_BNK",
      full_name: "VISHWAS CO-OPERATIVE BANK LTD",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UDAIPUR_MAHILA_URBAN",
      full_name: "The Udaipur Mahila Urban Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "CHIPLUN_URBAN",
      full_name: "The Chiplun Urban Co-Operative Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHRI_CHHANI_SAHAKARI",
      full_name: "Shri Chhani Sahakari Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SREE_CHARAN_BNK",
      full_name: "Sree Charan Souharda Co-Op Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UNITED_CO_OP_BNK",
      full_name: "United Co-Op. Bank Ltd, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NSDL_PAYMENTS_BNK",
      full_name: "NSDL Payments Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "LOKMANGAL_MULTISTATE",
      full_name: "Lokmangal Multi State Cooperative Society Limited, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BARODA_UP_GRAMIN",
      full_name: "Baroda U.P. Gramin Bank, India",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHILWARA",
      full_name: "BHILWARA URBAN CO-OP.BANK LIMITED",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SHINHAN",
      full_name: "Shinhan Bank India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/shinhan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UNITED",
      full_name: "United Bank of India",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/united_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ZOROASTRIAN",
      full_name: "Zoroastrian Co-Operative Bank",
      logo_url:
        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/bank_logos/placeholder_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AUSFBNK",
      full_name: "AU Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/ausfbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NEWINDIA",
      full_name: "New India Co-Operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/newindia_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BHARATBNK",
      full_name: "Bharat Co-Operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/bharatbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SVCBNK",
      full_name: "SVC Co-Operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/svcbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DNSBNK",
      full_name: "Dombivli Nagari Sahakari Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/dnsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "BANDHAN",
      full_name: "Bandhan Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/bandhan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DBSBNK",
      full_name: "DBS Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/dbsbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "JNKBNK",
      full_name: "Jammu and Kashmir Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/jnkbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "PAYTM",
      full_name: "Paytm Payments Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/paytm_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "UJJIVAN",
      full_name: "Ujjivan Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/ujjivan_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MAHAGRAMBNK",
      full_name: "Maharashtra Gramin Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/mahagrambnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "GSMAHANAGAR",
      full_name: "GS Mahanagar Co-op Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/gsmahanagar_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "DCBBNK",
      full_name: "DCB Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/dcbbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "FINCAREBNK",
      full_name: "Fincare Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/fincarebnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "ESAFBNK",
      full_name: "ESAF Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/esafbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SURYODAYBNK",
      full_name: "Suryoday Small Finance Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/suryodaybnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VILAS",
      full_name: "Lakshmi Vilas Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/vilas_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "APGVBNK",
      full_name: "Andhra Pradesh Grameena Vikas Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/apgvbnk_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "MIZORAM",
      full_name: "Mizoram Rural Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/mizoram_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AIRTEL",
      full_name: "Airtel Payments Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/airtel_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "KARNAVATI",
      full_name: "Karnavati Co-op Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/karnavati_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "AHMEDABAD_MERCANTILE",
      full_name: "The Ahmedabad Mercantile Co-operative Bank Limited",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/ahmedabad_mercantile.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "NUTAN_SAHAKARI",
      full_name: "Nutan Nagarik Sahakari Bank Limited",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/nutan_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "TJSB_SAHAKARI",
      full_name: "TJSB Sahakari Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/tjsb_sahakari_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "VAIDYANATH",
      full_name: "The Vaidyanath Urban Co-Op Bank Ltd",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/vaidyanath_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
    {
      name: "SARASWAT",
      full_name: "Saraswat Co-operative Bank",
      logo_url:
        "https://s3.ap-south-1.amazonaws.com/finbox-cdn/bank_logos/saraswat_light.png",
      is_pdf_upload_available: true,
      is_net_banking_available: false,
      is_aa_available: false,
    },
  ],
};

function AccountAggregator() {
  const [banksList, setBanksList] = useState(banks.banks);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedMethod, setselectedMethod] = useState<
    "PDF" | "AA" | "NETBANKING" | ""
  >("");
  const [redirectLink, setRedirectLink] = useState("");
  const navigate = useNavigate();

  const getAARedirectLink = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      //   myHeaders.append("Authorization", `Bearer ${authToken.value}`);

      const response = await fetch(
        `${APPLICATIONS_BACKEND}/account-aggregator/generate`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({}),
        }
      );

      const result = await response.json();

      setRedirectLink(result.data.url);
      window.open(result.data.url, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const submit = () => {
    //
    if (selectedMethod === "AA") {
      getAARedirectLink();
    } else if (selectedMethod === "PDF") {
      navigate("/account-aggregator/bank-statement");
    } else {
      navigate("/account-aggregator/bank-statement");
    }
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <h3>Account Aggregator</h3>
          <select
            onChange={(e) => {
              setSelectedBank(e.target.value);
              setselectedMethod("");
            }}
          >
            <option value="" defaultChecked>
              Select Bank
            </option>
            {banksList.map((bank) => {
              return (
                <option
                  value={bank.name}
                  key={bank.name}
                  style={{
                    background: `url(${bank.logo_url}) no-repeat left center`,
                  }}
                >
                  {bank.full_name}
                </option>
              );
            })}
          </select>
          {selectedBank && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
              }}
            >
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_aa_available && (
                <div
                  onClick={() => {
                    setselectedMethod("AA");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "AA"
                        ? "2px solid #D32028"
                        : "1px solid rgba(212, 33, 41, 0.15)",
                    background: "#FFF7F2",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={BankIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    Bank Account
                  </p>
                </div>
              )}
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_pdf_upload_available && (
                <div
                  onClick={() => {
                    setselectedMethod("PDF");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "PDF"
                        ? "2px solid #D32028"
                        : "1px solid rgba(212, 33, 41, 0.15)",
                    background: "#FFF7F2",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={PdfIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    PDF Upload
                  </p>
                </div>
              )}
              {banksList.filter((bank) => bank.name === selectedBank)[0]
                .is_net_banking_available && (
                <div
                  onClick={() => {
                    setselectedMethod("NETBANKING");
                  }}
                  style={{
                    borderRadius: "1rem",
                    border:
                      selectedMethod === "NETBANKING"
                        ? "2px solid #D32028"
                        : "1px solid rgba(212, 33, 41, 0.15)",
                    background: "#FFF7F2",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)",
                    padding: "2rem 3rem",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                    }}
                    src={BankIcon}
                    alt=""
                  />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    Net Banking
                  </p>
                </div>
              )}
            </div>
          )}
          <Button
            onPress={() => {
              submit();
            }}
            imageRight={ArrowLeft}
            text={"Continue"}
            disabled={!(selectedMethod && selectedBank)}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AccountAggregator;
