import React from 'react'

export default function Conditions() {
  return (
    <div style={{display:'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',padding:'3rem',}}> 
    <div>
      <h4>Terms & Conditions</h4>

    <p>PLEASE READ THE TERMS AND CONDITIONS ("TERMS" or "T&C") CAREFULLY. THIS IS A LEGALLY BINDING AGREEMENT BETWEEN YOU,
        THE USER, AND MONK CAPITAL PRIVATE LIMITED ("FEEMONK") AND ALL ITS FELLOW SUBSIDIARIES, GROUP COMPANIES AND
        PERMITTED ASSIGNS ESTABLISHING THE TERMS AND CONDITIONS UNDER WHICH THIS WEBSITE MAY BE USED/ACCESSED. BY ACCESSING
        THIS WEBSITE, OR ANY PAGES THEREOF, AND/OR USING THE INFORMATION OR SERVICES PROVIDED ON OR VIA THIS WEBSITE, YOU
        AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. IN THE EVENT YOU ARE NOT AGREEABLE TO THE T&C, YOU NOT ACCESS THE
        WEBSITE OR AVAIL THE SERVICES AVAILABLE ON THE WEBSITE. THE T&C CONTAINED HEREIN EXPRESSLY SUPERSEDE ALL PRIOR
        AGREEMENTS OR ARRANGEMENTS BETWEEN WITH FEEMONK IN RELATION TO THE BROWSING OR USAGE. YOU FURTHER UNDERSTAND THAT
        THE ACCESS TO THE WEBSITE AND THE OFFER OF SERVICES IS CONDITIONAL UPON YOUR IRREVOCABLE CONSENT AND ACCEPTANCE OF
        ALL THE TERMS, CONDITIONS AND OBLIGATIONS CONTAINED HEREIN (AS MAY BE AMENDED FROM TIME TO TIME). IN THE EVENT OF
        ANY CONFLICT BETWEEN THE TERMS AND CONDITIONS OF SPECIFIC PRODUCTS AND SERVICES AND THESE TERMS AND CONDITIONS, THE
        CONDITIONS SPECIFIC TO SUCH PRODUCTS AND SERVICES SHALL PREVAIL.</p>

    <h4>General</h4>

    <p>These Terms, sets forth the terms and conditions that apply to use of this site (www.feemonk.com) and all sub sites
        that reside under the site (collectively 'Website'), by a Subscriber. The Terms are governed by the provisions of
        the applicable Indian laws, the rules, regulations, guidelines, and clarifications framed thereunder, including
        but not limited to:</p>

    <ol>
        <li>The Indian Contract Act, 1872;</li>
        <li>The Information Technology Act, 2000;</li>
        <li>The Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information)
            Rules, 2011;</li>
        <li>The Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
    </ol>

    <p>These Terms and your activity under these Terms are an electronic record as per the (Indian) Information Technology
        Act, 2000 and these Terms being electronically generated by a computer system does not require any physical or
        digital signatures. Under these Terns, "You" means you and every each person who establishes or accesses a
        connection ('Account') for access to and use of the Website.</p>


<div>
    <h4>Restrictions on use</h4>
    <p>The Website are owned and operated by MONK CAPITAL PRIVATE LIMITED and contains material which is derived, in whole or in part, from material supplied by the Company, its group companies, various news agencies and other sources (including content partners), and is protected by international copyright and trademark laws. The restrictions on use of the material and content on the Website by the Subscriber are specified below. Except where specifically authorised, the Subscriber may not modify, copy, reproduce, republish, upload, post, transmit or distribute in any way any material from the Website including code and software.</p>
</div>

<div>
    <h4>Compliance with the Terms</h4>
    <p>By using the Website, you agree to comply with all of the terms and conditions hereof. The right to use the Website is personal to you and is not transferable to any other person or entity. You are responsible for all use of your account (under any screen name or password) and for ensuring that all use of your account complies fully with the provisions of this Agreement. You shall be responsible for protecting the confidentiality of your password(s), if any.</p>
</div>

<div>
    <h4>Changed Terms</h4>
    <p>The Company shall have the right at any time to change or modify the terms and conditions applicable to Subscriber's use of the Website, or any part thereof, or to impose new conditions, including but not limited to, adding fees and charges for use. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by any means, including but not limited to, posting on the Website, or by electronic or conventional mail, or by any other means by which you obtain notice thereof. Any use of the Website by Subscriber after such notice shall be deemed to constitute acceptance by you of such changes, modifications or additions.</p>
</div>

<div>
    <h4>Use of Information and Materials</h4>
    <p>The content (material, information, data, money market movements, news items, texts, graphics, links etc.) contained on this Website is provided for general information only and should not be used as a basis for making business/commercial decisions. You are advised to exercise due caution and/or seek independent advice before availing any facility or entering into any investment or financial obligation based on the Content contained on this Website.</p>
    <p>The Content contained on this Website or other terms are provided on an "as is", "as available" basis and are protected by copyright. You may not distribute the Content to others without the express written consent of FeeMonk. You may not copy, download, publish, distribute or reproduce any of the Content contained on this Website in any form without prior permission of FeeMonk.</p>
    <p>The Content on this Website should not be regarded as an offer, solicitation, invitation, advice or recommendation to buy or sell investments, securities or any other instrument or financial products / schemes of FeeMonk, or any of its subsidiaries/affiliates. Use of the products or services described on this Website may not be permitted in some countries and if in doubt, you should check with your local regulator or authority before requesting further information on such products/ services.</p>
</div>

<div>
    <h4>Products</h4>
    <p>Products and Services are available only at the discretion of FeeMonk and its fellow subsidiaries, subject to the individual contractual terms and conditions of products and services on which they are offered and such products and services may be withdrawn or amended at any time without notice. The full range of products or services may not be available in all locations. The user acknowledges that certain functions of this Website would require an active internet connection which can be Wi-Fi or the Local Area Network (LAN) connection. FeeMonk and its fellow subsidiaries do not assume any responsibility/liability if the Website is not fully functional due to absence/ insufficiency of the required internet connection.</p>
    <p>All rates, charges and fees quoted / stated for various financial products and schemes and interest rates cited as examples of rates which may be in effect from time to time are indicative rates only and are subject to change at any time at the sole discretion of FeeMonk and its fellow subsidiaries as the case may be, and applicable Indian laws. These charges, fees and rates may change depending upon the assessment made by FeeMonk in individual cases upon receiving necessary information and documents.</p>
</div>

<div>
    <h4>Indicative Loan Terms</h4>
    <p><strong>Loan:</strong> The Loan provided by FeeMonk shall be the amount stated in the sanction letter which shall be strictly in accordance with the terms and conditions mentioned herein and based on other documents executed by you.</p>
    <p><strong>Rate Of Interest:</strong> The rate of interest will be specified in the sanction letter based upon your risk gradation</p>
    <p><strong>Disbursement:</strong> FeeMonk will disburse the loan to your designated bank account by way of direct bank transfer via NEFT, IMPS or through such other modes as FeeMonk deems fit in its absolute discretion.</p>
    <p><strong>Repayment:</strong> You shall pay to FeeMonk all instalments, inclusive of interest, on a monthly basis on the Scheduled Due Date, from the first due date to the last due date as mentioned in the Sanction Letter/ loan agreements (as may be required by the Lender to its satisfaction), without fail on the first presentation.</p>
    <p>In order to facilitate such repayment, FeeMonk has provided various options that can be relied upon by you., including, without limitation, debit card payment, Net Banking Payment, direct bank transfer, etc. In order to further secure such payments, FeeMonk relies on securest payment gateway like Razorpay, etc.</p>
    <p><strong>Refund:</strong> All refunds in relation to your loan that are initiated by us will be credited to the account/card from where the original transaction had initiated. Such refunds shall be credited within the statutory time period.</p>
</div>

<div>
    <h4>Equipment</h4>
    <p>You shall be responsible for obtaining and maintaining all telephone, computer hardware and other equipment needed for access to and use of the Website and all charges related thereto.</p>
</div>

<div>
    <h4>Intellectual Property Rights</h4>
    <p>The term "FeeMonk" is a registered trademark and the rights of the Website as well as the mark FeeMonk" are exclusively owned by FeeMonk. As long as you respect these terms and conditions for browsing/ using this Website, FeeMonk grants you a non – exclusive, non-transferable and revocable license to use this Website. All rights relating to this website as well as its functionalities are the exclusive property of FeeMonk (particularly including but not limited to copyrights, trademarks, source code, patents as well as any other intellectual property right). All the information provided on or via this Website (including all texts, graphics, design or logos) shall be the intellectual property of FeeMonk and or its fellow subsidiaries/ affiliates. You agree not to copy, forward, download or share content without obtaining the necessary rights or permissions to do so. You also agree not to abuse the use of this Website.</p>
</div>

<div>
    <h4>Limited Use</h4>
    <p>While browsing the Website or using any Services provided herein, you agree that you shall not:</p>
    <ol>
        <li>use FeeMonk's Services for spamming or any other illegal purposes;</li>
        <li>infringe FeeMonk's or any third party's intellectual property rights, rights of publicity or privacy;</li>
        <li>post or transmit any message, data, image or program which violates any law or which discloses private or personal matters concerning any person;</li>
        <li>refuse to cooperate in an investigation or provide confirmation of your identity or any other information you provide to FeeMonk;</li>
        <li>remove, circumvent, disable, damage or otherwise interfere with security related features of the FeeMonk's Services or features that enforce limitations on the use of FeeMonk's Services and Website;</li>
        <li>upload any content that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
        <li>reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of FeeMonk's Services or any part thereof or infringe any patent, trademark, copyright or other proprietary rights;</li>
        <li>use FeeMonk's Services in any manner that could damage, disable, overburden, or impair, including, without limitation, using FeeMonk's Services in an automated manner;</li>
        <li>interfere or disrupt FeeMonk's Services or networks connected in addition to that;</li>
        <li>use the facilities and capabilities of FeeMonk's Services to conduct any activity or solicit the performance of any illegal activity or other activity which infringes the rights of others;</li>
        <li>provide false, inaccurate or misleading information to FeeMonk's Services and Website; and</li>
        <li>use FeeMonk's Services to collect or obtain personal information, including without limitation, personal information about other Users of FeeMonk's Services and Website.</li>
    </ol>
</div>

<div>
    <h4>Linked Websites</h4>
    <p>This Website may contain links to other websites of affiliate companies or fellow subsidiaries of FeeMonk. This Website also may contain links to external websites, having further linked websites, controlled or offered by third parties (non-affiliates of FeeMonk), in order to help you find relevant websites, services and/or products which may be of interest to you, quickly and easily. The contents displayed or products / services offered on such linked websites or any quality of the products/ services are not endorsed, verified or monitored by FeeMonk or its affiliates. FeeMonk and its affiliates are also not responsible for the owners or operators of such external links or websites or for any products or services they supply or for the contents of their websites and do not give or enter into any conditions, warranties, express or implied; or other terms or representations in relation to any of these or accept any liability in relation to any of these (including any liability arising out of any claim that the content of any external websites to which this website includes a link infringes the intellectual property rights of any third party).</p>
</div>

<div>
    <h4>Data Protection</h4>
    <p>The storage, usage and transmission of all information obtained by it through the Website, shall at all times be in accordance with the Information Technology Act, 2000 read with Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and all other applicable law. In the event FeeMonk obtains any personal data or confidential information pursuant to any transaction/Services, it shall only undertake the processing of Personal Data/Confidential Information reasonably required in connection with the performance of its obligations under the transaction/Services.</p>
    <p>FeeMonk shall at all times have appropriate technical and organisational measures in place to:</p>
    <ul>
        <li>prevent unauthorised or unlawful processing of any Personal Data/Confidential Information;</li>
        <li>protect any Personal Data/Confidential Information against accidental loss, destruction or damage;</li>
        <li>ensure the reliability of its employees/contractor having access to the Personal Data/Confidential Information;</li>
    </ul>
</div>

<div>
    <h4>Refund & Cancellation Policy</h4>
    <p>Any additional payment made by You shall be refunded back within 30 days pursuant to the reconciliation of the accounts by FeeMonk. There will be no cancellation of the service once You have received the loan amount in Your bank account.</p>
</div>

<div>
    <h4>Disclaimer of Warranty: Limitation of Liability</h4>
    <p>You expressly agree that use of Website is at your sole risk. Neither FeeMonk, its affiliates nor any of their respective employees, agents, third party content providers or licensors warrant that Website will be uninterrupted or error free; nor do they make any warranty as to the results that may be obtained from use of Website, or as to the accuracy, reliability or content of any information, service, or merchandise provided through Website.</p>
    <p>Website is provided on an "As Is" basis without warranties of any kind, either express or implied, including, but not limited to, warranties of title or implied warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to this agreement.</p>
    <p>This disclaimer of liability applies to any damages or injury, caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tortious behaviour, negligence, or under any other cause of action. In no event will FeeMonk, or any person or entity involved in creating, producing or distributing Website or the Website software, be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential or punitive damages arising out of the use of or inability to use Website. You hereby acknowledge that the provisions of this section shall apply to all content on Website.</p>
    <p>In addition to the terms set forth above neither FeeMonk, nor its affiliates, information providers or content partners shall be liable regardless of the cause or duration, for any errors, inaccuracies, omissions, or other defects in, or inauthenticity of, the information contained within Website, or for any delay or interruption in the transmission thereof to the user, or for any claims or losses arising therefrom or occasioned thereby. None of the foregoing parties shall be liable for any third-party claims or losses of any nature, including, but not limited to, lost profits, punitive or consequential damages. FeeMonk, its affiliates, information providers or content partners shall have no liability for investment decisions based on the information provided. Neither, the Company, nor its affiliates, information providers or content partners warrant or guarantee the timeliness, sequence, accuracy or completeness of any information.</p>
</div>

<div>
    <h4>GOVERNING LAW AND JURISDICTION</h4>
    <p>These Terms will be governed by the laws of the India, without regard to its conflict of laws rules. Any dispute arising on account of the Website of usage of any Services, will be exclusively settled by the courts located in Hyderabad, India.</p>
</div>

<div>
    <h4>DETAILS OF GRIEVANCE REDRESSAL OFFICER</h4>
    <p>In accordance with Information Technology Act, 2000 and rules made there under, the name and contact details of the Grievance Officer of FeeMonk are as provided below:</p>
    <p><strong>Name:</strong> K Vikram<br/>
       <strong>Address:</strong> MONK CAPITAL PRIVATE LIMITED PLOT NO : 1-82/2/SUIT "B" PRASANNA HITEX 2nd FLOOR, MANCHIREVULA, HYDERABAD TS 500089<br/>
       <strong>Phone No:</strong> 9100820555<br/>
       <strong>Email id:</strong> hello@feemonk.com</p>
</div>

<div>
    <h4>BREACH OF THE TERMS</h4>
    <p>Without prejudice to FeeMonk's other rights under these Terms, if you breach these Terms in any way, or if FeeMonk suspect that you have breached these Terms in any way, then FeeMonk shall be entitled to take such action as it may deem fit.</p>
</div>


        </div>
        </div>
  )
}
