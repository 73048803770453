import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Grid, Box, Typography, Divider } from "@mui/material";
import { Mainpagestyle } from "./Style";
import { useLocation, useNavigate } from "react-router-dom";
import {useDispatch,useSelector} from 'react-redux';
import {userActions} from '../../actions/user.actions';
import Educationcard from "../Home/Educationcard";
import Preveiouspayment from "./Preveiouspayment";
import Cap from "../../Assets/images/cap.svg";
import Insitutte from "../../Assets/images/Insitutte.svg";
import Pay from "../../Assets/images/Pay.svg";
import axiosInstance from "../../helpers/axios";
import { jwtDecode } from "jwt-decode";
import wallet from "../../Assets/images/wallet.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

export default function Feepayment({sendUpdatedRows,payments,sendRows,amount,discountedAmount,totalAmount}) {
  console.log(sendRows,"ADasda")
  const dispatch=useDispatch();
  const state=useSelector((state)=>state);
  const [rows, setRows] = useState(sendRows);
  const [updatedRow, setUpdatedRow] = useState(sendUpdatedRows);
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentPayload, setPaymentPayload] = useState();
  const token = sessionStorage.getItem("token")?sessionStorage.getItem("token"):window?.location?.href?.split('?')[1]?.split('&')[0]?.split('=')[1]
  const decode = jwtDecode(token)
  const [loading,setLoading] = useState(false)
  // console.log("Location" , location)
  const [selectedItems, setSelectedItems] = useState([]);
  console.log("selected items-->",selectedItems)
  const testEaseBuzz=()=>{
    setLoading(true)
    if(sendRows[0]?.instituteId==="44d7fbd7-21a5-4891-94e8-84339801011f")
      {
        const cashFreeUrl=`/cashfree-pl/create`
        const payload= {   amount: parseFloat(dueAmount).toFixed(1),
          studentId:sendRows[0]?.studentId,
          orderId:selectedItems,
          productinfo: sendRows[0]?.paymentName,
          firstname: sendRows[0]?.studentName,
          phone: sendRows[0]?.phone,
          branchId:sendRows[0]?.branchId,
          email: sendRows[0]?.email,
          paymentId:sendRows[0]?._id
        }
        axiosInstance.post(`${process.env.REACT_APP_INSTITUTE_URL}` + cashFreeUrl,payload)
        .then((res)=>{
          window.open(res?.data?.data,"_self")
          const loadingTimeout = setTimeout(() => {
            setLoading(false);
          }, 800);
            console.log("cashfree",res)
        }).catch((err)=>console.log(err))      }
    else
      {
        const urlEaseBuzz=`/easebuzz/payment_link/create-link`
       const payload= {   amount: parseFloat(dueAmount).toFixed(1),
         studentId:sendRows[0]?.studentId,
         orderId:sendRows[0]?.orderId,
         productinfo: sendRows[0]?.paymentName,
         firstname: sendRows[0]?.studentName,
         phone: sendRows[0]?.phone,
         branchId:sendRows[0]?.branchId,
         email: sendRows[0]?.email,
         paymentId:sendRows[0]?._id
       }
       axios.post(`${process.env.REACT_APP_INSTITUTE_URL}`+urlEaseBuzz,payload)
       .then((res)=>{
         console.log(res)
          
         window.open(res?.data?.res,"_self")
         const loadingTimeout = setTimeout(() => {
          setLoading(false);
        }, 800);
       })
       .catch((err)=>{
         console.log(err)
       })
   
     
      }
    }
  // function onPayClickListener(item) {
  //   const easeBuzzPayload = {
  //     amount: parseFloat(item.due_amount),
  //     productinfo: item.payment_name,
  //     firstname: location?.state?.user?.first_name,
  //     phone: location?.state?.user?.phone,
  //     email: location?.state?.user?.email,
  //     paymentId: item._id,
  //   };

  //   axiosInstance
  //     .post("/easebuzz/payment_link/create", easeBuzzPayload)
  //     .then((res) => {
  //       console.log("Response", res?.data?.res?.data);
  //       window.open("https://testpay.easebuzz.in/pay/" + res?.data?.res?.data);
  //     })
  //     .catch((e) => {
  //       console.log("Errror", e);
  //     });
  // }

  // function onRowsSelectionHandler(ids){

  //     console.log("Ids" , ids)

  //     let payment  = {}
  //     if(ids.length > 0){
  //         rows.map((item) => {

  //             if(item._id === ids[0] ){
  //                 payment = item;
  //             }
  //         })
  //     }

  //     console.log("Phone" ,location?.state?.user?.phone )

  //     setPaymentPayload(easeBuzzPayload)

  // }

  const [dueAmount,setDueAmount] = useState(0)
  const [checkedCount,setCheckedCount] = useState(0)

  const handleCheckboxClick = (array, index) => {
    console.log("index", array, index);
    const checkbox = document.getElementById(`check${index}`);
    const isChecked = checkbox.checked;

    if (!isChecked) {
      // Unchecking: uncheck all checkboxes up to the current index
      for (let i = index; i >= 0; i--) {
        const currentCheckbox = document.getElementById(`check${i}`);
        if (currentCheckbox) {
          currentCheckbox.checked = false;
        }
      }
      setSelectedItems([]);
      setDueAmount(0);
      setCheckedCount(0);
    } else {
      // Checking: check all checkboxes up to the current index
      let newSelectedItems = [];
      let newDueAmount = 0;
      for (let i = 0; i <= index; i++) {
        const currentCheckbox = document.getElementById(`check${i}`);
        if (currentCheckbox) {
          currentCheckbox.checked = true;
          newSelectedItems.push(array[i].orderId);
          newDueAmount += array[i].due_amount;
        }
      }
      setSelectedItems(newSelectedItems);
      setDueAmount(newDueAmount);
      setCheckedCount(index + 1);
    }
  };

  const totalPaymentLink=()=>{
    setLoading(true)
    const cashFreeUrl=`/cashfree-pl/total`
    const body={
    amount: discountedAmount,
    studentId: sendRows[0]?.studentId,
    email: sendRows[0]?.email,
    phone: sendRows[0]?.phone,
    productInfo: sendRows[0]?.paymentName,
    branchId: sendRows[0]?.branchId,
    firstName: sendRows[0]?.studentName,
    instituteId: sendRows[0]?.instituteId,
    course: sendRows[0]?.course,
    uniqueInstituteId:sendRows[0]?.uniqueInstituteId,
    mandate:'false'
    }
    axiosInstance.post(`${process.env.REACT_APP_INSTITUTE_URL}` + cashFreeUrl,body)
        .then((res)=>{
          window.open(res?.data?.data,"_self")
          const loadingTimeout = setTimeout(() => {
            setLoading(false);
          }, 800);
            console.log("cashfree",res)
        }).catch((err)=>{
          alert(err?.response?.data?.message)
          console.log(err)
        }
          ) 
  }

  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    console.log("clicked")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>

      <Mainpagestyle>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"  style={{ position: 'absolute', top: '-60%', left: '0%' }}
      >
        <DialogTitle id="alert-dialog-title" color='red'>
          {"Alert !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color='red'>
            Please Select the Amount by clicking checkbox to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={handleClose} autoFocus style={{color:'red',justifyContent:'flex-end',backgroundColor:'red',width:'1rem',color:'white'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
         <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
         <CircularProgress />
       </Box>
      ):(
        <Box my={5}>
        <Grid container>
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="body2" component="div" className="payment">
            <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',whiteSpace:'nowrap'}}>Current Fee Payments </span> 

              <svg
                width="5"
                height="23"
                viewBox="0 0 5 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.461426"
                  y="0.772095"
                  width="4.17139"
                  height="21.7188"
                  rx="2.08569"
                  fill="#D32028"
                />
              </svg>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9}>
            <Box mt={2} ml={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        {/* card section started */}
        {/* {sendRows.length > 0 && payments.length === 0 && sendRows[0]?.instituteId==="44d7fbd7-21a5-4891-94e8-84339801011f" ? (
          <div style={{backgroundColor:'#FFF8F4',border:'1px solid #F9D8D6',boxShadow:"0px 3px 3px rgba(211, 32, 40, 0.1)",borderRadius:'12px',marginTop:'0.5em'}}>
           <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
            <Button
              onClick={() => totalPaymentLink()}
              variant="contained"
              color="primary"
              className="paynowbtn"
              style={{ width: '1em', fontSize: '1em', flexWrap: 'nowrap',marginLeft:'5px' }}
            >
              Click
            </Button>
            <p style={{ marginTop: '1.3em', marginLeft: '0.5em', fontWeight: 'bold', flexWrap: 'nowrap' }}>
              to pay entire course fee{' '}
              <span style={{fontWeight: 'bold', fontFamily: 'outfit-medium', marginLeft: '1em',textDecoration:'line-through' }}> ₹{formatNumberWithCommas(totalAmount)}</span>
              <span style={{fontWeight: 'bold', fontFamily: 'outfit-medium', marginLeft: '1em' }}>
                ₹ {formatNumberWithCommas(discountedAmount)}
              </span>
            </p>
          </Grid>
        </Grid>
        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item style={{ display: 'flex', justifyContent: 'center'}}>
          <p style={{fontFamily: 'outfit-medium'}}>Note: No Merit Scholarship Applicable</p>
          </Grid>
        </Grid>
          </div>
       
      ) : null} */}
<div>
              <Grid item xs={12}>
            <Grid container  style={{padding:'0%',display:'flex',alignItems:'center',justifyContent:'center',marginTop:'1em'}}>
              {sendRows.length > 0 && sendRows.some(item => item.status !== "success") > 0  ? (
              <Grid item xs={12} sm={6} md={6} lg={4}>
              <div style={{borderStyle:'solid',borderColor:'#A5A5A5',borderRadius:'14px',borderWidth:'1px',display:'flex',justifyContent:'space-between',padding:'10px',alignItems:'center'}}>
                <div style={{fontFamily:'Inter-Medium'}}>
                Total
                {/* <span style={{fontFamily:'outfit-medium',fontWeight:'bold',marginLeft:'5px'}}>{checkedCount}</span> */}
                </div>
                <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                <div style={{fontFamily:'Inter-Medium'}}>₹{dueAmount?formatNumberWithCommas(dueAmount):"0"}</div>
                <div style={{ alignSelf: 'center', height: '18px',  background: '#393939',width:'1px' ,margin:'7px'}}></div>
                <div style={{backgroundColor:'#CB1F26',borderRadius:'10px',paddingLeft:'10px',paddingRight:'10px',paddingTop:'5px',paddingBottom:'5px',cursor:'pointer',whiteSpace:'nowrap',fontSize:'14px'}} ><span onClick={checkedCount >=1 ? ()=>testEaseBuzz():handleClickOpen}><img style={{height:'12px',width:'20px',marginRight:'5px'}} src={wallet}/><span style={{fontFamily:'Inter-Medium',color:'white'}}  >Pay now</span></span></div>

              </div>

              </Grid>
              ):null}
             
             </Grid>
            </Grid>
              </div>
        
        
        {sendRows?.filter((item)=>(item?.status === "Pending" || item?.status === "failure" || item?.status === "preinitiated" || item?.status === "Scheduled") && 
       item.paymentName !== "total_fees")?.length>0?sendRows?.map((item,index) => {
          if ((item?.status === "Pending" || item?.status === "failure" || item?.status === "preinitiated" || item?.status === "Scheduled") && item.paymentName !== "total_fees") {
            return (
              <>
              
          <div style={{display:'flex'}}>
              
              <div style={{display:'flex',lineHeight:'1rem',justifyContent:'center',alignItems:'center',marginTop:'-1rem',marginRight:'1rem',flexDirection:'column'}}>
               <input id={`check${index}`} style={{height:'20px',width:'20px'}} type="checkbox"  onClick={() => handleCheckboxClick(sendRows,index)}></input>
             </div>
           <div className="loan-payment-crd" style={{marginLeft:'1em'}}>
             
             <Grid  container  spacing={2} >
               <Grid item xs={3} sm={6} md={3} lg={item.status!=="Pending"&&item.status!=="preinitiated"?3:2.5}>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Beneficiary"
                 >
                   <svg
                     width="23"
                     height="23"
                     viewBox="0 0 23 23"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M18.8149 6.31734V9.51771C18.8149 9.70935 18.6616 9.86266 18.47 9.86266C18.2783 9.86266 18.125 9.70935 18.125 9.51771V6.72745L16.3926 7.7508V11.5951C16.3926 13.086 15.718 14.4198 14.6602 15.309V18.6359L22.0613 21.6638C22.2376 21.7366 22.3219 21.9397 22.2491 22.1161C22.1763 22.2924 21.9731 22.3767 21.7968 22.3039L14.1811 19.184C14.0508 19.1303 13.9664 19.0038 13.9664 18.862V15.792C13.2382 16.2136 12.4065 16.4397 11.5441 16.4397C10.6817 16.4397 9.85004 16.2136 9.12181 15.792V18.862C9.12181 19.0038 9.03749 19.1303 8.90717 19.184L1.29144 22.3C1.11513 22.3729 0.911997 22.2885 0.839174 22.1122C0.766351 21.9359 0.850672 21.7328 1.02698 21.66L8.42807 18.6321V15.3052C7.37023 14.416 6.69566 13.0822 6.69566 11.5912V7.74697L3.74825 6.00689C3.52212 5.87274 3.52212 5.54312 3.74825 5.40897L11.3678 0.913126C11.4751 0.847969 11.6131 0.847969 11.7204 0.913126L19.3362 5.41281C19.5623 5.54695 19.5623 5.87657 19.3362 6.01072L18.8149 6.31734ZM6.69949 6.94592V6.75045C6.69949 6.6163 6.77998 6.49365 6.8988 6.43616C8.44341 5.72326 9.99185 5.36681 11.5441 5.36681C13.0964 5.36681 14.6448 5.72326 16.1895 6.43616C16.3121 6.49365 16.3888 6.6163 16.3888 6.75045V6.94592L18.4776 5.71176L11.5441 1.61069L4.60679 5.71176L6.69949 6.94592ZM7.38939 9.86266V11.5951C7.38939 13.8909 9.24829 15.7498 11.5441 15.7498C13.84 15.7498 15.6989 13.8909 15.6989 11.5951V9.86266H7.38939ZM7.38939 6.97275V9.17276H15.6989V6.97275C14.3114 6.36334 12.9278 6.05671 11.5441 6.05671C10.1605 6.05671 8.77686 6.3595 7.38939 6.97275Z"
                       fill="black"
                       stroke="black"
                       stroke-width="0.5"
                     />
                   </svg>
                   Student name
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Tuviksh"
                 >
                   {item.studentName}
                 </Typography>
               </Grid>
               <Grid item xs={12} sm={6} md={3} lg={3}>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Program"
                 >
                   <Box
                     component="img"
                     src={Cap}
                     className="cap-setting"
                   />
                   Program/Class
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Class"
                 >
                   {item.paymentName}
                 </Typography>
               </Grid>
               
               <Grid item xs={12} sm={6} md={2.5} lg={2.5}>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Program"
                 >
                   <Box
                     component="img"
                     src={Insitutte}
                     className="cap-setting"
                   />
                   Institute/School
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Class"
                 >
                   {item.paymentName}
                 </Typography>
               </Grid>
             <Grid item xs={12} sm={6} md={2} lg={item.status!=="Pending"&&item.status!=="preinitiated"?2:2.5}>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Program"
                 >
                   <Box
                     component="img"
                     src={Pay}
                     className="cap-setting"
                   />
                   Payable Fee
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Class"
                 >
                   ₹ {formatNumberWithCommas(item.due_amount)}
                 </Typography>
               </Grid>
                { item.status!=="Pending"&&item.status!=="preinitiated"&&item.status!=="Scheduled"?
               <Grid item xs={12} sm={6} md={2} lg={1.5}>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Program"
                 >
                   <Box
                     component="img"
                    
                     className="cap-setting"
                   />
                   Status
                 </Typography>
                 <Typography
                   variant="body2"
                   component="div"
                   className="Class"
                 >
                  <span style={{backgroundColor:item.status==="failure"?"#FEEAEB":"#fffa8e",padding:"4px",borderRadius:'10px',fontSize:'14px',color:item.status==="failure"?"#D32028":"black",border:item.status==="failure"?'1px solid #d32028':"1px solid #DbDbDb"}}>{item.status}</span>
                 </Typography>
               </Grid>:null}
               {/* <Grid item xs={2} sm={6} md={2.5} lg={2}>
                 <Button
                   onClick={() =>testEaseBuzz(item)}
                   variant="contained"
                   color="primary"
                   className="paynowbtn"
                 >
                   <svg
                     width="22"
                     height="17"
                     viewBox="0 0 22 17"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                   >
                     <path
                       d="M1.16895 6.38912H21.1689M2.98713 0.93457H19.3508C20.3549 0.93457 21.1689 1.7486 21.1689 2.75275V13.6618C21.1689 14.666 20.3549 15.48 19.3508 15.48H2.98713C1.98297 15.48 1.16895 14.666 1.16895 13.6618V2.75275C1.16895 1.7486 1.98297 0.93457 2.98713 0.93457Z"
                       stroke="white"
                       stroke-width="1.5"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                   </svg>
                   Pay now
                 </Button>
               </Grid> */}
             </Grid>
           </div>
           </div>
              </>
              
                      
             
            );
            } 
             
        })
      : 
        <div><p
          style={{
            padding: "1em",
            marginLeft: "1em",
            marginRight: "1em",
            marginTop: "1em",
            borderRadius: "5em",
            color: "#D32028",
            backgroundColor: "#F0F0F0",
            fontSize: "1.5em",
            fontFamily: "Outfit",
            textAlign: "center",
          }}
        >
         No active fee payments
        </p></div>
      }
        {/* card section ended */}

        {/* card section started
          <div className='payment-crd' >
      <Grid container spacing={3}  >
      <Grid item xs={12} sm={6} lg={2} >
      <Typography variant="body2" component="div" className='Beneficiary' >
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.8149 6.31734V9.51771C18.8149 9.70935 18.6616 9.86266 18.47 9.86266C18.2783 9.86266 18.125 9.70935 18.125 9.51771V6.72745L16.3926 7.7508V11.5951C16.3926 13.086 15.718 14.4198 14.6602 15.309V18.6359L22.0613 21.6638C22.2376 21.7366 22.3219 21.9397 22.2491 22.1161C22.1763 22.2924 21.9731 22.3767 21.7968 22.3039L14.1811 19.184C14.0508 19.1303 13.9664 19.0038 13.9664 18.862V15.792C13.2382 16.2136 12.4065 16.4397 11.5441 16.4397C10.6817 16.4397 9.85004 16.2136 9.12181 15.792V18.862C9.12181 19.0038 9.03749 19.1303 8.90717 19.184L1.29144 22.3C1.11513 22.3729 0.911997 22.2885 0.839174 22.1122C0.766351 21.9359 0.850672 21.7328 1.02698 21.66L8.42807 18.6321V15.3052C7.37023 14.416 6.69566 13.0822 6.69566 11.5912V7.74697L3.74825 6.00689C3.52212 5.87274 3.52212 5.54312 3.74825 5.40897L11.3678 0.913126C11.4751 0.847969 11.6131 0.847969 11.7204 0.913126L19.3362 5.41281C19.5623 5.54695 19.5623 5.87657 19.3362 6.01072L18.8149 6.31734ZM6.69949 6.94592V6.75045C6.69949 6.6163 6.77998 6.49365 6.8988 6.43616C8.44341 5.72326 9.99185 5.36681 11.5441 5.36681C13.0964 5.36681 14.6448 5.72326 16.1895 6.43616C16.3121 6.49365 16.3888 6.6163 16.3888 6.75045V6.94592L18.4776 5.71176L11.5441 1.61069L4.60679 5.71176L6.69949 6.94592ZM7.38939 9.86266V11.5951C7.38939 13.8909 9.24829 15.7498 11.5441 15.7498C13.84 15.7498 15.6989 13.8909 15.6989 11.5951V9.86266H7.38939ZM7.38939 6.97275V9.17276H15.6989V6.97275C14.3114 6.36334 12.9278 6.05671 11.5441 6.05671C10.1605 6.05671 8.77686 6.3595 7.38939 6.97275Z" fill="black" stroke="black" stroke-width="0.5"/>
</svg>

      Beneficiary name 


            </Typography>
            <Typography variant="body2" component="div" className='Tuviksh' >
            Mr. Rahul Aditya
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}  lg={2.5} >
      <Typography variant="body2" component="div" className='Program' >
      <Box component="img" src={Cap} className='cap-setting'  />

Program/Class


            </Typography>
            <Typography variant="body2" component="div" className='Class' >
            Class-II
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}  lg={3.5} >
      <Typography variant="body2" component="div" className='Program' >
      <Box component="img" src={Insitutte} className='cap-setting'  />
      
      Institute/School


            </Typography>
            <Typography variant="body2" component="div" className='Class' >
            Gaudium International School 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}  lg={1.5} >
      <Typography variant="body2" component="div" className='Program' >
      <Box component="img" src={Pay} className='cap-setting'  />
      
      Payable Fee


            </Typography>
            <Typography variant="body2" component="div" className='Class' >
            ₹ 25,000
            </Typography>
          </Grid>
          <Grid item xs={12}  lg={2} >
              <Button variant='contained' color='primary' className='paynowbtn'  >
              <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.16895 6.38912H21.1689M2.98713 0.93457H19.3508C20.3549 0.93457 21.1689 1.7486 21.1689 2.75275V13.6618C21.1689 14.666 20.3549 15.48 19.3508 15.48H2.98713C1.98297 15.48 1.16895 14.666 1.16895 13.6618V2.75275C1.16895 1.7486 1.98297 0.93457 2.98713 0.93457Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

              Pay now
              </Button>
          </Grid>
          </Grid>
</div> */}
        {/* card section ended */}
      </Box>
      )}
      
      </Mainpagestyle>
      {/* {console.log("Row", rows)} */}
      <Preveiouspayment userData={payments}/>
      {/* <Educationcard /> */}
    </>
  );
}
