import React from 'react'
import back from "./Assets/images/arrow-left-circle.png"
import {useNavigate,useLocation} from 'react-router-dom'


export default function Consent() {
  const navigate=useNavigate();

  return (
    <div style={{padding:'2rem 5rem 0rem 5rem'}}>
    <img src={back} onClick={() => navigate(-1)} style={{cursor:'pointer', height: "22px", width: "22px" }}/> <span
                style={{
                  fontFamily: "Inter-Medium",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: 0,
                  marginTop:'10px'
                }}
              >
                Back
              </span>
      <h4 class="mt-5 text-center">Consent Terms & Conditions</h4>
<p class="heading mt-5">By visiting our website/mobile application, you have consented to our terms of use and privacy policy.</p>
<p class="heading mt-5"> You are providing your consent to share your personal information which may include your name, address, telephone number, photograph along with supporting KYC (Know Your Customer) documents, bank account details, financial statements and other such personal information with us and Credit Bureaus, our lending partners (including NBFCs, Banks etc.) as well as outsourced agencies of the lenders, for the purpose of obtaining financial assistance.</p>
<p class="heading mt-5">Your personal information may be stored by us as per the Applicable Laws and as laid down in our Privacy Policy. You may read our privacy policy at https://feemonk.com/privacy . You hereby consent to provide one time access of camera, microphone, location and other such features on your mobile phone for the purpose of on-boarding / KYC requirement.</p>
<p class="heading mt-5">Your personal information may be shared with Statutory or Regulatory Bodies constituted under any Statute, Act or Regulation for the time being in force or under any order, judgement, decree of the Court, Tribunal or Judicial Authority.</p>
<p class="heading mt-5">You can write to us hello@feemonk.com for giving or denying your consent for use of specific data, restrict disclosure to third parties, data retention, or revoke consent already granted to collect personal data or making our mobile application delete or forget the data.</p></div>
  )
}
