import React,{useState} from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Hero from "./Hero";

import Footer from "../Home/Footer";
import { jwtDecode } from "jwt-decode";
export default function Index() {

  const [rows, setRows] = useState([]);
  const [payments, setPayments] = useState([]);
  const [updatedRow, setUpdatedRow] = useState([]);
  const token = sessionStorage.getItem("token")? sessionStorage.getItem("token"):window?.location?.href?.split('?')[1]?.split('&')[0]?.split('=')[1]
  console.log(token,"token")
  const decode = jwtDecode(token) 
  const [amount,setAmount] = useState(0)
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  React.useEffect(() => {


    axios.get(`${process.env.REACT_APP_INSTITUTE_URL}/Orders/student?mobile=${decode?.mobile}`)
      .then((res) => {
        console.log("Response", res);

        if (res.status === 200) {
          setRows(res?.data?.res);
          const items = res?.data?.res || [];
          let totalDueAmount = 0;
          let hasPDM = false;
          let hasSAM = false;
          let hasMAT = false;
          let hasBAF = false;
          let hasBAT = false;
          items.forEach(item => {
            totalDueAmount += item.due_amount || 0;
            if (item.course.includes('PDM')) {
              hasPDM = true;  
            }else if(item.course.includes('SAM')){
              hasSAM = true;
            }else if(item.course.includes('MAT')){
              hasMAT = true;
            }else if(item.course.includes('BAF')){
              hasBAF = true;
            }else{
              hasBAT = true;
            }
          });
          let discountedAmount = 0;
          let totalAmount = 0;
        
          if (hasPDM) {
            discountedAmount = 160000;
            totalAmount = 185000;
          } else if (hasBAF) {
            discountedAmount = 456000;
            totalAmount = 656000;
          } else if (hasBAT) {
            discountedAmount = 300000;
            totalAmount = 402000;
          } else if (hasMAT) {
            discountedAmount = 288000;
            totalAmount = 338000;
          } else if (hasSAM) {
            discountedAmount = 240000;
            totalAmount = 300000;
        }         
          //  const discountedAmount = totalDueAmount - discount;
          const foo = [];
          // setRows(res?.data?.res)
          setTotalAmount(totalAmount)
          setAmount(totalDueAmount)
          setDiscountedAmount(discountedAmount);
          // res?.data?.res.forEach((element) => {
             

          //   if(element.status==="success")
          //   {
          //       const txn = {
          //     txnid: element.txnId,
          //     orderId:element.orderId,
          //     amount: parseFloat(element.due_amount).toFixed(1),
          //     email: element.email,
          //     phone: element.phone,
          //     studentId: element.studentId,
          //     paymentId: element._id,
          //   };

          //   console.log("Success TXN", txn);

          //   axios
          //     .post(`${process.env.REACT_APP_INSTITUTE_URL}/easebuzz/transaction/details`, txn)
          //     .then((res) => {
          //       console.log(parseInt(res?.data?.res?.msg.amount),"details")
          //         const exFoo = {
          //         txnid: res?.data?.res?.msg.txnid,
          //         id: element._id,
          //         paid_date:element.paid_date,
          //         firstname:res?.data?.res?.msg.firstname,
          //         addedOn:res?.data?.res?.msg.addedon,
          //         mode:res?.data?.res?.msg.mode,
          //         bankName:res?.data?.res?.msg.bank_name,
          //         branch:res?.data?.res?.msg.bank_name,
          //         payment_name: res?.data?.res?.msg.productinfo,
          //         dueDate: element.due_date,
          //         status: element.status,
          //         amountDue: parseInt(res?.data?.res?.msg.amount),
          //         phone: element.phone,
          //         studentId: element.studentId,
          //       };

          //   setRows(old=>[...old,exFoo])
          //     })
          //     .catch((e) => {
          //       console.log("Error", e);
          //     });

          
          //   }
          // else
          // {
          //   const txn = {
          //     txnid: element.txnId,
          //     orderId:element.orderId,
          //     amount: parseFloat(element.due_amount).toFixed(1),
          //     email: element.email,
          //     phone: element.phone,
          //     studentId: element.studentId,
          //     paymentId: element._id,
          //   };

          //    console.log("Pending TXN", txn);


          //   axios
          //     .post(`${process.env.REACT_APP_INSTITUTE_URL}/easebuzz/transaction/status`, txn)
          //     .then((res) => {
          //       console.log(res,"status")
          //     })
          //     .catch((e) => {
          //       console.log("Error", e);
          //     });

          //   const exFoo = {
          //     txnid: element.txnId,
          //     id: element._id,
          //     paid_date:element.paid_date,
          //     payment_name: element.paymentName,
          //     dueDate: element.dueDate,
          //     status: element.status,
          //     amountDue: element.due_amount,
          //     phone: element.phone,
          //     studentId: element.studentId,
          //   };

          //   setRows(old=>[...old,exFoo])
          // }
            
          // });

          // setUpdatedRow(foo);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    axios.get(`${process.env.REACT_APP_INSTITUTE_URL}/payments/customer?mobile=${decode?.mobile}`)
      .then((res) => {
        console.log("Response", res);

        if (res.status === 200) {
          // setRows(res?.data?.res);

          const foo = [];
          setPayments(res?.data?.res)
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, []);

  console.log("rows",rows)
  return (
    <div className="home-bg ">
      <Navbar />
      <Hero sendUpdatedRows={updatedRow} payments={payments} sendRows={rows} amount={amount} discountedAmount={discountedAmount} totalAmount={totalAmount}/>
      <Footer />
    </div>
  );
}
