import React from "react";

function FeemonkText() {
  return (
    <span
      style={{
        display: "inline",
      }}
    >
      <p
        style={{
          display: "inline",
          color: "#D32028",
          fontWeight: "bold",
          fontSize:'1em'
        }}
      >
        Fee
      </p>
      <p
        style={{
          display: "inline",
          color: "#000000",
          fontWeight: "bold",
           fontSize:'1em'
        }}
      >
        Monk
      </p>
    </span>
  );
}

export default FeemonkText;
