import React,{useState} from 'react';
import Group_1 from '../../Assets/images/Group_1.png'
import feepaylogo from '../../Assets/images/feepay.png'
import whatsapp from "../../Assets/images/whatsapp.png"
import './index.css'
import { userActions } from '../../actions/user.actions';
import {useDispatch,useSelector} from 'react-redux';
import { Link,useNavigate } from "react-router-dom";
import Captcha from "react-numeric-captcha";

export default function Index({open,setOpen,open2,setOpen2}) {

  const [mobile,setMobile]=useState('')
  const [otp,setOtp]=useState('')
  const [mobileError,setMobileError]=useState('')
  const [otpSent,setOtpSent]=useState(false)
  const dispatch=useDispatch();
  const sendOtpState=useSelector((state)=>state)
  const [mobileNumber, setMobileNumber] = React.useState("");
  const navigate=useNavigate();
  const confirmOtpState=useSelector((state)=>state);
  const [timer,setTimer]=useState(0)


  React.useEffect(() => {
  
if(sessionStorage.getItem("token")){
  navigate('/mainpage')
}else{
  navigate("/login")
}
        // sessionStorage.setItem('token',confirmOtpState?.submitotp?.submitOtp?.data?.data)
        // sessionStorage.setItem('userId', confirmOtpState?.submitotp?.submitOtp?.data?.user?._id)
        // sessionStorage.setItem('applicantId', confirmOtpState?.submitotp?.submitOtp?.data?.user?.applicantId)
        
  
    let timerInterval= setInterval(() => {
      
      if(timer>0)
      setTimer(old=>old-1)
    if(timer===0)
      clearInterval(timerInterval)
      
    }, 1000);

  return()=>{
    clearInterval(timerInterval)
  }
      
  }, [timer,sessionStorage.getItem("token")])

  const handleResendOtp=(event)=>{
    setTimer(45)
    event.preventDefault()

    if(mobileNumber?.length===10)
    { 
      const urlGetOtp='/login/send-otp'
      sessionStorage.setItem("mob",mobileNumber)
       const payLoad = {
        mobile : mobileNumber
      }
      dispatch(userActions.login(urlGetOtp,payLoad),[])
      setOtpSent(true)
     
    }
    else
    alert("Invalid Mobile Number")

    
    // loggedIn(url,options)
  

    
  
}

  const  submitForm = async event => {
    event.preventDefault()

        if(mobileNumber?.length===10)
        { 
          const urlGetOtp='/login/send-otp'
          sessionStorage.setItem("mob",mobileNumber)
           const payLoad = {
            mobile : mobileNumber
          }
          dispatch(userActions.login(urlGetOtp,payLoad),[])
          setOtpSent(true)
         
        }
        else
        alert("Invalid Mobile Number")
    
        
        // loggedIn(url,options)
      
  }
  const [enteredOtp,setEnteredOtp]=React.useState(null)
  const  submitOtp = async event => {
    event.preventDefault()
     
  
    if(enteredOtp?.length===4){

      const urlSubmitOtp="/login/verify-otp" 
     const payLoad = {
        mobile : sessionStorage.getItem("mob"),
        otp : enteredOtp
      }

      dispatch(userActions.submitOtp(urlSubmitOtp,payLoad))
      // axiosInstance.post( "/user/login", payLoad)
      // .then((res)=>{
      //   if(res.status === 200){
      //     console.log(res)
      //     handleClose()
      //     navigate('/mainpage', {state : {
      //       user : res.data.user
      //     }
      //     })
      //     // setOtpReceived(true)
      //   }
        
      // })
      // .catch(e => {
      //   alert("Login Failed Check OTP!!!");
      // })
  }
  else
  {
    alert("Enter 4 digit OTP")
  }
        
    
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen(false);
    setOpen2(true);
  };
  const  onChangeMobile = event => {
    const mob = event.target.value;
    if(mob.length===10)
    {
      setMobile(mob)
      setMobileError(mob.length!==10 ? "Invalid Mobile" : null)
    }
    else
    {
      setMobile(mob)
      setMobileError(mob.length!==10 ? "Invalid Mobile" : null)
    }
   
  };

  const  onChangeOtp = event => {
    setOtp(event.target.value)
    
  }
  const [captchaSuccess,setCaptchaSuccess] = useState(false)


  return (
    <>
         <div className="login-form-container">
        <div className="login-background">
          {/* <div className="login-background-2"> */}
          <img src={Group_1} className="login-image" alt="login" />
          <img
            src={feepaylogo}
            className="feemonk-image"
            alt="website logo"
          />
         
          {/* <p className="caption">Simplified education fee payments & fiscal management <br/> */}
          {/* <button
            className="applybtn1"
            onClick={() => {
              navigate(
                "/eyJtb2JpbGVOdW1iZXIiOiI4NzYzMDM5MzgwIiwiY291cnNlIjoiRGF0YSBTY2llbmNlIiwiZmVlcyI6MTAwMDAsInN0dWRlbnROYW1lIjoiU3dhcGFuZGVlcCIsImluc3RpdHV0ZU5hbWUiOiJVbmFjYWRlbXkifQ=="
              );
            }}
          >
            Apply Now
          </button> */}
          {/* </p> */}
        {/* </div> */}
        </div>
        <div className="login-background-mobile">
          {/* <div className="login-background-2"> */}
         
          <img
            src={feepaylogo}
            className="feemonk-image-mobile"
            alt="website logo"
          />
          {/* <button
            className="applybtn1-mobile"
            onClick={() => {
              navigate(
                "/eyJtb2JpbGVOdW1iZXIiOiI4NzYzMDM5MzgwIiwiY291cnNlIjoiRGF0YSBTY2llbmNlIiwiZmVlcyI6MTAwMDAsInN0dWRlbnROYW1lIjoiU3dhcGFuZGVlcCIsImluc3RpdHV0ZU5hbWUiOiJVbmFjYWRlbXkifQ=="
              );
            }}
          >
            Apply Now
          </button> */}
        {/* </div> */}
        </div>
        <form className="form-container" >

          <p className='started'>To get started</p>
          <h3  className='login'>Log In</h3>
         
          <div className="input-container">
            <p className="input-p" htmlFor="email" style={{fontFamily:'Inter-Medium',color:'#667085',marginLeft:'20%',lineHeight:'0.1em'}}>
          Enter Registered Mobile
        </p>
        <input
          type="text"
          id="outlined-basic"
          placeholder='Enter 10 digit Mobile Number'
          className="email-input-filed"
          value={mobileNumber}
          onChange={(e)=>setMobileNumber(e.target.value)}
        />
        {otpSent?<small className='otp-resend' onClick={timer ? () => null : (event) => handleResendOtp(event)}>
          {timer
            ? `Didn't receive OTP? Wait for ${timer} and hit `
            : ""}
          <strong style={{
            fontSize: '14px',
            color: timer ? 'grey' : 'blue',
            cursor: timer ? "not-allowed" : "pointer"
          }}>
            <u>Resend otp</u>
          </strong>
        </small>
        :null}
        {mobileError && <div className='error'>{mobileError}</div>}
        
     </div>
     {!otpSent ? <div style={{marginLeft:'22%',marginTop:'5%'}}>
      <form>
          <Captcha
            onChange={(status) => setCaptchaSuccess(status)}
          />
          
        </form>
      </div>:null}
        { otpSent?<div className="input-container">

        <p className="input-p" htmlFor="password"  style={{fontFamily:'Inter-Medium',color:'#667085',marginLeft:'20%',lineHeight:'0.1em',marginTop:'5%'}}>
          OTP
        </p>
        <input
          type="text"
          id="password"
          className="password-input-filed"
          value={enteredOtp}
          onChange={(e)=>setEnteredOtp(e.target.value)}
        />
          {/* {otpError && <div style={{ color: 'red' }}>{otpError}</div>} */}
        
      </div> :null}
          <div className="buttons-container1">
              {/* onClick={()=>navigate('/forgotPassword')} */}
           {/* <p  style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}}><span style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}} onClick={()=>navigate('/forgotPassword')}>Forgot Password ?</span></p> */}
          {!otpSent?<button  type="submit" className="login-button" onClick={(e)=>submitForm(e)} disabled={!captchaSuccess} style={{backgroundColor:!captchaSuccess?"gray":"#2c6ce3"}}>
            Get OTP
          </button>:<button  type="submit" className="login-button" onClick={(e)=>submitOtp(e)}>
            Submit
          </button>}
          {/* <div style ={{display:'flex', flexDirection : 'row', alignContent: 'center'}}><p style={{marginTop : '20px' , marginRight : '10px'}}>Don't have an account? </p><SignUpForm /> </div> */}
         </div>
         {/* <div className='or'>(or)</div>
        <div className="buttons-container1">
          <button  type="submit" className="login-button1" onClick={(event)=>event.preventDefault()}><img src={whatsapp} style={{height:'28px',width:'28px',marginRight:'10px'}}/>Login with WhatsApp</button>
        </div> */}
        </form>  
        <form className="form-container-mobile" >

            <p className='started-mobile'>To get started</p>
            <p  className='login-mobile'>Log In</p>

            <div className="input-container-mobile">
              <label className="input-label-mobile" htmlFor="email">
           <span style={{fontFamily:'Inter-Medium',color:'#667085'}}>Enter Registered Mobile</span> 
            </label>
            <input
            type="text"
            id="outlined-basic"
            placeholder='Enter 10 digit Mobile Number'
            className="email-input-filed-mobile"
            value={mobileNumber}
            onChange={(e)=>setMobileNumber(e.target.value)}
            /><br/>
            {otpSent? <small className='otp-resend-mobile' onClick={!timer?()=>console.log("wait few seconds"):()=>handleResendOtp()}>Didn't receive OTP ? Wait for 45s and hit <strong style={{fontSize:'14px', color:!timer?'grey':'blue',cursor:timer?"pointer":""}}><u>Resend</u></strong></small>:null}
            {mobileError && <div className='error-mobile'>{mobileError}</div>}

            </div>
            {!otpSent ? <div style={{marginLeft:'10%',marginTop:'5%'}}>
      <form>
          <Captcha
            onChange={(status) => setCaptchaSuccess(status)}
          />
          
        </form>
      </div>:null}
            { otpSent?<div className="input-container-mobile">

            <label className="input-label-mobile" htmlFor="password">
             <span style={{fontFamily:'Inter-Medium',color:'#667085'}}>OTP</span>
            </label>
            <input
            type="text"
            id="password"
            placeholder='Enter Otp'
            className="password-input-filed-mobile"
            value={enteredOtp}
            onChange={(e)=>setEnteredOtp(e.target.value)}
            />
            {/* {otpError && <div style={{ color: 'red' }}>{otpError}</div>} */}

            </div> :null}
            <div className="buttons-container1-mobile">
                {/* onClick={()=>navigate('/forgotPassword')} */}
            {/* <p  style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}}><span style={{color:'#D32028',textAlign:'right', cursor : 'pointer'}} onClick={()=>navigate('/forgotPassword')}>Forgot Password ?</span></p> */}
            {!otpSent?<button  type="submit" className="login-button-mobile" onClick={(e)=>submitForm(e)} disabled={!captchaSuccess} style={{backgroundColor:!captchaSuccess?"gray":"#2c6ce3"}}>
              Get OTP
            </button>:<button  type="submit" className="login-button-mobile" onClick={(e)=>submitOtp(e)}>
              Submit
            </button>}
            {/* <div style ={{display:'flex', flexDirection : 'row', alignContent: 'center'}}><p style={{marginTop : '20px' , marginRight : '10px'}}>Don't have an account? </p><SignUpForm /> </div> */}
            </div>
            {/* <div className='or-mobile'>(or)</div>
            <div className="buttons-container1-mobile">
            <button  type="submit" className="login-button1-mobile" onClick={(event)=>event.preventDefault()}><img src={whatsapp} style={{height:'28px',width:'28px',marginRight:'10px'}}/>Login with WhatsApp</button>
            </div> */}
            
            </form>    
      </div>
      <div>
     
      </div>
      </>
  )
}
