import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {  Grid, Box, Typography, Divider, Paper } from "@mui/material";
import { Mainpagestyle } from "./Style";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Educationcard from "../Home/Educationcard";
import "./Applications.css"
import rejected from "../../Assets/images/rejected.png";
import back from "../../Assets/images/arrow-left-circle.png"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Basicdetailsclicked from "../../Assets/images/Basicdetailsclicked.png"
import basicdetails from "../../Assets/images/basicdetails.png"
import kycclicked from "../../Assets/images/kycclicked.png"
import kyc from "../../Assets/images/kyc.png"
import incomeclicked from "../../Assets/images/incomeclicked.png"
import income from "../../Assets/images/income.png"
import loan from "../../Assets/images/loan.png"
import loandetailsclicked from "../../Assets/images/loandetailsclicked.png"
import disbursal from "../../Assets/images/dibursal.png"
import disbursalclicked from "../../Assets/images/disbursalclicked.png"
import sanction from "../../Assets/images/sanction1.png"
import sanctionclicked from "../../Assets/images/sanctionclicked.png"
import tickicon from "../../Assets/images/tickicon.png"
import InputText from "../../pay-later/components/atoms/InputText";
import FeemonkText from "../../pay-later/components/atoms/FeemonkText";
import Button from "../../pay-later/components/atoms/Button";
import styles from "../../pay-later/pages/WorkDetails/index.module.css"
import styles1 from "../../pay-later/pages/Sanction/index.module.css"
import Dropzone from "react-dropzone";
import SanctionLetter from "../../pay-later/pages/SanctionLetter";
import download from "../../Assets/images/download.png"
import Label from "../../pay-later/components/atoms/Label";
import check_circle from "../../Assets/images/check-circle.svg"
import Mandate from "./Mandates";
import rupee from "../../Assets/images/rupee.png";
import calender from "../../Assets/images/calender.png";
import utr from "../../Assets/images/utr.png";
import { jwtDecode } from "jwt-decode";
import feemonk from "../../Assets/images/FeeMonk_HighRes_Logo.png"
import styless from "./styles.module.css"
import * as moment from 'moment'
import styless1 from "../../pay-later/pages/Sanction/index.module.css"


function Applications() {
  const user = sessionStorage.getItem("token");
  const [isImageClicked1, setIsImageClicked1] = useState(false);
  const [isImageClicked2, setIsImageClicked2] = useState(false);
  const [isImageClicked3, setIsImageClicked3] = useState(false);
  const [isImageClicked4, setIsImageClicked4] = useState(false);
  const [isImageClicked5, setIsImageClicked5] = useState(false);
  const [open, setOpen] = useState();
  const [showCards1, setShowCards1] = useState(false);
  const [showCards2, setShowCards2] = useState(false);
  const [showCards3, setShowCards3] = useState(false);
  const [showCards4, setShowCards4] = useState(false);
  const [showCards5, setShowCards5] = useState(false);
  const [openVerify,SetOpenVerify] = useState(true)
  const [openAddress,SetOpenAddress] = useState(false)
  const [isMandateVisible, setIsMandateVisible] = useState(false);
 // const [authToken, setAuthToken] = useState("");
  const [panNumber, setPanNumber] = useState('');
  const [dob, setDob] = useState('');
  const [salary, setSalary] = useState('');
  const [employerName, setEmployerName] = useState('');
  const [typeOfBusiness, setProfession] = useState('');
  const [incomePerMonth, setMonthlyIncome] = useState('');
  const [currentAddress, setCurrentAddress] = useState("yes");
  const [employmentType, setEmploymentType] = useState(
    sessionStorage.getItem("employmentType")
  );

  const [address,setAddress] = useState("")
  const [city,setCity] = useState("")
  const [state,setState] = useState("")
  const [pincode,setPincode] = useState("")
  const [fibUrl,setFibUrl] = useState("")

  const handleKYCClick = (item) => {
    setIsMandateVisible(!isMandateVisible);

    const url = `${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`
    const data = {
      mobile:item?.obj?.data?.mobile,
      userId:item?.obj?.data?.userId,
      applicationId:item?.obj?.applicationProfile?.applicationId
      
    }
    axios.post(url,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{console.log("token",res?.data?.data);sessionStorage.setItem("authToken",res?.data?.data)})
    .catch((error)=>alert(error?.response?.data?.message))
  };

  const handleFibKYCClick = (item) => {
    const url = `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/fibe-profile`
    const data = {
      applicationId:item?.obj?.data?.applicationId,
      fundCode:item?.obj?.applicationProfile?.fundSourceCode
      
    }
    axios.post(url,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      console.log("fib token",res?.data?.data?.bitlyUrl);
      setFibUrl(res?.data?.data?.bitlyUrl);
      if (res?.data?.data?.bitlyUrl) {
        window.open(res?.data?.data?.bitlyUrl, '_blank');
      } else {
        console.error('Invalid URL for KYC');
      }
    })
    .catch((error)=>alert(error?.response?.data?.message))
  }

  const handleToggle=(id,item)=>{
    sessionStorage.setItem("employmentType",item?.obj?.data?.employmentType)
    toggle(id)
    if (id === '1') {
      setShowCards1(true);
      setIsImageClicked1(true);
      
    } else {
      setShowCards1(false);
      setIsImageClicked1(false);
    }
    if (id === '2') {
      setShowCards2(true);
      setIsImageClicked2(true);
      
    } else {
      setShowCards2(false);
      setIsImageClicked2(false);
    }
    if (id === '3') {
      setShowCards3(true);
      setIsImageClicked3(true)
      
    } else {
      setShowCards3(false);
      setIsImageClicked3(false)
    }
    if (id === '4') {
      setShowCards4(true);
      setIsImageClicked4(true)
      
    } else {
      setShowCards4(false);
      setIsImageClicked4(false)
    }
    if (id === '5') {
      setShowCards5(true);
      setIsImageClicked5(true)
      
    } else {
      setShowCards5(false);
      setIsImageClicked5(false)
    }
    

}
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const navigate = useNavigate();

  const [applicationsDetails, setApplicationDetails] = React.useState([]);
  const dispatch = useDispatch();
  const applicationState = useSelector(
    (state) => state?.GETAPPLICATIONS?.getApplicationsData
  );
    const token = sessionStorage.getItem("token") || window.location.href.split('?')[1].split('=')[1]

  const decode = jwtDecode(token)

  const urlApplications = `/applications/getApplications/${decode?.mobile}`;
  React.useEffect(() => {
    if (applicationState) {
      console.log("appp",applicationState?.data?.res)
      setApplicationDetails(applicationState?.data?.res);
    } else {
      dispatch(userActions.getApplications(urlApplications, token));
    }
  }, [applicationState]);

  const getPanPro = () => {
    console.log("clicked")
    SetOpenVerify(false)
    SetOpenAddress(true)
  };

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);

  const [mandateModal, setMandateModal] = useState(false);
  const [sanctionLetter, setSanctionLetter] = useState(null);
  const [agreement, setAgreement] = useState(null);


  const toggle2 = (item) =>{setSanctionLetter(item?.obj?.applicationProfile?.sanctionLetter);setModal1(!modal1)} ;
  const toggleAgreement = (item) =>{setAgreement(item?.obj?.applicationProfile?.agreementUrl);setAgreementModal(!agreementModal)} ;


  const toggleMandate = ()=>setMandateModal(!mandateModal)

  const [mandateModals, setMandateModals] = useState(false);
  const toggleMandates = ()=>setMandateModals(!mandateModals)

  const acceptSanctionLetter=()=>{
    const userId = sessionStorage.getItem("userId")
    const appId = sessionStorage.getItem("appId")
    const url = `/integrations/update-applicant-consent?userId=${userId}&applicationId=${appId}&status=true`;
    
    axios.get(process.env.REACT_APP_DASHBOARD_URL + url)
    .then((res)=>{
      console.log(res)
    })
    
  }
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 

  const updateWorkDetails =()=>{
    setShowCards2(false)
    setIsImageClicked2(false)

    setShowCards3(true)
    setIsImageClicked3(true)
    // const url = `/users/employment-details/update`;
    // const data = {
    //   employmentType:employmentType,
    //   employerName: employerName || document.getElementById("emplName")?.value,
    //   salary: salary || document.getElementById("salary")?.value,
    //   incomePerMonth: incomePerMonth,
    //   typeOfBusiness: typeOfBusiness,
    // };
   
    // axios.post(process.env.REACT_APP_DASHBOARD_URL + url,data,{
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //     'Content-type': 'application/json',
    //   },
    // })
    // .then((res)=>{
    //   console.log(res,"empployement data")
    // })
  }

  const updateProfileDetails = ()=>{
    setShowCards1(false)
    setIsImageClicked1(false)

    setShowCards2(true)
    setIsImageClicked2(true)
    // const url = `/users/profile-details/update`;
    // const data = {
    //   userId:sessionStorage.getItem("userId"),
    //   currentAddress: address || document.getElementById("address")?.value,
    //   currentCity: city || document.getElementById("city")?.value,
    //   currentState: state || document.getElementById("state")?.value,
    //   currentPincode: pincode || document.getElementById("pincode")?.value,
    // };
   
    // axios.post(process.env.REACT_APP_DASHBOARD_URL + url,data,{
    //   headers: {
    //     Authorization: `Bearer ${user}`,
    //     'Content-type': 'application/json',
    //   },
    // })
    // .then((res)=>{
    //   console.log(res,"empployement data")
    // })
  }

  const handleDisbursementDetailsClick =()=>{
  setIsImageClicked5(true);
  setShowCards5(true);
  setIsImageClicked3(false);
  setShowCards3(false);
  setIsImageClicked2(false);
  setShowCards2(false);
  setIsImageClicked1(false);
  setShowCards1(false);
  setIsImageClicked4(false);
  setShowCards4(false);
  }

  const handleKycDetails =()=>{
    setIsImageClicked5(false);
    setShowCards5(false);
    setIsImageClicked3(true);
    setShowCards3(true);
    setIsImageClicked2(false);
    setShowCards2(false);
    setIsImageClicked1(false);
    setShowCards1(false);
    setIsImageClicked4(false);
    setShowCards4(false);
    }

  return (
    <>
      <Mainpagestyle>
        <Box my={5}>
          <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment">
              <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',whiteSpace:'nowrap'}}>Active Applications</span> 
                <svg
                  width="5"
                  height="23"
                  viewBox="0 0 5 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.461426"
                    y="0.772095"
                    width="4.17139"
                    height="21.7188"
                    rx="2.08569"
                    fill="#D32028"
                  />
                </svg>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={9}>
              <Box mt={2} ml={3}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
          
           {applicationsDetails?.map((item,index)=>{
            
            return(
              <>
               <Modal isOpen={agreementModal}  size="xl"  style={{height:'100%'}}>
                <ModalHeader toggle={toggleAgreement}>Agreement</ModalHeader>
                <ModalBody >
                <iframe
                    src={agreement}
                    width="100%"
                    height="1000vh"
                    style={{ border: 'none' }}
                  ></iframe>
                </ModalBody>
               
              </Modal>
               <Modal isOpen={modal1}  size="xl"  style={{height:'100%'}}>
                <ModalHeader toggle={toggle2}>Sanction Letter</ModalHeader>
                <ModalBody >
                <iframe
                    src={sanctionLetter}
                    width="100%"
                    height="1000vh"
                    style={{ border: 'none' }}
                  ></iframe>
                </ModalBody>
                {/* {item?.obj?.applicationProfile?.applicantConsent !== true  ? (
                  <div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>

                  <Button
                        onPress={() => {
                          acceptSanctionLetter();
                        }}
                      text={"Accept for loan"}
                    />
                  </div>
                ):null} */}
              
              
              </Modal>
              {item.obj && Object.keys(item.obj).length !== 0 && item?.obj?.data?.location !== null &&(
                <>
                {item?.obj?.applicationProfile?.status !== "Dropped" && item?.obj?.applicationProfile?.status !== "Rejected" && item?.obj?.applicationProfile?.status !== "Disbursed" ?
                <div className="loan-payment-crd1">
               
                <Accordion style={{backgroundColor:'#FFF8F4',width:'100%',overflow:'auto',padding:'0.2rem'}} onClick = {()=>{sessionStorage.setItem("appId",item?.obj?.data?.applicationId);sessionStorage.setItem("userId",item?.obj?.data?.userId);sessionStorage.setItem("mobile",item?.obj?.data?.mobile)}} expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                     <Grid container spacing={3} style={{alignItems:'center'}}>
                      <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Typography variant="body2" component="div" className="Tuviksh1">
                        {item?.obj?.data?.applicationId}
                        </Typography>
                      </Grid>
                    
                      <Grid item xs={12} sm={6} md={6} lg={4} >
                        
                        <Typography variant="body2" component="div" className="Program">
                        Start Date : {item.appliedOn ? moment(item.appliedOn).format('DD MMM YY') : "-" }{" "}
                        </Typography>
  
                      
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={5}>
                      <Typography variant="body2" component="div" className="Program"
                      >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{
                        borderRight: (item?.obj?.applicationProfile?.status === 'Disbursed' || item?.obj?.applicationProfile?.status === 'In Sanction') ? '2px solid #737373' : '0px',
                      }}><span style={{fontFamily: 'Outfit-Medium'}}>Status:</span>
                          <span style={{
                            color: item?.obj?.applicationProfile?.status === 'Disbursed' ? '#12B76A' : '#F8A31F' ,
                            fontFamily: 'Outfit-Medium',
                            backgroundColor: item?.obj?.applicationProfile?.status === 'Disbursed' ? '#ECFDF3' : '#FFF6E8',
                            borderRadius: item?.obj?.applicationProfile?.status === 'In Process' ? '16px' : '0px',
                            padding: '0.2rem'
                          }}>
                            {item?.obj?.applicationProfile?.status }
                          </span>
                        </div>
                        <div style={{ marginLeft: '0.2rem' }}>
                          {item?.obj?.applicationProfile?.status === 'In Process' ? null : 
                          (
                            item?.obj?.applicationProfile?.status === 'Disbursed'  ? (
                              <div  style={{
                                fontFamily: 'Outfit-Medium',
                                fontSize: '1em',
                                color: '#737373',
                                whiteSpace: 'nowrap',
                                textDecoration: 'underline',
                              }} onClick={() => handleDisbursementDetailsClick()}>
                                Disbursed details
                                {/* Add the necessary code to display disbursement details */}
                              </div>
                            ) : item?.obj?.applicationProfile?.status === 'In Sanction' ? (
                              <div  style={{
                                fontFamily: 'Outfit-Medium',
                                fontSize: '1em',
                                color: '#737373',
                                whiteSpace: 'nowrap',
                                textDecoration: 'underline',
                              }} onClick={() => handleKycDetails()}>
                                Click to complete KYC
                                {/* Add the necessary code to display disbursement details */}
                              </div>
                            ) :item?.obj?.applicationProfile?.status === 'In Submission' ? (
                              <></>
                            ) : (
                            //  <><div  style={{
                            //   fontFamily: 'Outfit-Medium',
                            //   fontSize: '1em',
                            //   color: '#737373',
                            //   whiteSpace: 'nowrap',
                            //   textDecoration: 'underline',
                            // }} onClick={(item) => toggle2(item)}>
                            //  SanctionLetter <img src={download} style={{height:'17px'}}/> 
                            //   {/* Add the necessary code to display disbursement details */}
                            // </div></>
                            null
                            )
                          )}
                        </div>
  
                       
                      </div>
                        </Typography>
                       
                      </Grid>
                      {/* <Grid item xs={12} lg={2}>
                        <div style={{display:'flex',marginTop:'5px',}}>
                          <img style={{marginLeft:'8em'}} src={enterIcon}/>
                      </div>
                      </Grid> */}
                    </Grid>
                </AccordionSummary>
  
               
                
                <AccordionDetails style={{overflow:'auto'}}>
                <div style={{listStyleType:"none",display:"flex",flexDirection:"row",width:'1050px',justifyContent:'space-between'}}>
                <div style={{display:"flex",flexDirection:'row'}}>
                  <div 
                  style={{fontWeight:'500',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:"space-between",alignItems:"center",textAlign:'center'}}
                  onClick={()=>handleToggle('1',item)}>
                      
                  <img  style={{height:'25px',width:'25px'}} src={isImageClicked1 ? Basicdetailsclicked :basicdetails} alt="basicdetails"/>
                  {item?.obj?.data?.panId === null || item?.obj?.data?.dateOfBirth === null || item?.obj?.data?.currentAddress === null || item?.obj?.data?.currentCity === null || item?.obj?.data?.currentState === null || item?.obj?.data?.currentPincode === null
                 || item?.obj?.data?.panId === undefined  || item?.obj?.data?.dateOfBirth === undefined  || item?.obj?.data?.currentAddress === undefined  || item?.obj?.data?.currentCity === undefined  || item?.obj?.data?.currentState === undefined  || item?.obj?.data?.currentPincode === undefined 
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}
  
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',color: isImageClicked1 ? '#D32027' : 'black',marginTop:'5px'}}>Basic Details</p> 
                
                  </div>
                  {!item?.obj?.data ||
                  ((item?.obj?.data?.employmentType === "salaried" && (!item?.obj?.data?.employerName || !item?.obj?.data?.salary)) ||
                  (item?.obj?.data?.employmentType === "self employed" && (!item?.obj?.data?.profession || !item?.obj?.data?.income)))
                  ? (
                    <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                  ) : (
                    <div style={{ marginTop: "45px" }}>
                      <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                    </div>
                  )}
  
  
                  {/* <div style={{ borderBottom: "2px dashed #F8A31F",borderSpacing:"40px",alignSelf: 'center', height: '2px', width: '120px',marginRight:'20px'}}></div>
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div> */}
  
                </div >
  
                <div style={{display:"flex",flexDirection:'row'}}>
                  <div
                  style={{marginRight:"5px",fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                    
                  onClick={()=>handleToggle('2',item)}
                  >
                      <img  style={{height:'25px',width:'25px'}} src={isImageClicked2? incomeclicked :income} alt='income'/>
                      {/* <div style={{marginTop:"5px",borderStyle:"solid",borderColor:"#F8A31F",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center"}}></div> */}
  
                          {!item?.obj?.data ||
                  ((item?.obj?.data?.employmentType === "salaried" && (!item?.obj?.data?.employerName || !item?.obj?.data?.salary)) ||
                  (item?.obj?.data?.employmentType === "self employed" && (!item?.obj?.data?.profession || !item?.obj?.data?.income)))
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}
  
                   <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked2 ? '#D32027' : 'black'}}>Work Details</p>
                  </div>
                  {item?.obj?.applicationProfile?.enachStatus !== 4 || item?.obj?.applicationProfile?.agreementStatus !== 3 || item?.obj?.applicationProfile?.selfieStatus !== 3 || item?.obj?.applicationProfile?.digilockerStatus !== 3 
                  ? (
                    <div style={{ borderBottom: "2px dashed #F8A31F",borderSpacing:"40px",alignSelf: 'center', height: '2px', width: '120px',marginRight:'20px'}}></div>
                    ) : (
                    <div style={{ marginTop: "45px" }}>
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                    </div>
                  )}
  
                </div>
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:"center"}}
                  onClick={()=>handleToggle('3')}
                  >
                    
                    <img style={{height:'25px',width:'25px'}} src={isImageClicked3 ? kycclicked: kyc} alt="kyc"/>
                    {item?.obj?.applicationProfile?.enachStatus !== 4 || item?.obj?.applicationProfile?.agreementStatus !== 3 || item?.obj?.applicationProfile?.selfieStatus !== 3 || item?.obj?.applicationProfile?.digilockerStatus !== 3 
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}   
                    <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked3 ? '#D32027' : 'black'}}>KYC</p>
                  </div>
                </div>
               
                {!item?.obj?.applicationProfile?.agreementUrl || !item?.obj?.applicationProfile?.sanctionLetter ? (
                  <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                ) : (
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                )}
  
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div
                  style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                  onClick={()=>handleToggle('4')}>
                      <img style={{height:'25px',width:'25px'}} src={isImageClicked4 ? disbursalclicked :disbursal} alt="sanction"/>
                      {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
                      {!item?.obj?.applicationProfile?.agreementUrl || !item?.obj?.applicationProfile?.sanctionLetter
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}                     
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked4 ? '#D32027' : 'black'}}>Sanction</p>
                  </div>
                </div>
                {!item?.obj?.applicationProfile?.disbursement ? (
                  <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                ) : (
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                )}
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div
                    style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                    onClick={()=>handleToggle('5')}
                  >
                      <img style={{height:'25px',width:'25px'}} src={isImageClicked5 ? sanctionclicked:sanction} alt="disbursal"/>
                      {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
                      {!item?.obj?.applicationProfile?.disbursement
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}                
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked5 ? '#D32027' : 'black'}}>Disbursment</p> 
                  </div>
                </div>
                </div>
                </AccordionDetails>
                {showCards1 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Basic Details</span> 
                     
                    </AccordionSummary>
                    <AccordionDetails>
                      {openVerify ? (
                        <div style={{
                          padding: "2rem",
                          background: "#FFF7F2",
                          border: "1px solid #F9D8D6",
                          borderRadius: "12px 12px 12px 12px"
                        }}>
      
                        <div >
                          <div className="basic"
                          >
                            <div>
                            <p style={{fontSize:'1em',marginLeft:'0.5em'}}>PAN number</p>
                            <InputText
                              square
                              style={{width:'14em',height:'3em'}}
                              placeholder="PAN Number"
                              value={item?.obj?.data?.panId}
                              onChange={(e) => setPanNumber(e.target.value)}
                            />
                            </div>
                            <div>
                            <p style={{fontSize:'1em',marginLeft:'0.5em'}}>Date Of Birth</p>
                            <InputText
                              square
                              style={{width:'14em',height:'3em'}}
                              placeholder="Date of Birth"
                              value={item?.obj?.data?.dateOfBirth}
                              onChange={(e) => setDob(e.target.value)}
                              type="date"
                            />
                            </div>
                      
                            {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            > */}
              
                            {/* </div> */}
                          </div>
                          <label style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
                            <input
                              type="checkbox"
                              checked
                              name=""
                              id=""
                              style={{
                                marginRight: "0.4rem",
                                height:'1em',width:'1em'
                              }}
                            />
                            <span style={{fontSize:'1em'}}>I consent and authorize <FeemonkText /> to get a background check
                            and a consumer credit report on me</span>
                          </label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
                        <Button
                          onPress={() => {
                           
                              getPanPro();
                            
                          }}
                          text={"Next"}
                         
                        />
                        </div>
                       
                                 </div>
                      ):null}
                       {openAddress ? (
                       <div >
                     <div onClick={() => {SetOpenVerify(true);SetOpenAddress(false)}} style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}}>
                        <p><img style={{height:'22px',width:'22px'}} src={back}/></p>
                        <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',fontWeight:'500',marginLeft:'5px'}}>Back</p>
                      </div>
                       <div>
                         <div
                           style={{
                             padding: "1rem",
                             background: "#FFF7F2",
                             border: "1px solid #F9D8D6",
                             borderRadius: "12px 12px 0px 0px",
                           }}
                         >
                           <p style={{fontSize:'1em', fontWeight: "bold" }}>Address</p>
                         </div>
                         <div
                           style={{
                             display: "flex",
                             flexDirection: "column",
                             // justifyContent: "center",
                             // alignItems: "center",
                             gap: "1rem",
                             border: "1px solid #F9D8D6",
                             background: "#FFFCFA",
                             padding: "1rem",
                             boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                             borderRadius: "0px 0px 12px 12px",
                           }}
                         >
                           <div>
                             <p
                               style={{
                                 color: "#D32028",
                                 fontSize: "1.2em",
                               }}
                             >
                               Note
                             </p>
                             <p style={{fontSize:'1em',}}>
                               The following address details are obtained from your e-kyc
                             </p>
                           </div>
                           <div >
                             <InputText
                               gridArea="door"
                               square
                               placeholder="Door No."
                               id="address"
                               value={item?.obj?.data?.currentAddress}
                               onChange={(event) =>
                                setAddress(event.target.value)
                              }
                             />
                             {/* <InputText
                               gridArea="street"
                               square
                               placeholder="Street / Landmark"
                               value={state.currentAddress}
                             /> */}
                             <div style={{display:'block'}}>
                             <InputText
                               gridArea="city"
                               square
                               placeholder="City"
                               id="city"
                               value ={item?.obj?.data?.currentCity}                            
                               onChange={(event) =>
                                setCity(event.target.value)
                              }
                             />
                             <InputText
                               gridArea="state"
                               square
                               placeholder="State"
                               id="state"
                               value={item?.obj?.data?.currentState}
                               onChange={(event) =>
                                setState(event.target.value)
                              }
                             />
                             <InputText
                               gridArea="pin"
                               square
                               placeholder="Pincode"
                               id="pincode"
                               value={item?.obj?.data?.currentPincode}
                               onChange={(event) =>
                                setPincode(event.target.value)
                              }
                             />
                             
                             </div>
                           </div>
                           <br />
                         </div>
                         <br />
                        
                       </div>
                       <div style={{marginTop:'2em'}}>
                       <Button
                         text={"Next"}
                         onPress={() => {
                          updateProfileDetails();
                        }}
                       />
                       </div>
                      
                     </div>
                      ):null}
                    
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                 {showCards2 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                     <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Work Details</span> 
                    </AccordionSummary>
                    <AccordionDetails>
                    
         <div>
            <div>
              <div
                style={{
                  padding: "1rem",
                  background: "#FFF7F2",
                  border: "1px solid #F9D8D6",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', fontWeight: "bold" }}>Work Details</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid #F9D8D6",
                  background: "#FFFCFA",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <div className="work">
                  <div>
                    <p
                      style={{
                        display:'flex',justifyContent:'center',
                        textAlign: "center",
                        color: "#3D3D3D",
                        fontWeight: "bold",
                        fontSize:'1.2em',
                      }}
                    >
                      Employment details
                    </p>
                    <div
                      className={`${
                        employmentType === "salaried"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("salaried")}
                    >
                      <p style={{fontSize:'1em'}}>Salaried</p>
                    </div>
                    <div
                      className={`${
                        employmentType === "Self employed"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("Self employed")}
                    >
                      <p  style={{fontSize:'1em'}}>Self Employee</p>
                    </div>
                  </div>
                
                  {employmentType === "salaried" ? (
                    <>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Company Name</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="Ex: FeeMonk"
                          id="emplName"
                          value={item?.obj?.data?.employerName}
                          onChange={(e) => setEmployerName(e.target.value)}
                        />
                      </div>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Net Monthly Salary</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="₹"
                          id="salary"
                          value={item?.obj?.data?.salary}
                          onChange={(e) => setSalary(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Profession</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="Profession"
                          value={item?.obj?.data?.typeOfBusiness}
                          onChange={(e) => setProfession(e.target.value)}
                        />
                      </div>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Monthly income</p>
                        <InputText
                        style={{width:"14rem"}}
                          square
                          placeholder="₹"
                          value={item?.obj?.data?.incomePerMonth}
                          onChange={(e) => setMonthlyIncome(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  {/* <Button 
                     onPress={() => {
                      updateWorkDetails();
                    }}
                    text={"Save"}
                    fullWidth={false}
                    secondary
                  /> */}
                </div>
                <br />
              </div>
            </div>
            <div
            style={{marginTop:'2em'}}
            >
              <div
                style={{
                  padding: "1rem",
                  background: "#FFF7F2",
                  border: "1px solid #F9D8D6",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', fontWeight: "bold" }}>Bank Statement</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid #F9D8D6",
                  background: "#FFFCFA",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                  borderRadius: "0px 0px 12px 12px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', }} >
                  Uploading a bank account statement can enhance your chances of
                  availing better loan amount
                </p>
                <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 57 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3.5"
                            y="3"
                            width="50"
                            height="50"
                            rx="25"
                            fill="#F2F4F7"
                          />
                          <rect
                            x="3.5"
                            y="3"
                            width="50"
                            height="50"
                            rx="25"
                            stroke="#F9FAFB"
                            stroke-width="6"
                          />
                          <path
                            d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                            stroke="#475467"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
  
                        <p
                          style={{
                            color: "#d32028",
                            fontSize:'1em'
                          }}
                        >
                          Click to upload
                        </p>
                        <p style={{
                            
                            fontSize:'1em'
                          }}>PDF or ZIP</p>
                      </div>
              </div>
            </div>
            <div style={{marginTop:'2em'}}>
            <Button
              text={"Next"}
              onPress={() => {
                updateWorkDetails();
              }}
            />
            </div>
          </div>
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                 {showCards3 ? (
                  <>
                  <Accordion style={{margin:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                      
                      <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>KYC</span> 
                    </AccordionSummary>
                    <AccordionDetails>
                      {item?.obj?.applicationProfile?.sanctionLetter ?(
                        <>
                        {item?.obj?.applicationProfile?.fundSourceCode !== "FIB" ? (
                          <div  style={{
                        boxShadow: " 0px 4px 10px 0px #00000029",
                        cursor: item?.obj?.applicationProfile?.applicantConsent ? "not-allowed" : "pointer",
                        width: "auto",
                        height: "auto",
                        backgroundColor: "#FFF8F4",
                        border: "none",
                        borderRadius: "10px",
                        padding: "10px",display:'flex',justifyContent:'space-between'

                      }} onClick={item?.obj?.applicationProfile?.applicantConsent ? undefined :()=> toggle2(item)}>
                        <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Click Here to Accept Sanction Letter</div>
                        {item?.obj?.applicationProfile?.applicantConsent && (
                            <img src={check_circle} style={{ marginLeft: 'auto' }} />
                          )}                     
                      </div>
                        ):null}
                        </>
                      
                      ):null}
                     
                      
                      <>
                        {item?.obj?.applicationProfile?.fundSourceCode !== "FIB" ? (
                                <>
                                  <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                     backgroundColor:  "#FFF8F4" ,
                     color: "black",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px'
                     
                    }}   onClick={()=>handleKYCClick(item)}   >
                      <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Complete KYC</span>
                      </div>
                      {isMandateVisible && (
                       <div className={styless.main}>
                       
                         <Mandate item={item}/>
                       </div>
                      )}
                                </>
                              ):( <div  style={{
                                boxShadow: " 0px 4px 10px 0px #00000029",
                                cursor: "pointer",
                                width: "auto",
                                height: "auto",
                               backgroundColor:  "#FFF8F4" ,
                               color: "black",
                                border: "none",
                                borderRadius: "10px",
                                padding: "10px",marginTop:'10px'
                               
                              }}     onClick={()=>handleFibKYCClick(item)} >
                                <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Complete KYC</span>
                                </div>)}   
                      </>
                                 
                    
                      </AccordionDetails>
                  </Accordion>
                 
                  </>
                ):null}
                {showCards4 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                     <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Sanction Documents</span> 
                    </AccordionSummary>
                    <AccordionDetails >
                     <div>
                      
                     <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
  
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#FFF8F4",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px',display:'flex',justifyContent:'space-between'
                    
                    }}  onClick={()=>toggle2(item)}>
                      
                      <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Sanction Letter</div>
                      <div><img src={download} style={{height:'20px'}}/></div>
                      </div>
                      <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
  
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#FFF8F4",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px',display:'flex',justifyContent:'space-between'
                     
                    }} onClick={()=>toggleAgreement(item)}>
                      
                      <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Agreement</div>
                      <div><img src={download} style={{height:'20px'}}/></div>
                      </div>
                     </div>
                    
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                {showCards5 ? (
                   <>
                   <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                         <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header">
                          <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Disbursement</span> 
                         </AccordionSummary>
                         <AccordionDetails>
                           <Grid container style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>Amount Disbursed</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.disbursementAmount}</p>
                               </div>
                               <div>
                               <img src={rupee} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>Disbursement Date</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.disbursementDate?.substr(0, 10)}</p>
                               </div>
                               <div>
                               <img src={calender} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>UTR</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.utr}</p>
                               </div>
                               <div>
                               <img src={calender} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                           </Grid>
                 
                           </AccordionDetails>
                       </Accordion>
                       </>
                ):null}
              </Accordion>
              </div>:null}
                </>
              )}
              
              </>
            
            )
           })}       
          <br />
          <br />
          <Grid container>
            <Grid item xs={6} sm={4} md={3}>
              <Typography variant="body2" component="div" className="payment">
             <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',whiteSpace:'nowrap'}}>Previous Applications</span>  
                <svg
                  width="5"
                  height="23"
                  viewBox="0 0 5 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.461426"
                    y="0.772095"
                    width="4.17139"
                    height="21.7188"
                    rx="2.08569"
                    fill="#D32028"
                  />
                </svg>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={9}>
              <Box mt={2} ml={3}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
          {applicationsDetails ?.map((item,index)=>{
            return(
             <>
             {item.obj && Object.keys(item.obj).length !== 0 && item?.obj?.data?.location !== null &&(
              <>
              {item?.obj?.applicationProfile?.status === "Dropped" || item?.obj?.applicationProfile?.status === "Rejected" || item?.obj?.applicationProfile?.status === "Disbursed" ?
              <div className="loan-payment-crd1">
              <Accordion style={{backgroundColor:'#FFF8F4',width:'100%',overflow:'auto',padding:'0.2rem'}} onClick = {()=>{sessionStorage.setItem("appId",item?.obj?.data?.applicationId);sessionStorage.setItem("userId",item?.obj?.data?.userId);sessionStorage.setItem("mobile",item?.obj?.data?.mobile)}} expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                     <Grid container spacing={3} style={{alignItems:'center'}}>
                      <Grid item xs={12} sm={6} md={6} lg={3} >
                        <Typography variant="body2" component="div" className="Tuviksh1">
                        {item?.obj?.applicationProfile?.applicationId}
                        </Typography>
                      </Grid>
                    
                      <Grid item xs={12} sm={6} md={6} lg={4} >
                        
                        <Typography variant="body2" component="div" className="Program">
                        Start Date : {item.appliedOn ? moment(item.appliedOn).format('DD MMM YY') : "-" }{" "}
                        </Typography>
  
                      
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={5}>
                      <Typography variant="body2" component="div" className="Program"
                      >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{
                        borderRight: (item?.obj?.applicationProfile?.status === 'Disbursed' || item?.obj?.applicationProfile?.status === 'In Sanction') ? '2px solid #737373' : '0px',
                      }}><span style={{fontFamily: 'Outfit-Medium'}}>Status:</span>
                          <span style={{
                            color: item?.obj?.applicationProfile?.status === 'Disbursed' ? '#12B76A' : '#F8A31F' ,
                            fontFamily: 'Outfit-Medium',
                            backgroundColor: item?.obj?.applicationProfile?.status === 'Disbursed' ? '#ECFDF3' : '#FFF6E8',
                            borderRadius: item?.obj?.applicationProfile?.status === 'In Process' ? '16px' : '0px',
                            padding: '0.2rem'
                          }}>
                            {item?.obj?.applicationProfile?.status }
                          </span>
                        </div>
                        <div style={{ marginLeft: '0.2rem' }}>
                          {item?.obj?.applicationProfile?.status === 'In Process' ? null : 
                          (
                            item?.obj?.applicationProfile?.status === 'Disbursed'  ? (
                              <div  style={{
                                fontFamily: 'Outfit-Medium',
                                fontSize: '1em',
                                color: '#737373',
                                whiteSpace: 'nowrap',
                                textDecoration: 'underline',
                              }} onClick={() => handleDisbursementDetailsClick()}>
                                Disbursed details
                                {/* Add the necessary code to display disbursement details */}
                              </div>
                            ) : item?.obj?.applicationProfile?.status === 'In Sanction' ? (
                              <div  style={{
                                fontFamily: 'Outfit-Medium',
                                fontSize: '1em',
                                color: '#737373',
                                whiteSpace: 'nowrap',
                                textDecoration: 'underline',
                              }} onClick={() => handleKycDetails()}>
                                Click to complete KYC
                                {/* Add the necessary code to display disbursement details */}
                              </div>
                            ) :item?.obj?.applicationProfile?.status === 'In Submission' ? (
                              <></>
                            ) :
                             (
                            //  <><div  style={{
                            //   fontFamily: 'Outfit-Medium',
                            //   fontSize: '1em',
                            //   color: '#737373',
                            //   whiteSpace: 'nowrap',
                            //   textDecoration: 'underline',
                            // }} onClick={(item) => toggle2(item)}>
                            //  SanctionLetter <img src={download} style={{height:'17px'}}/> 
                            //   {/* Add the necessary code to display disbursement details */}
                            // </div></>
                            <></>
                            )
                          )}
                        </div>
  
                       
                      </div>
                        </Typography>
                       
                      </Grid>
                      {/* <Grid item xs={12} lg={2}>
                        <div style={{display:'flex',marginTop:'5px',}}>
                          <img style={{marginLeft:'8em'}} src={enterIcon}/>
                      </div>
                      </Grid> */}
                    </Grid>
                </AccordionSummary>
  
               
                
                <AccordionDetails style={{overflow:'auto'}}>
                <div style={{listStyleType:"none",display:"flex",flexDirection:"row",width:'1050px',justifyContent:'space-between'}}>
                <div style={{display:"flex",flexDirection:'row'}}>
                  <div 
                  style={{fontWeight:'500',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:"space-between",alignItems:"center",textAlign:'center'}}
                  onClick={()=>handleToggle('1',item)}>
                      
                  <img  style={{height:'25px',width:'25px'}} src={isImageClicked1 ? Basicdetailsclicked :basicdetails} alt="basicdetails"/>
                  {item?.obj?.data?.panId === null || item?.obj?.data?.dateOfBirth === null || item?.obj?.data?.currentAddress === null || item?.obj?.data?.currentCity === null || item?.obj?.data?.currentState === null || item?.obj?.data?.currentPincode === null
                 || item?.obj?.data?.panId === undefined  || item?.obj?.data?.dateOfBirth === undefined  || item?.obj?.data?.currentAddress === undefined  || item?.obj?.data?.currentCity === undefined  || item?.obj?.data?.currentState === undefined  || item?.obj?.data?.currentPincode === undefined 
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}
  
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',color: isImageClicked1 ? '#D32027' : 'black',marginTop:'5px'}}>Basic Details</p> 
                
                  </div>
                  {!item?.obj?.data ||
                  ((item?.obj?.data?.employmentType === "salaried" && (!item?.obj?.data?.employerName || !item?.obj?.data?.salary)) ||
                  (item?.obj?.data?.employmentType === "self employed" && (!item?.obj?.data?.profession || !item?.obj?.data?.income)))
                  ? (
                    <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                  ) : (
                    <div style={{ marginTop: "45px" }}>
                      <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                    </div>
                  )}
  
  
                  {/* <div style={{ borderBottom: "2px dashed #F8A31F",borderSpacing:"40px",alignSelf: 'center', height: '2px', width: '120px',marginRight:'20px'}}></div>
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div> */}
  
                </div >
  
                <div style={{display:"flex",flexDirection:'row'}}>
                  <div
                  style={{marginRight:"5px",fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                    
                  onClick={()=>handleToggle('2',item)}
                  >
                      <img  style={{height:'25px',width:'25px'}} src={isImageClicked2? incomeclicked :income} alt='income'/>
                      {/* <div style={{marginTop:"5px",borderStyle:"solid",borderColor:"#F8A31F",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center"}}></div> */}
  
                          {!item?.obj?.data ||
                  ((item?.obj?.data?.employmentType === "salaried" && (!item?.obj?.data?.employerName || !item?.obj?.data?.salary)) ||
                  (item?.obj?.data?.employmentType === "self employed" && (!item?.obj?.data?.profession || !item?.obj?.data?.income)))
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}
  
                   <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked2 ? '#D32027' : 'black'}}>Work Details</p>
                  </div>
                  {item?.obj?.applicationProfile?.enachStatus !== 4 || item?.obj?.applicationProfile?.agreementStatus !== 3 || item?.obj?.applicationProfile?.selfieStatus !== 3 || item?.obj?.applicationProfile?.digilockerStatus !== 3 
                  ? (
                    <div style={{ borderBottom: "2px dashed #F8A31F",borderSpacing:"40px",alignSelf: 'center', height: '2px', width: '120px',marginRight:'20px'}}></div>
                    ) : (
                    <div style={{ marginTop: "45px" }}>
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                    </div>
                  )}
  
                </div>
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:"center"}}
                  onClick={()=>handleToggle('3')}
                  >
                    
                    <img style={{height:'25px',width:'25px'}} src={isImageClicked3 ? kycclicked: kyc} alt="kyc"/>
                    {item?.obj?.applicationProfile?.enachStatus !== 4 || item?.obj?.applicationProfile?.agreementStatus !== 3 || item?.obj?.applicationProfile?.selfieStatus !== 3 || item?.obj?.applicationProfile?.digilockerStatus !== 3 
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}   
                    <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked3 ? '#D32027' : 'black'}}>KYC</p>
                  </div>
                </div>
               
                {!item?.obj?.applicationProfile?.agreementUrl || !item?.obj?.applicationProfile?.sanctionLetter ? (
                  <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                ) : (
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                )}
  
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div
                  style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                  onClick={()=>handleToggle('4')}>
                      <img style={{height:'25px',width:'25px'}} src={isImageClicked4 ? disbursalclicked :disbursal} alt="sanction"/>
                      {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
                      {!item?.obj?.applicationProfile?.agreementUrl || !item?.obj?.applicationProfile?.sanctionLetter
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}                     
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked4 ? '#D32027' : 'black'}}>Sanction</p>
                  </div>
                </div>
                {!item?.obj?.applicationProfile?.disbursement ? (
                  <div style={{ borderBottom: "2px dashed #F8A31F", borderSpacing: "40px", alignSelf: 'center', height: '2px', width: '120px', marginRight: '20px' }}></div>
                ) : (
                  <div style={{ alignSelf: 'center', height: '2px', width: '120px', background: '#12B76A' }}></div>
                )}
                <div style={{display:"flex",flexDirection:'column'}}>
                  <div
                    style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
                    onClick={()=>handleToggle('5')}
                  >
                      <img style={{height:'25px',width:'25px'}} src={isImageClicked5 ? sanctionclicked:sanction} alt="disbursal"/>
                      {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"20px",width:"20px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
                      {!item?.obj?.applicationProfile?.disbursement
                  ? (
                    <div style={{ marginTop: "10px", borderStyle: "solid", borderColor: "#F8A31F", borderRadius: "50%", height: "21px", width: "21px", alignSelf: "center" }}></div>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <img style={{ padding: '1px', borderStyle: "solid", borderColor: "#12B76A", borderRadius: "50%", height: "20px", width: "20px", alignSelf: "center", backgroundColor: '#12B76A' }} src={tickicon} />
                    </div>
                  )}                
                  <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',marginTop:"5px",color: isImageClicked5 ? '#D32027' : 'black'}}>Disbursment</p> 
                  </div>
                </div>
                </div>
                </AccordionDetails>
                {showCards1 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Basic Details</span> 
                     
                    </AccordionSummary>
                    <AccordionDetails>
                      {openVerify ? (
                        <div style={{
                          padding: "2rem",
                          background: "#FFF7F2",
                          border: "1px solid #F9D8D6",
                          borderRadius: "12px 12px 12px 12px"
                        }}>
      
                        <div >
                          <div className="basic"
                          >
                            <div>
                            <p style={{fontSize:'1em',marginLeft:'0.5em'}}>PAN number</p>
                            <InputText
                              square
                              style={{width:'14em',height:'3em'}}
                              placeholder="PAN Number"
                              value={item?.obj?.data?.panId}
                              onChange={(e) => setPanNumber(e.target.value)}
                            />
                            </div>
                            <div>
                            <p style={{fontSize:'1em',marginLeft:'0.5em'}}>Date Of Birth</p>
                            <InputText
                              square
                              style={{width:'14em',height:'3em'}}
                              placeholder="Date of Birth"
                              value={item?.obj?.data?.dateOfBirth}
                              onChange={(e) => setDob(e.target.value)}
                              type="date"
                            />
                            </div>
                      
                            {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            > */}
              
                            {/* </div> */}
                          </div>
                          <label style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
                            <input
                              type="checkbox"
                              checked
                              name=""
                              id=""
                              style={{
                                marginRight: "0.4rem",
                                height:'1em',width:'1em'
                              }}
                            />
                            <span style={{fontSize:'1em'}}>I consent and authorize <FeemonkText /> to get a background check
                            and a consumer credit report on me</span>
                          </label>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
                        <Button
                          onPress={() => {
                           
                              getPanPro();
                            
                          }}
                          text={"Next"}
                         
                        />
                        </div>
                       
                                 </div>
                      ):null}
                       {openAddress ? (
                       <div >
                     <div onClick={() => {SetOpenVerify(true);SetOpenAddress(false)}} style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}}>
                        <p><img style={{height:'22px',width:'22px'}} src={back}/></p>
                        <p style={{fontFamily:'Outfit-Medium',fontSize:'14px',fontWeight:'500',marginLeft:'5px'}}>Back</p>
                      </div>
                       <div>
                         <div
                           style={{
                             padding: "1rem",
                             background: "#FFF7F2",
                             border: "1px solid #F9D8D6",
                             borderRadius: "12px 12px 0px 0px",
                           }}
                         >
                           <p style={{fontSize:'1em', fontWeight: "bold" }}>Address</p>
                         </div>
                         <div
                           style={{
                             display: "flex",
                             flexDirection: "column",
                             // justifyContent: "center",
                             // alignItems: "center",
                             gap: "1rem",
                             border: "1px solid #F9D8D6",
                             background: "#FFFCFA",
                             padding: "1rem",
                             boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                             borderRadius: "0px 0px 12px 12px",
                           }}
                         >
                           <div>
                             <p
                               style={{
                                 color: "#D32028",
                                 fontSize: "1.2em",
                               }}
                             >
                               Note
                             </p>
                             <p style={{fontSize:'1em',}}>
                               The following address details are obtained from your e-kyc
                             </p>
                           </div>
                           <div >
                             <InputText
                               gridArea="door"
                               square
                               placeholder="Door No."
                               id="address"
                               value={item?.obj?.data?.currentAddress}
                               onChange={(event) =>
                                setAddress(event.target.value)
                              }
                             />
                             {/* <InputText
                               gridArea="street"
                               square
                               placeholder="Street / Landmark"
                               value={state.currentAddress}
                             /> */}
                             <div style={{display:'block'}}>
                             <InputText
                               gridArea="city"
                               square
                               placeholder="City"
                               id="city"
                               value ={item?.obj?.data?.currentCity}                            
                               onChange={(event) =>
                                setCity(event.target.value)
                              }
                             />
                             <InputText
                               gridArea="state"
                               square
                               placeholder="State"
                               id="state"
                               value={item?.obj?.data?.currentState}
                               onChange={(event) =>
                                setState(event.target.value)
                              }
                             />
                             <InputText
                               gridArea="pin"
                               square
                               placeholder="Pincode"
                               id="pincode"
                               value={item?.obj?.data?.currentPincode}
                               onChange={(event) =>
                                setPincode(event.target.value)
                              }
                             />
                             
                             </div>
                           </div>
                           <br />
                         </div>
                         <br />
                        
                       </div>
                       <div style={{marginTop:'2em'}}>
                       <Button
                         text={"Next"}
                         onPress={() => {
                          updateProfileDetails();
                        }}
                       />
                       </div>
                      
                     </div>
                      ):null}
                    
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                 {showCards2 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                     <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Work Details</span> 
                    </AccordionSummary>
                    <AccordionDetails>
                    
         <div>
            <div>
              <div
                style={{
                  padding: "1rem",
                  background: "#FFF7F2",
                  border: "1px solid #F9D8D6",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', fontWeight: "bold" }}>Work Details</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid #F9D8D6",
                  background: "#FFFCFA",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <div className="work">
                  <div>
                    <p
                      style={{
                        display:'flex',justifyContent:'center',
                        textAlign: "center",
                        color: "#3D3D3D",
                        fontWeight: "bold",
                        fontSize:'1.2em',
                      }}
                    >
                      Employment details
                    </p>
                    <div
                      className={`${
                        employmentType === "salaried"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("salaried")}
                    >
                      <p style={{fontSize:'1em'}}>Salaried</p>
                    </div>
                    <div
                      className={`${
                        employmentType === "Self employed"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("Self employed")}
                    >
                      <p  style={{fontSize:'1em'}}>Self Employee</p>
                    </div>
                  </div>
                
                  {employmentType === "salaried" ? (
                    <>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Company Name</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="Ex: FeeMonk"
                          id="emplName"
                          value={item?.obj?.data?.employerName}
                          onChange={(e) => setEmployerName(e.target.value)}
                        />
                      </div>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Net Monthly Salary</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="₹"
                          id="salary"
                          value={item?.obj?.data?.salary}
                          onChange={(e) => setSalary(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Profession</p>
                        <InputText
                         style={{width:"14rem"}}
                          square
                          placeholder="Profession"
                          value={item?.obj?.data?.typeOfBusiness}
                          onChange={(e) => setProfession(e.target.value)}
                        />
                      </div>
                      <div>
                        <p style={{fontSize:'1.2em'}}>Monthly income</p>
                        <InputText
                        style={{width:"14rem"}}
                          square
                          placeholder="₹"
                          value={item?.obj?.data?.incomePerMonth}
                          onChange={(e) => setMonthlyIncome(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                  {/* <Button 
                     onPress={() => {
                      updateWorkDetails();
                    }}
                    text={"Save"}
                    fullWidth={false}
                    secondary
                  /> */}
                </div>
                <br />
              </div>
            </div>
            <div
            style={{marginTop:'2em'}}
            >
              <div
                style={{
                  padding: "1rem",
                  background: "#FFF7F2",
                  border: "1px solid #F9D8D6",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', fontWeight: "bold" }}>Bank Statement</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid #F9D8D6",
                  background: "#FFFCFA",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                  borderRadius: "0px 0px 12px 12px",
                  width: "100%",
                }}
              >
                <p style={{fontSize:'1.2em', }} >
                  Uploading a bank account statement can enhance your chances of
                  availing better loan amount
                </p>
                <div
                        
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                        }}
                      >
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 57 56"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="3.5"
                            y="3"
                            width="50"
                            height="50"
                            rx="25"
                            fill="#F2F4F7"
                          />
                          <rect
                            x="3.5"
                            y="3"
                            width="50"
                            height="50"
                            rx="25"
                            stroke="#F9FAFB"
                            stroke-width="6"
                          />
                          <path
                            d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                            stroke="#475467"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
  
                        <p
                          style={{
                            color: "#d32028",
                            fontSize:'1em'
                          }}
                        >
                          Click to upload
                        </p>
                        <p style={{
                            
                            fontSize:'1em'
                          }}>PDF or ZIP</p>
                      </div>
              </div>
            </div>
            <div style={{marginTop:'2em'}}>
            <Button
              text={"Next"}
              onPress={() => {
                updateWorkDetails();
              }}
            />
            </div>
          </div>
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                 {showCards3 ? (
                  <>
                  <Accordion style={{margin:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                      
                      <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>KYC</span> 
                    </AccordionSummary>
                    <AccordionDetails>
                      
                      <div  style={{
                        boxShadow: " 0px 4px 10px 0px #00000029",
                        cursor: item?.obj?.applicationProfile?.applicantConsent ? "not-allowed" : "pointer",
                         width: "auto",
                        height: "auto",
                        backgroundColor: "#FFF8F4",
                        border: "none",
                        borderRadius: "10px",
                        padding: "10px",display:'flex',justifyContent:'space-between'
                      
                      }} onClick={item?.obj?.applicationProfile?.applicantConsent ? undefined :()=> toggle2(item)}>
                        <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Click Here to Accept Sanction Letter</div>
                        {item?.obj?.applicationProfile?.applicantConsent && (
                            <img src={check_circle} style={{ marginLeft: 'auto' }} />
                          )}                     
                      </div>
                      {item?.obj?.applicationProfile?.applicantConsent === true ?
                      <>
                        {item?.obj?.applicationProfile?.fundSourceCode !== "FIB" ? (
                                <>
                                  <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                     backgroundColor:  "#FFF8F4" ,
                     color: "black",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px'
                     
                    }}   onClick={()=>handleKYCClick(item)}   >
                      <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Complete KYC</span>
                      </div>
                      {isMandateVisible && (
                       <div className={styless.main}>
                       <Modal isOpen={mandateModals}  size="lg" style={{height:'100%'}}>
                         <ModalHeader toggle={toggleMandates}>Mandate</ModalHeader>
                         <ModalBody >
                           
                             <div className={styless1.inputField}>
                               <Label text="Account Number" />
                               <InputText
                                 placeholder="Account Number"
                                 type="number"
                                 
                               />
                             </div>
                 
                             <div className={styless1.inputField}>
                               <Label text="IFSC Code" />
                               <InputText
                                 placeholder="IFSC Code"
                                 type="text"
                                 
                               />
                             </div>
                             <div className={styless1.inputField}>
                               <Label text="Account Type" />
                               <div
                               
                                 defaultValue="SAVINGS"
                               >
                                 <input
                                   type="radio"
                                   value="SAVINGS"
                                   name="student"
                                 />{" "}
                                  <span style={{fontFamily:'Outfit-Medium',margin:'5px'}}>Savings</span> 
                                 {" "}<br/>
                                 <input
                                   type="radio"
                                   value="CUURENT"
                                   name="student"
                                 />{" "}
                                  <span style={{fontFamily:'Outfit-Medium',margin:'5px'}}>Current</span> 
                               </div>
                             </div>
                             <div className={styless1.inputField}>
                               <Label text="How do you want to authenticate your account?" />
                               <div
                                
                                 defaultValue="NET_BANKING"
                               >
                                 <input
                                   type="radio"
                                   value="NET_BANKING"
                                   name="Net Banking"
                                 />{" "}
                                  <span style={{fontFamily:'Outfit-Medium',margin:'5px'}}>Net Banking</span> 
                   
                                 {" "}<br/>
                                 <input
                                   type="radio"
                                   value="DEBIT_CARD"
                                   name="Debit Card" 
                                 />{" "}
                                <span style={{fontFamily:'Outfit-Medium',margin:'5px'}}>Debit Card</span> 
                               </div>
                             </div>
                             <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                             <Button
                             onPress={() => {}}
                              text="SUBMIT"
                            />
                             </div>
                         </ModalBody>
                       </Modal>
                         <Mandate item={item}/>
                       </div>
                      )}
                                </>
                              ):( <div  style={{
                                boxShadow: " 0px 4px 10px 0px #00000029",
                                cursor: "pointer",
                                width: "auto",
                                height: "auto",
                               backgroundColor:  "#FFF8F4" ,
                               color: "black",
                                border: "none",
                                borderRadius: "10px",
                                padding: "10px",marginTop:'10px'
                               
                              }}     onClick={()=>handleFibKYCClick(item)} >
                                <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Complete KYC</span>
                                </div>)}   
                      </>:null}
                                 
                    
                      </AccordionDetails>
                  </Accordion>
                 
                  </>
                ):null}
                {showCards4 ? (
                  <>
              <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                     <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Sanction Documents</span> 
                    </AccordionSummary>
                    <AccordionDetails >
                     <div>
                      
                     <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
  
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#FFF8F4",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px',display:'flex',justifyContent:'space-between'
                    
                    }}  onClick={()=>toggle2(item)}>
                      
                      <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Sanction Letter</div>
                      <div><img src={download} style={{height:'20px'}}/></div>
                      </div>
                      <div  style={{
                      boxShadow: " 0px 4px 10px 0px #00000029",
  
                      cursor: "pointer",
                      width: "auto",
                      height: "auto",
                      backgroundColor: "#FFF8F4",
                      border: "none",
                      borderRadius: "10px",
                      padding: "10px",marginTop:'10px',display:'flex',justifyContent:'space-between'
                     
                    }} onClick={()=>toggleAgreement(item)}>
                      
                      <div style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}} >Agreement</div>
                      <div><img src={download} style={{height:'20px'}}/></div>
                      </div>
                     </div>
                    
                      </AccordionDetails>
                  </Accordion>
                  </>
                ):null}
                {showCards5 ? (
                   <>
                   <Accordion style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}} defaultExpanded={true}>
                         <AccordionSummary  expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header">
                          <span style={{fontFamily:'Outfit-Medium',fontSize:'1em',color:  'black'}}>Disbursement</span> 
                         </AccordionSummary>
                         <AccordionDetails>
                           <Grid container style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>Amount Disbursed</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.disbursementAmount}</p>
                               </div>
                               <div>
                               <img src={rupee} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>Disbursement Date</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.disbursementDate?.substr(0, 10)}</p>
                               </div>
                               <div>
                               <img src={calender} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                             <Grid item style={{display:'flex',justifyContent:'space-around',borderStyle:'solid',borderStyle:'solid',boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",borderWidth:'1px',borderColor:"#EAECF0",borderRadius:'5px',paddingTop:'15px',paddingBottom:'10px',marginTop:'5px'}} xs={12} sm={12} md={3} lg={3}>
                               <div>
                               <p style={{fontFamily:'Outfit-Medium',color:'#858585',fontWeight:'500',fontSize:'1em'}}>UTR</p>
                               <p style={{color:'#101828',fontWeight:'600',fontFamily:'Outfit-Medium',fontSize:'1em'}}>{item?.obj?.applicationProfile?.disbursement?.utr}</p>
                               </div>
                               <div>
                               <img src={calender} alt="rupee" style={{height:'53px',width:'53px'}} />
                               </div>
                             </Grid>
                           </Grid>
                 
                           </AccordionDetails>
                       </Accordion>
                       </>
                ):null}
              </Accordion>
              </div>
            :null}
              </>
             )}
              
             </>
             
            )
          })}
    
         
          {/* <p
            style={{
              padding: "1em",
              marginLeft: "1em",
              marginRight: "1em",
              marginTop: "1em",
              borderRadius: "5em",
              color: "#D32028",
              backgroundColor: "#F0F0F0",
              fontSize: "1.5em",
              fontFamily: "Outfit",
              textAlign: "center",
            }}
          >
            No Previous Applications
          </p> */}
        </Box>
      </Mainpagestyle>
      {/* <Educationcard /> */}
      {/* <Preveiouspayment userData={rows} /> */}
    </>
  );
}

export default Applications;
