import React, { useContext, useState } from "react";
import styles from "./index.module.css";
import KYC from "../../images/icons/kyc.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import LoanStepCard from "../../components/molecules/Card";
import InputText from "../../components/atoms/InputText";
import Button from "../../components/atoms/Button";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { useNavigate } from "react-router-dom";
import { updateAddress } from "../../services/application";

function Address() {
  const [currentAddress, setCurrentAddress] = useState("yes");
  const { panProData, userId } = useContext(DataContext) as DataContextType;
  const navigate = useNavigate();

  const [state, setState] = useState({
    currentAddress: panProData.currentAddress || "",
    currentCity: panProData.currentCity || "",
    currentState: panProData.currentState || "",
    currentPincode: panProData.currentPincode || "",
    permanentAddress: "",
    permanentCity: "",
    permanentState: "",
    permanentPincode: "",
  });

  const submitAddress = () => {
    // setPanDetails(PANzoopData);
    updateAddress({
      userId: userId,
      currentAddress: state.currentAddress,
      currentCity: state.currentCity,
      currentState: state.currentState,
      currentPincode: state.currentPincode,
      permanentAddress:
        currentAddress === "yes"
          ? state.currentAddress
          : state.permanentAddress,
      permanentCity:
        currentAddress === "yes" ? state.currentCity : state.permanentCity,
      permanentState:
        currentAddress === "yes" ? state.currentState : state.permanentState,
      permanentPincode:
        currentAddress === "yes"
          ? state.currentPincode
          : state.permanentPincode,
    })
      .then((result) => {
        console.log(result);
        navigate("/work-details");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <LoanStepCard
            // description="Permanent Address & Current Location"
            title="KYC"
            image={KYC}
          />
          <div>
            <div
              style={{
                padding: "1rem",
                background: "#FFF7F2",
                border: "1px solid #F9D8D6",
                borderRadius: "12px 12px 0px 0px",
              }}
            >
              <p style={{fontSize:'1em', fontWeight: "bold" }}>Permanent Address</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                gap: "1rem",
                border: "1px solid #F9D8D6",
                background: "#FFFCFA",
                padding: "1rem",
                boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <div>
                <p
                  style={{
                    color: "#D32028",
                    fontSize: "1.2em",
                  }}
                >
                  Note
                </p>
                <p style={{fontSize:'1em',}}>
                  The following address details are obtained from your e-kyc,
                  please feel free to edit in case of changes
                </p>
              </div>
              <div className={styles.addressInputGrid}>
                <InputText
                  gridArea="door"
                  square
                  placeholder="Door No."
                  value={state.currentAddress}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, currentAddress: e.target.value }))
                  }
                />
                {/* <InputText
                  gridArea="street"
                  square
                  placeholder="Street / Landmark"
                  value={state.currentAddress}
                /> */}
                <div style={{display:'block'}}>
                <InputText
                  gridArea="city"
                  square
                  placeholder="City"
                  value={state.currentCity}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, currentCity: e.target.value }))
                  }
                />
                <InputText
                  gridArea="state"
                  square
                  placeholder="State"
                  value={state.currentState}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, currentState: e.target.value }))
                  }
                />
                <InputText
                  gridArea="pin"
                  square
                  placeholder="Pincode"
                  value={state.currentPincode}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, currentPincode: e.target.value }))
                  }
                />
                <Button
                  onPress={() => {}}
                  text={"Save"}
                  fullWidth={false}
                  secondary
                />
                </div>
              </div>
              <br />
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                gap: "1rem",
                border: "1px solid #F9D8D6",
                background: "#FFFCFA",
                padding: "1rem",
                boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                borderRadius: "12px",
              }}
            >
              <strong style={{fontSize:'1em'}}>Is this your current address?</strong>
              <div className={styles.inputField}>
                {/* <Label text="Account Type" /> */}
                <div
                  onChange={(event) =>
                    setCurrentAddress((event.target as HTMLInputElement).value)
                  }
                  defaultValue="yes"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <input
                      style={{width:'1em'}}
                      type="radio"
                      value="yes"
                      name="yes"
                      checked={currentAddress === "yes"}
                    />{" "}
                    <span style={{fontSize:'1em'}}>Yes</span>
                  </div>
                  <div>
                    <input
                      style={{width:'1em'}}
                      type="radio"
                      value="no"
                      name="student"
                      checked={currentAddress === "no"}
                    />{" "}
                   <span style={{fontSize:'1em'}}> No</span>
                  </div>
                </div>
                {currentAddress === "no" && (
                  <div>
                    <br />
                    <div
                      style={{
                        border: "0.5px solid rgba(181, 181, 181, 0.3)",
                      }}
                    ></div>
                    <br />
                    <p style={{fontSize:'1em', fontWeight: "bold" }}>Current Address</p>
                    <br />
                    <div className={styles.addressInputGrid}>
                      <InputText
                        gridArea="door"
                        square
                        placeholder="Door No."
                        value={state.permanentAddress}
                        changeHandler={(e) => {
                          setState((s) => ({
                            ...s,
                            permanentAddress: e.target.value,
                          }));
                        }}
                      />
                      <div style={{display:'block'}}>
                      <InputText
                        gridArea="city"
                        square
                        placeholder="City"
                        value={state.permanentCity}
                        changeHandler={(e) => {
                          setState((s) => ({
                            ...s,
                            permanentCity: e.target.value,
                          }));
                        }}
                      />
                      <InputText
                        gridArea="state"
                        square
                        placeholder="State"
                        value={state.permanentState}
                        changeHandler={(e) => {
                          setState((s) => ({
                            ...s,
                            permanentState: e.target.value,
                          }));
                        }}
                      />
                      <InputText
                        gridArea="pin"
                        square
                        placeholder="Pincode"
                        value={state.permanentPincode}
                        changeHandler={(e) => {
                          setState((s) => ({
                            ...s,
                            permanentPincode: e.target.value,
                          }));
                        }}
                      />
                      <Button
                        onPress={() => {}}
                        text={"Save"}
                        fullWidth={false}
                        secondary
                      />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Button
            text={"Save & Next"}
            onPress={() => {
              submitAddress();
            }}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Address;
