import React from "react";
import styles from "./index.module.css";
import MailIcon from "../../../images/icons/mail.svg";
import PhoneIcon from "../../../images/icons/phone.svg";
import FeemonkText from "../../atoms/FeemonkText";

function Footer() {
  return (
    <div className={styles.footer}>
      <p className={styles.helpCenter}>Help centre</p>
      <div className={styles.contactDiv}>
        <span className={styles.contactItem}>
          <img style={{width:'1em',height:'1em',marginTop:'5px'}} src={MailIcon} alt="" />
          <p style={{fontSize:'1em'}}>info@feemonk.com</p>
        </span>
        <span className={styles.contactItem}>
          <img  style={{width:'1em',height:'1em',marginTop:'5px'}} src={PhoneIcon} alt="" />
          <p style={{fontSize:'1em'}}>+91 99999 99999</p>
        </span>
      </div>
      <p className={styles.copyrightText}>
        Copyright @ 2023 <FeemonkText /> All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
