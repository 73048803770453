import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import PaybackIcon from "../../images/icons/payback.svg";
import ZeropcIcon from "../../images/icons/zero_pc.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { getProducts } from "../../services/application";


function SelectEmi() {
  const navigate = useNavigate();

  const [products, setProducts] = useState<
    {
      productId: string;
      tenure: number;
      emi: number;
    }[]
  >([]);
  const { userId, applicationId, loanAmount } = useContext(DataContext) as DataContextType;
  const [selected, setSelected] = useState("");

  useEffect(() => {
    getProducts({
      userId,
      applicationId,
    })
      .then((result) => {
        console.log(result);
        setProducts(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId, applicationId]);

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                flex: 1,
                marginLeft:'1em'
              }}
            >
              <p
                style={{
                  color: "#606060",
                  fontSize: "1em",
                }}
              >
                Principal amount
              </p>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "1em",
                }}
              >
                ₹ {loanAmount}
              </p>
            </div>
            <div
             className={styles.advert}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={ZeropcIcon}
                    alt=""
                    style={{
                      height: "3rem",
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "1em",
                    }}
                  >
                    Processing Fee
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={ZeropcIcon}
                    alt=""
                    style={{
                      height: "3rem",
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "1em",
                    }}
                  >
                    Foreclosure Charges
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={PaybackIcon}
                    alt=""
                    style={{
                      height: "1.8rem",
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "1em",
                    }}
                  >
                    Pay Back Anytime
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <p
              style={{
                fontWeight: 600,
                fontSize: "1em",
              }}
            >
              Choose EMI plan
            </p>
            <div
             className={styles.planBox}
            >
              {products?.map(({ tenure, emi, productId }) => (
                <div
                  onClick={() => {
                    setSelected(productId);
                  }}
                  id={productId}
                  style={{
                    marginLeft:'20px',
                    marginBottom:'10px',
                    borderRadius: "12px",
                    border:
                      selected === productId
                        ? "1px solid #D32028"
                        : "1px solid #F9D8D6",
                    background: "#FFF",
                    boxShadow:
                      selected === productId
                        ? "0px 3px 3px 0px rgba(211, 32, 40, 0.10)"
                        : "0px 3px 3px 0px rgba(211, 32, 40, 0.10)",
                    height: "12em",
                    width: "12em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.3em",
                      color: selected === productId ? "#000000" : "#575757",
                    }}
                  >
                    ₹ {emi}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      color: selected === productId ? "#000000" : "#575757",
                    }}
                  >
                    {tenure} months
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Button
            onPress={() => {
              navigate("/select-date", {
                state: {
                  selected,
                  data: products.filter(
                    ({ productId }) => productId === selected
                  )[0],
                },
              });
            }}
            text={"Proceed"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SelectEmi;
