import React, { useState } from "react";
import { Container, Button, Grid, Paper, Box } from "@mui/material";
import { Homestyle } from "../Home/Style";
import Loanicon from "../../Assets/images/Loanicon.svg";
import Repaymenticon from "../../Assets/images/repayment-icon.png"
import Applicationicon from "../../Assets/images/Applicationicon.png";
import feepaymenticon from "../../Assets/images/Feepaymenticon.png"
import Feepayment from "./Feepayment";
import Repayments from "./Repayments";
import Loan from "./Loan";
import Applications from "./Applications";

export default function Hero({sendUpdatedRows,payments,sendRows,amount,discountedAmount,totalAmount}) {
  const [selectedTab, setSelectedTab] = useState("FEE_PAYMENT");

  return (
    <>
      <Homestyle>
        <Container>
          <Paper className="paper-setting">
            <div className="paper-btn">
              <Grid container>
                {/* <Grid item xs={6} md={4}>
                  <Button
                    fullWidth
                    color={
                      selectedTab === "APPLICATIONS" ? "secondary" : "primary"
                    }
                    onClick={() => setSelectedTab("APPLICATIONS")}
                  > */}
                    {/* <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.66797 1.51855H2.67305C2.27556 1.51855 1.89435 1.67646 1.61328 1.95752C1.33222 2.23859 1.17432 2.6198 1.17432 3.01729V15.0071C1.17432 15.4046 1.33222 15.7858 1.61328 16.0669C1.89435 16.348 2.27556 16.5059 2.67305 16.5059H11.6654C12.0629 16.5059 12.4441 16.348 12.7252 16.0669C13.0063 15.7858 13.1642 15.4046 13.1642 15.0071V6.01475M8.66797 1.51855L13.1642 6.01475M8.66797 1.51855V6.01475H13.1642M10.1667 9.76157H4.17178M10.1667 12.759H4.17178M5.67051 6.76411H4.17178"
                        stroke="black"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg> */}
                    {/* <Box component="img" src={Applicationicon} marginRight={1} />
                    Applications
                  </Button>
                </Grid> */}
                {/* <Grid item xs={6} md={4}>
                  <Button
                    fullWidth
                    color={selectedTab === "LOANS" ? "secondary" : "primary"}
                    onClick={() => setSelectedTab("LOANS")}
                  >
                    <Box component="img" src={Loanicon} marginRight={1} />
                    Loans
                  </Button>
                </Grid> */}
                {/* <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedTab("REPAYMENT")}
                    color={
                      selectedTab === "REPAYMENT" ? "secondary" : "primary"
                    }
                  >
                   
                    <Box component="img" src={Repaymenticon} marginRight={1} />
                    Repayment
                  </Button>
                </Grid> */}
               { sendRows?.length>0?<Grid item xs={6} md={4}>
                  <Button
                    fullWidth
                    color={
                      selectedTab === "FEE_PAYMENT" ? "secondary" : "primary"
                    }
                    onClick={() => setSelectedTab("FEE_PAYMENT")}
                  >
                   
                    <Box component="img" src={feepaymenticon} marginRight={0.5} />
                   <span style={{whiteSpace:'nowrap',fontFamily:'Inter-Medium'}}>Fee Payment</span> 
                  </Button>
                </Grid>:<></>}
              </Grid>
            </div>
            {selectedTab === "FEE_PAYMENT" ? (
              <Feepayment sendRows={sendRows} payments={payments} sendUpdatedRows={sendUpdatedRows} amount={amount} discountedAmount={discountedAmount} totalAmount={totalAmount}/>
            ) : selectedTab === "REPAYMENT" ? (
              <Repayments />
            ) : selectedTab === "LOANS" ? (
              <Loan />
            ) : (
              <Applications />
            )}
          </Paper>
        </Container>
      </Homestyle>
    </>
  );
}

