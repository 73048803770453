import React from 'react';
import Slider from "react-slick";

// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
// import App from './Reciept/App';
import App1 from './Reciept/App1';
import { List, ListItem, Grid, Typography, } from '@mui/material';


export default function Slidercard({data}) {
  console.log(data,"slider")
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow:3,
    slidesToScroll: 3,
    nextArrow: <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8589 23.6217L22.1992 17.2814L15.8589 10.9411M33.2113 17.2814C33.2113 26.0355 26.1146 33.1322 17.3605 33.1322C8.60639 33.1322 1.50977 26.0355 1.50977 17.2814C1.50977 8.52728 8.60639 1.43066 17.3605 1.43066C26.1146 1.43066 33.2113 8.52728 33.2113 17.2814Z" stroke="#D32028" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    ,
    prevArrow: <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8589 23.6217L22.1992 17.2814L15.8589 10.9411M33.2113 17.2814C33.2113 26.0355 26.1146 33.1322 17.3605 33.1322C8.60639 33.1322 1.50977 26.0355 1.50977 17.2814C1.50977 8.52728 8.60639 1.43066 17.3605 1.43066C26.1146 1.43066 33.2113 8.52728 33.2113 17.2814Z" stroke="#D32028" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      },

    ]

  };

  const handleClick = (component, componentName) => {
    const newWindow = window.open('', '_blank');
    const container = newWindow.document.createElement('div'); // Creating a container element
    newWindow.document.body.appendChild(container); // Appending container to body
    createRoot(container).render(component); // Rendering into the container using createRoot
    newWindow.close();
  };
  const filteredData = Object.values(
    data.reduce((acc, item) => {
      if (item.status === "success") {
        if (!acc[item.paymentRefNo] || item.paid_amount > acc[item.paymentRefNo].paid_amount) {
          acc[item.paymentRefNo] = item;
        }
      }
      return acc;
    }, {})
  );
  
  return (
    <Slider {...settings} >
     
          {
            filteredData?.map((item)=>{
              if(item.status==="success"){
                return(
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div>
                  <div  className='fee-crd' >
                 <div>
                      <List style={{display:'flex'}}>
                        <ListItem>

                          <Typography variant="body2" component="div" className="TRYZ00121" >
                            
                         Receipt
                          </Typography>

                        </ListItem>
                        <ListItem>
                      
                    <p  onClick={() => handleClick(<App1 receipt={{...item}} type="feepayment" />, 'Receipt')}>
                     <App1 type="feepayment" receipt={{...item}}/> </p>
                        </ListItem>

                      </List>

                    </div>
                    <div className='innerpdng' >
                      <Grid container spacing={2} xs={14} width={250} >
                        <Grid item xs={6} sm={6.5}  >
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="Aditya-crd" >

                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.9427 1.83105V4.33105M6.27604 1.83105V4.33105M2.73438 6.83105H15.4844M4.15104 3.08105H14.0677C14.8501 3.08105 15.4844 3.6407 15.4844 4.33105V13.0811C15.4844 13.7714 14.8501 14.3311 14.0677 14.3311H4.15104C3.36864 14.3311 2.73438 13.7714 2.73438 13.0811V4.33105C2.73438 3.6407 3.36864 3.08105 4.15104 3.08105Z" stroke="#5F5F5F" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            Payment Date

                          </Typography>
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="date-crd" >
                            {item.paid_date?.substr(0, 10)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={5.5} >
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="Aditya-crd" >

                          

                            Amount Paid

                          </Typography>
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="date-crd" >
                            ₹ {item.paid_amount}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={7} >
                          <Typography variant="body2" component="div" className="Aditya-crd" >

                          

                            Program/Class

                          </Typography>
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="date-crd" >
                            {item.payment_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={5} >
                        <Typography variant="body2" component="div" className="Aditya-crd-2">
                            
                           Ref No.
                          </Typography>

                          <Typography variant="body2" style={{fontSize:'14px'}} component="div" className="date-crd" >
                            {item.paymentRefNo}
                          </Typography>
                        </Grid>
                        <Grid item lg={7} xs={8}>
                          <Typography variant="body2" component="div" className="Aditya-crd" >

                          
                            Inst./School

                          </Typography>
                          <Typography variant="body2" style={{fontSize:'14px'}}  component="div" className="date-crd" >
                            {item.payment_name}
                          </Typography>
                        </Grid>
                        <Grid item  lg={5} xs={7} >
                          <Typography variant="body2" component="div" className="Aditya-crd" >
                           Status

                          </Typography>
                          <Typography variant="body2" component="div" className="date-crd" >
                            <span style={{backgroundColor:item.status==="success"?"#ECFDf3":"#FFF6E8",padding:"4px",borderRadius:'10px',fontSize:'14px',color:item.status==="success"?"green":"",border:item.status==="success"?"1px solid green":""}}>{item.status}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
              </div>
         </div>
        
                )
              }
              else{
                <div>
                  </div>
              }
            })
          }
    </Slider>
  )
}
