export const userConstants = {


    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    SUBMITOTP_REQUEST: 'USERS_SUBMITOTP_REQUEST',
    SUBMITOTP_SUCCESS: 'USERS_SUBMITOTP_SUCCESS',
    SUBMITOTP_FAILURE: 'USERS_SUBMITOTP_FAILURE',
    FEE_PAYMENT_REQUEST: 'FEE_PAYMENT_REQUEST',
    FEE_PAYMENT_SUCCESS: 'FEE_PAYMENT_SUCCESS',
    FEE_PAYMENT_FAILURE: 'FEE_PAYMENT_FAILURE',
    GET_APPLICATIONS_REQUEST: 'GET_APPLICATIONS_REQUEST',
    GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
    GET_APPLICATIONS_FAILURE: 'GET_APPLICATIONS_FAILURE',
    GET_REPAYMENTS_REQUEST: 'GET_REPAYMENTS_REQUEST',
    GET_REPAYMENTS_SUCCESS: 'GET_REPAYMENTS_SUCCESS',
    GET_REPAYMENTS_FAILURE: 'GET_REPAYMENTS_FAILURE',
    GET_EMIHISTORY_REQUEST: 'GET_EMIHISTORY_REQUEST',
    GET_EMIHISTORY_SUCCESS: 'GET_EMIHISTORY_SUCCESS',
    GET_EMIHISTORY_FAILURE: 'GET_EMIHISTORY_FAILURE',

    ADMIN_LOGIN_SUCCESS:'ADMIN_LOGIN_SUCCESS',
   

    LOGOUT: 'USERS_LOGOUT'
}