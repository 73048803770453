import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import FeemonkText from "../../components/atoms/FeemonkText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createApplication } from "../../services/application";

function EnterPan() {
  const navigate = useNavigate();
  const { data } = useParams();
  const [userData, setUserData] = useState<{
    mobileNumber: string;
    course: string;
    fees: number;
    studentName: string;
    instituteName: string;
  }>({
    mobileNumber: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
  });

  const [pan, setPan] = useState(
    process.env.NODE_ENV === "development" ? "HKRPM6911E" : ""
  );
  const [dob, setDob] = useState(
    process.env.NODE_ENV === "development" ? "2004-04-17" : ""
  );

  const { updatePanProData, saveIds } = useContext(
    DataContext
  ) as DataContextType;
  // const [panDetails, setPanDetails] = useState({});

  useEffect(() => {
    setUserData(JSON.parse(atob(data || "")));
  }, [data]);
  

  const getPanPro = () => {
    // setPanDetails(PANzoopData);
    createApplication({
      mobile: userData.mobileNumber,
      panId: pan,
      dob: dob,
      courseFees: userData.fees,
      instituteName: userData.instituteName,
      email: "swapnadeep456@gmail.com",
      studentName: userData.studentName,
      courseName: userData.course,
      channelId: 0,
    })
      .then((result) => {
        console.log(result);

        // updatePanProData(PANzoopData);
        updatePanProData(result.data);
        saveIds(
          result.data.application,
          result.data.userId,
          userData.fees.toString(),
          userData.mobileNumber.toString()
        );
        navigate("/address");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div   className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                
              }}
            >
              <p style={{fontSize:'1.5em'}}>PAN number</p>
              <InputText
                square
                style={{width:'15em',height:'3em'}}
                placeholder="PAN Number"
                changeHandler={(e) => setPan(e.target.value)}
                value={pan}
              />
              <br/>
              <p style={{fontSize:'1.5em'}}>Date Of Birth</p>
              <InputText
                square
                style={{width:'15em',height:'3em'}}
                placeholder="Date of Birth"
                changeHandler={(e) => {
                  console.log(e.target.value);
                  setDob(e.target.value);
                }}
                value={dob}
                type="date"
              />
              <br />

              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              > */}

              {/* </div> */}
            </div>
            <div></div>
            <label>
              <input
                type="checkbox"
                name=""
                id=""
                style={{
                  marginRight: "0.4rem",
                  height:'1em',width:'1em'
                }}
              />
              <span style={{fontSize:'1em'}}>I consent and authorize <FeemonkText /> to get a background check
              and a consumer credit report on me</span>
            </label>
          </div>
          <Button
          
            onPress={() => {
              getPanPro();
            }}
            imageRight={ArrowLeft}
            text={"Verify"}
            
          />
        </div>
        <br/>
        <Footer />
      </div>
    </div>
  );
}

export default EnterPan;
