import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/atoms/Button";
import styles from "./index.module.css";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import Label from "../../components/atoms/Label";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import FeemonkText from "../../components/atoms/FeemonkText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createApplication } from "../../services/application";
import BankIcon from "../../images/static_assests/bank.svg";
import PdfIcon from "../../images/static_assests/pdf.svg";
import { APPLICATIONS_BACKEND } from "../../utils/keys";
import Dropzone from "react-dropzone";
import { upload } from "@testing-library/user-event/dist/upload";
import { bankStatementPdfUpload } from "../../services/aa";

function BankStatement() {
  const [pdf, setPdf] = useState();

  const submitUpload = async () => {
    const data = new FormData();
    if (pdf) {
      data.append("file", pdf);
    }
    const result = await bankStatementPdfUpload(data);
    console.log(result);
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <h3>Bank Statement Upload</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              gap: "1rem",
              border: "1px solid #F9D8D6",
              background: "#FFFCFA",
              padding: "1rem",
              boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
              borderRadius: "12px",
              width: "75%",
            }}
          >
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => setPdf(acceptedFiles[0])}
            >
              {({
                getRootProps,
                getInputProps,
              }: {
                getRootProps: any;
                getInputProps: any;
              }) => (
                <section>
                  <div
                    {...getRootProps()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                  >
                    <input {...getInputProps()} />
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        fill="#F2F4F7"
                      />
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        stroke="#F9FAFB"
                        stroke-width="6"
                      />
                      <path
                        d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                        stroke="#475467"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p
                      style={{
                        color: "#d32028",
                      }}
                    >
                      Click to upload
                    </p>
                    <p>PDF</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <Button
            onPress={() => {
              //   getPanPro();
              submitUpload();
            }}
            imageRight={ArrowLeft}
            text={"Continue"}
            disabled={!pdf}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default BankStatement;
