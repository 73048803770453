import React from 'react'
import logo from './Assets/images/logo.svg'


export default function Privacy() {
  return (
    <div style={{padding:'10px'}}>
      <div className="nav-className">
    <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="www.feemonk.com">
            <img className="nav-img" src={logo}  />
        </a>
        </nav>
    </div>

    <h4 className="mt-5 text-center">PRIVACY POLICY</h4>
    <div className="policy">
        <div className="container">
            <p className="mt-5 heading">THIS WEBSITE WWW.FEEMONK.COM AND OTHER RELATED INTERNET BASED APPLICATIONS (COLLECTIVELY REFERRED TO AS “WEBSITE” or “SERVICES”) OWNED AND OPERATED BY GLAZE BARTER PRIVATE LIMITED, A COMPANY INCORPORATED UNDER THE COMPANIES ACT, 1956 HAVING ITS REGISTERED OFFICE AT PLOT NO : 1-82/2/SUIT "B" PRASANNA HITEX 2nd FLOOR,MANCHIREVULA HYDERABAD TS 500089 (HEREINAFTER REFERRED TO AS “WE”, or “US” or OUR or “FEEMONK”).
                THIS PRIVACY POLICY GIVES YOU DETAILS ABOUT OUR DATA COLLECTION METHODS AND USAGE. YOU ARE ADVISED TO READ THIS PRIVACY POLICY ALONG WITH THE TERMS OF USE AND OTHER INFORMATION IN THE WEBSITE/APPLICATIONS. USERS PLEASE TAKE NOTE THAT ANY STATEMENTS MADE ON FEEMONK WEBSITE/APPLICATIONS SHALL NOT BE CONSTRUED AS AN OFFER OR PROMISES FOR GRANT OF ANY FINANCIAL SERVICES.</p>
        
                <dl>
                    <dt>1.Use of Our Website or Services:</dt>
                    <dd className="mt-2">You will be able to use Our Website or Services as per Term of Use.</dd>
                </dl>
                
                <dl>
                    <dt>2. Information collected by FeeMonk:</dt>
                    <dd className="mt-2">a.&nbsp;&nbsp;Information provided by users through our Website or Services will include details required for availing respective Services.</dd>
                    <dd className="mt-2">b.&nbsp;&nbsp;Information from credit bureaus and customer service providers to help FeeMonk with customer verification and diligence required for FeeMonk and partners.</dd>
                    <dd className="mt-2">c.&nbsp;&nbsp;Log information: Search queries, IP address, crashes, date and time.</dd>
                    <dd className="mt-2">d.&nbsp;&nbsp;Location information: Your actual location.</dd>
                    <dd className="mt-2">e.&nbsp;&nbsp;Details required for administration or services and product improvement.</dd>
                    <dd className="mt-2">f.&nbsp;&nbsp;Record and/or monitor calls if FeeMonk feel necessary for quality checks and staff training. Such recordings may also be used to help FeeMonk combat fraud.</dd>
                    <dd className="mt-2">g.&nbsp;&nbsp;Feedback given by you.</dd>
                    <dd className="mt-2">h.&nbsp;&nbsp;Third-party advertisements: FeeMonk may use third-party advertising companies to serve ads when you visit OUR Website/Application. These companies may use information about your usage preferences (but not your name, address, email address, or telephone numabout your visits to this and other websites in order to provide advertisements about goods and services of interest to you, FeeMonk is not responsible for product and services of such websites and is also not responsible for their privacy practices, which FeeMonk does not own, manage or control.</dd>
                    <dd className="mt-2">i.&nbsp;&nbsp;Termination of account: While you can terminate your account as per terms of use, your information may remain stored in archive on our servers even after the deletion or the termination of your account.                   </dd>
                    <dd className="mt-2">j.&nbsp;&nbsp;Retention of information collected: The information collected from you will be retained during the
                        <ul className="mt-2">
                            <li>validity of your Account and for the purpose of submission of such information,</li>
                            <li>As consented by you while creating your User Account</li>
                            <li>As required by any regulatory norms including but not limited to the norms prescribed under Prevention of Money Laundering Act, 2002.</li>
                        </ul>
                    </dd>                    
                
                </dl>

                <dl>
                    <dt>3. Use of information collected by FeeMonk:</dt>
                    <dd className="mt-2">
                        The information will be used by FeeMonk for the following:
                        <ul className="mt-3">
                        <li>To provide products you have requested;</li>
                        <li>To provide services you have requested;</li>
                        <li>To provide information to our partners to assist in making, credit decisions about you;</li>
                        <li>To help investigate violations of our terms of use or to defend against legal claims;</li>
                        <li>To disclose the information under special circumstances such as compliance with the applicable local law, court summons, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure.</li>
                        <li>Search credit bureaus and fraud prevention agencies;</li>
                        <li>To get in touch with you when necessary and contact you by email, SMS, letter, telephone or in any other way about our products and services;</li>
                        <li>To identify, prevent, detect or tackle fraud, money laundering, terrorism and other crimes;</li>
                        <li>To form a view of you as an individual and to identify, develop or improve products, that may be of interest to you;</li>
                        <li>Provide information to independent external bodies such as governmental departments and agencies, universities and similar to carry out research;</li>
                        <li>Perform other administrative and operational purposes including the testing of systems;</li>
                        <li>Trace your whereabouts;</li>
                        <li>Recover any payments you owe to FeeMonk or partners; and</li>
                        <li>Comply with our regulatory obligations;</li>
                        <li>To show you advertisements;</li>
                        <li>Contact you as a survey respondent;</li>
                        <li>To maintain records under applicable law or a may apply to pursuant to agreements executed by FeeMonk;</li>
                        <li>Carry out, monitor and analyse our business, Carry out market research, business, and statistical analysis and also direct our efforts for product improvement;</li>
                        <li>Carry out audits;</li>   

                        
                        
                        </ul> 
                    </dd>
                </dl>

                <dl>
                    <dt>4. Specific consent</dt>
                    <dd className="mt-2">a.&nbsp;&nbsp;You consent and grant us authority to share your information with third parties to
                        <ul className="mt-2">
                            <li className="mt-2">Provide the product or service you have requested;</li>
                            <li className="mt-2">To enable them to contact you or to certain service partners in order to respond to your queries / comments or to resolve service issues and to serve you better and</li>
                            <li className="mt-2">To collect information about you as mentioned in section 2 of this Privacy Policy and use the same for those purpose mentioned in section 3 of this Privacy Policy.</li>

                        </ul>

                    </dd>
                    <dd>b.&nbsp;&nbsp;By using the Website/Services you authorize us, our affiliates & our associate partners to contact you via email or phone call or sms and offer you their services/ product, imparting product knowledge, offer promotional offers running on their websites & offers offered by the associated third parties. Irrespective of the fact if also you have registered yourself under DND or DNC or NCPR service, you still authorize to give you a call from FeeMonk & its associates / partners for the above- mentioned purposes.</dd>
                </dl>

                <dl>
                    <dt>5. Security:</dt>
                    <dd className="mt-2">Your account information is password-protected for your privacy and security. You shall be the custodian of your password and comply with the applicable terms mentioned in the Website/ Application in this regard</dd>
                </dl>

                <dl>
                    <dt>6. Changes in Privacy Policy:</dt>
                    <dd className="mt-2">Our Privacy Policy might change from time to time, and FeeMonk will provide notice of it on your email address linked to your Account or can be seen by you in our Website.</dd>
                </dl>

                <dl>
                    <dt>7. Incorporation of Privacy Policy to the Terms of use:</dt>
                    <dd className="mt-2">This Privacy Policy is incorporated to the Terms of use (Terms) and other specific terms of this Website/Services/Account.</dd>
                </dl>

                <dl>
                    <dt>8. Contact:</dt>
                    <dd className="mt-2">Contact: For any information regarding the Privacy Policy, please contact hello@feemonk.com. In case you have grievance relating to collecting receiving, possessing, storing, dealing or handling of Personal Information provided by you may contact our Grievance Officer on the following address or write an email</dd>
                    <dt className="mt-2">hello@feemonk.com</dt>
                    <p style={{width:'14rem'}}>
Glaze Barter Private Limited
PLOT NO : 1-82/2/SUIT "B" 
PRASANNA HITEX 2nd FLOOR,
MANCHIREVULA HYDERABAD 500089</p>
                
                </dl>
            </div>
    </div>
    </div>
  )
}
