import { combineReducers } from "redux";
import { authentication,submitotp } from "./authentication.reducer";
import { feepayment } from "./feepayment.reducer";
import { GETAPPLICATIONS } from "./applications.reducer";
import { repayments,emiHistory } from "./repayments.reducer";
 
 


const rootReducer = combineReducers({

  feepayment: feepayment,
  authentication: authentication,
  submitotp: submitotp,
  GETAPPLICATIONS:GETAPPLICATIONS,
  repayments:repayments,
  emiHistory:emiHistory

 
});

export default rootReducer;